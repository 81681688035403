export const baseUrl= process.env.REACT_APP_API_BASE_URL +"/"
export const getInvoiceListApi=baseUrl+"accinvoices";
export const multiCreateInvoiceApi=baseUrl+"accinvoices/multicreate";
export const validateInvoiceApi=baseUrl+"accinvoices/validate";
export const getDataDownloadApi=baseUrl+"table";
export const withdrawClaimData=baseUrl+"claim/";
export const maintainFactorsApi = baseUrl+"factors";
export const reconciliationApi =  baseUrl+"reports:reconciliation";
export const abatementApi =  baseUrl+"reports:abatement";
export const invoiceApi =  baseUrl+"reports:invoice";
export const employeeReportApi =  baseUrl+"reports:employee";
export const reCalculateClaim =  baseUrl+"reCalcClaim";