import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import ProgressBar from '../../../../shared/components/ProgressBar';

const format = "DD/MM/YYYY";
let cl = [];

const useStyles = makeStyles({
  table: {
    minHeight: 650
  }
});

function createData(
  runId,
  runDate,
  runBy,
  reason,
  status,
) {
  return {
    runId,
    runDate,
    runBy,
    reason,
    status,
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row"> {row.runId}</TableCell>
        <TableCell align="right">{row.runDate}</TableCell>
        <TableCell align="right">{row.runBy}</TableCell>
        <TableCell align="right">{row.reason}</TableCell>
        <TableCell align="right">{row.status}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Claim Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Claim Number</TableCell>
                    <TableCell>Employee Number</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Retry Count</TableCell>
                    <TableCell align="right">Last Updated</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody  className={classes.table}>
                  {cl.map((clRow) => {  
                    return (
                        clRow.runId === row.runId && 
                        <TableRow key={clRow.claimnumber}>
                          <TableCell component="th" scope="row">{clRow.claimnumber}</TableCell>
                          <TableCell>{clRow.employeenumber}</TableCell>
                          <TableCell align="right">{clRow.employeename}</TableCell>
                          <TableCell align="right">{clRow.count}</TableCell>
                          <TableCell align="right">{clRow.date}</TableCell>
                          <TableCell align="right">{clRow.status}</TableCell>
                        </TableRow>
                      )
                   })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ claimList }) {
  const [rows, setRows] = useState([]);
  useEffect(()=>{
    let duplicate = [];
    let rows = [];
    cl = [];
    claimList.forEach((obj) => {
      let data = {
        runId:obj.runId,
        claimnumber:obj.claimNumber,
        employeenumber:obj.employeeNumber,
        employeename:obj.employeeFirstName.concat(" ",obj.employeeLastName),
        count:obj.retryCount,
        date:moment(obj.lastUpdatedDateTime).format(format),
        status:(obj.claimStatus == "X")?"In Progress":(obj.claimStatus == "S") ? "Success" : "Fail",
      }
      cl.push(data)
      if(!duplicate.includes(obj.runId)){
        duplicate.push(obj.runId);
        let batch_status = (obj.batchStatus == "X")?"In Progress":(obj.batchStatus == "S") ? "Success" : "Fail"
        rows.push(createData(obj.runId, moment(obj.runDateTime).format(format), obj.runBy, obj.reason, batch_status))
      } 
    })
      setRows(rows)
  },[claimList])
  const rowsLength = rows.length;
  return (
    <>
    { 
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              { rowsLength > 0 && <TableCell /> }
              <TableCell align="right">Run Id</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Run By</TableCell>
              <TableCell align="right">Reason</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { rowsLength > 0 ? rows.map((row) => (
              <Row key={row.name} row={row} />
            )):<ProgressBar page={'meta'} />}
          </TableBody>
        </Table>
      </TableContainer>
    }
    </>
  );
}
