import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Forms from './Forms';
import Tables from './Tables';
import DefaultPages from './DefaultPages';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/forms" component={Forms} />
      <Route path="/tables" component={Tables} />
      <Route path="/default_pages" component={DefaultPages} />
    </div>
  </div>
);
