import React, { useEffect } from "react";
import * as validation from "../../../containers/Form/ReduxForm/WizardForm/components/validations";
import { Field } from "redux-form";

const RenderDateFieldArray = ({ input, meta, className, fields, onChange, min, max, isDisabled }) => {
  useEffect(() => {
    if (fields.length === 0) fields.push({});
  });

  return (
    <div
      className={`form__form-group-input-wrap ${className}`}
      style={validation.getErrorStyle(meta.error, meta.touched)}
    >
      {fields.map((fldName, index) => (
        <div className="form__form-group" key={index}>
          <span className="form__form-group-label">Indexation Exemption Date #{index + 1}</span>
          <div className="form__form-group-field">
            <div className="container">
              <Field
                {...input}
                name={`${fldName}.fromDate`}
                type="date"
                component={validation.renderField}
                validate={[validation.customValidation]}
                onChange = {onChange}
                min={min}
                max={max}
                disabled={isDisabled}
              />
            </div>
            <div className="container">
              <Field
                {...input}
                name={`${fldName}.toDate`}
                type="date"
                component={validation.renderField}
                validate={[validation.customValidation]}
                onChange = {onChange}
                min={`${fldName}`.fromDate || min}
                max={max}
                disabled={isDisabled}
              />
            </div>
            {index === 0 && (
              <div className="container">
                <button type="button" onClick={() => fields.push({})} disabled={isDisabled}>
                  +
                </button>
              </div>
            )}
            <div className="container">
              <button type="button" onClick={() => fields.remove(index)} disabled={isDisabled}>
                -
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RenderDateFieldArray;
