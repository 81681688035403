import * as Constant from '../../utils/constant';
import * as Action from '../actions/accInvoiceValidate';
import postData from '../../utils/api/base/post';
import * as API from '../../utils/api/base/apiUrl';
//getListInvoice 

export const validateInvoiceListAsync=(requestBody)=>{
    return async dispatch=>{
        console.log("request body",requestBody)
        dispatch(Action.validateInvoiceListRequest(requestBody));
        try{
            const response = await postData(API.validateInvoiceApi,requestBody,{},true); 
            console.log("response=====",response);
            if(response.errorData){
                dispatch(Action.validateInvoiceListFailure(response.errorData));
            }else{
            const responseData = response.data;
            dispatch(Action.validateInvoiceListSuccess(responseData));
            }
        } catch(error){
            console.log("error",error)
            dispatch(Action.validateInvoiceListFailure(error));
        }
    }
}