import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import renderDropZoneField from '../../../../../shared/components/form/DropZone';

const FileUploadDefault = ({ handleSubmit, reset,pageName=null,onChangeFile }) => {
  const { t } = useTranslation('common');

  return (
    <Col md={12} lg={12}>
          <form className="form" onSubmit={handleSubmit}>
             <Field
             onChange={(event)=>onChangeFile(event)}
              name="files"
              component={renderDropZoneField}
           />
            {pageName==null?<ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">Submit</Button>
              <Button type="reset" onClick={reset}>
                Cancel
              </Button>
            </ButtonToolbar>:null}
            
          </form>
    </Col>
  );
};

FileUploadDefault.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'file_upload_default', // a unique identifier for this form
})(FileUploadDefault);
