export const GET_INVOICE_LIST_REQUEST = 'GET_INVOICE_LIST_REQUEST';
export const GET_INVOICE_LIST_SUCCESS = 'GET_INVOICE_LIST_SUCCESS';
export const GET_INVOICE_LIST_FAILURE = 'GET_INVOICE_LIST_FAILURE';


export const VALIDATE_INVOICE_LIST_REQUEST = 'VALIDATE_INVOICE_LIST_REQUEST';
export const VALIDATE_INVOICE_LIST_SUCCESS = 'VALIDATE_INVOICE_LIST_SUCCESS';
export const VALIDATE_INVOICE_LIST_FAILURE = 'VALIDATE_INVOICE_LIST_FAILURE';

export const ACC_INVOICE_MULTI_REQUEST = 'ACC_INVOICE_MULTI_REQUEST';
export const ACC_INVOICE_MULTI_SUCCESS = 'ACC_INVOICE_MULTI_SUCCESS';
export const ACC_INVOICE_MULTI_FAILURE = 'ACC_INVOICE_MULTI_FAILURE';

export const GET_DATATABLE_REQUEST = 'GET_DATATABLE_REQUEST';
export const GET_DATATABLE_SUCCESS = 'GET_DATATABLE_SUCCESS';
export const GET_DATATABLE_FAILURE = 'GET_DATATABLE_FAILURE';

export const SET_WITHDRAW_CLAIM_MODE = 'SET_WITHDRAW_CLAIM_MODE';
export const SET_WITHDRAW_CLAIM_DATA = 'SET_WITHDRAW_CLAIM_DATA';

export const WITHDRAW_REQUEST_CLAIM_REQUEST = 'WITHDRAW_REQUEST_CLAIM_REQUEST';
export const WITHDRAW_REQUEST_CLAIM_SUCCESS = 'WITHDRAW_REQUEST_CLAIM_SUCCESS';
export const WITHDRAW_REQUEST_CLAIM_FAILURE = 'WITHDRAW_REQUEST_CLAIM_FAILURE';

export const GET_MAINTAINFACTORS_REQUEST = 'GET_MAINTAINFACTORS_REQUEST';
export const GET_MAINTAINFACTORS_SUCCESS = 'GET_MAINTAINFACTORS_SUCCESS';
export const GET_MAINTAINFACTORS_FAILURE = 'GET_MAINTAINFACTORS_FAILURE';

export const RESET_WITHDRAW_CLAIM_DATA = 'RESET_WITHDRAW_CLAIM_DATA';

export const SET_CLOSE_CLAIM_MODE = 'SET_CLOSE_CLAIM_MODE';
export const SET_CLOSE_CLAIM_DATA = 'SET_CLOSE_CLAIM_DATA';

export const CLOSE_REQUEST_CLAIM_REQUEST = 'CLOSE_REQUEST_CLAIM_REQUEST';
export const CLOSE_REQUEST_CLAIM_SUCCESS = 'CLOSE_REQUEST_CLAIM_SUCCESS';
export const CLOSE_REQUEST_CLAIM_FAILURE = 'CLOSE_REQUEST_CLAIM_FAILURE';

export const RESET_CLOSE_CLAIM_DATA = 'RESET_CLOSE_CLAIM_DATA';

export const EMPLOYMENT_TYPES = {
    fullTimePermanent: "A",
    PartTimePermanent: "B",
    FullTimeFixedTerm: "C",
    PartTimeFT: "D",
    Casual: "E"
  }

export const INJURY_TYPE = {
    Work: "Work",
    Nonwork: "Non-Work"
}

export const PAY_TYPE = {
    Salaried: "S",
    Waged: "W"
}

export const UPDATE_REASON = {
    AbatementLightDuties : 'abatementLightDuties',
    AbatementAnnualLeave : 'abatementAnnualLeave', 
    AbatementPublicHoliday : 'abatementPublicHoliday',
    abatementContractHours : 'abatementContractHours',
    AbatementTerminatedEmployee : 'abatementTerminatedEmployee',
    TopUpOrdinaryPay : 'topUpOrdinaryPay',
    EditMedicalExpiryDate : 'editMedicalExpiryDate',
    ChangeRuleGrouping : 'changeRuleGrouping',
    ChangePSLTopUp: 'changePSLTopUp',
    ChangeinDofiDosidates : 'changeinDofiDosidates', 
    IncorrectOrdinaryPay : 'incorrectOrdinaryPay',
    ChangeInLTESTEEarnings : 'changeInLTESTEEarnings', 
    OthersWithNotes : 'othersWithNotes'
}

export const CALCULATION_BASIS = {
    STE: 'STE',
    LTE: 'LTE',
    BOTH: 'STE & LTE'
}