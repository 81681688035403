import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
} from "reactstrap";
import { RadioButton } from "../../../shared/components/radioButton/radioButton";
import  Reconciliation  from "./components/Reconciliation";
import  Invoice  from "./components/Invoice";
import  Abatement  from "./components/Abatement";
import  Employee  from "./components/Employee";
import * as Api from './../../../utils/api/base/apiUrl';
import axios from 'axios';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const Reprots = () => {
  const [selected, setSelected] = useState('1');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const format = "DD/MM/YYYY";
  const dateArray = ['endOfWeek','startOfWeek','paymentDate',
                      'eligibilityEndDate','paymentFromDate','paymentToDate',
                      'paymentStartDate','paymentEndDate','endOfPayrollPeriod',
                      'startOfPayrollPeriod','hireDate','dateOfInjury','reimburseEndDate',
                      'reimburseStartDate','eligibilityFromDate','eligibilityToDate'
                    ];
   const handleChange = (e,setIdentifierState) => {
    const { name, value} = e.target;
    setIdentifierState(value);
    setMessage("");
  };

  const setInfo = (message) =>{
    setMessage(message);
    setTimeout(()=>{setMessage("")},3000)
  }
  useEffect(()=>{
   if(response){
     const {reports:{columnHeaders, data, totals} } = response;
      if(data && data.length){
        const head_keys = Object.keys(data[0]);
        const totals_keys = (totals) ? totals.map((val) => val.name):[];
        const totals_values = (totals) ? totals.map((val) => val.value):[]
        const headers = head_keys.map((val)=>{
          const matchObj = columnHeaders.find((obj) => obj.field === val);
          return (matchObj && matchObj.name)?matchObj.name:"Missing Header";
        });
        let fileName = (selected == 1) ? "Reconciliation" : (selected == 2) ? "Invoice" :  (selected == 3) ? "Abatement" : "Employee";
        downloadCsvFile(JSON_to_CSV(data, headers, head_keys,totals_keys,totals_values),`${fileName}${'-report'}`); 
      }
      else{
        setInfo("No Data Found");
      }
    }
  },[response])
  const JSON_to_CSV = (arr, headers, columns, totals_keys, totals_values, delimiter = ',') =>
  [
    headers.join(delimiter),
    ...arr.map(obj =>
      columns.reduce(
        (acc, key) => `${acc}${!acc.length ? '' : delimiter}"${(!obj[key]) ? '' : dateArray.includes(key)? moment(obj[key]).format(format):obj[key]}"`,
        ''
      ),
    ),
    totals_keys.join(delimiter),
    totals_values.join(delimiter),
  ].join('\n');

  const downloadCsvFile = (items,fileName) => {
      var FileSaver = require('file-saver');
      var blob = new Blob([items], {type: 'data:text/plain;charset=utf-8,'});
      FileSaver.saveAs(blob, `${fileName.replace(".","_")}.csv`);
  }

  const callApi = async(req) =>{
    // API Call 
    let apiUrl = Api.reconciliationApi ;
    if(selected == 2)
      apiUrl =  Api.invoiceApi; 
    if(selected == 3)
      apiUrl =  Api.abatementApi; 
    if(selected == 4)
      apiUrl =  Api.employeeReportApi; 
    setLoading(true);
    try{
      let resp = await axios.post(`${apiUrl}`,{ reportRequests:[req] });
      if(resp.status == 200){
        setResponse(resp.data);
        setLoading(false);
      }else{
        setLoading(false);
      }
    } catch(error){
      console.log(error)
      setInfo("Something went wrong");
      setLoading(false);
    }
  }
  const getReportdata = (request) =>{
    // Form Input
   let req = JSON.parse(JSON.stringify(request))
    for(let x in dateArray){
      if(req[dateArray[x]]){
        req[dateArray[x]] = moment(req[dateArray[x]]).format(format)
      }
    }
    if(req && req.payrollCode)
      req.payrollCode = req.payrollCode.value;
    if(req && req.eraStatus)
      req.eraStatus =  req.eraStatus.value;
    if(req && req.payType)
      req.payType =  req.payType.value;
    if(req && req.payFrequency)
      req.payFrequency =  req.payFrequency.value;
   callApi(req); 
  }
  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <FormGroup tag="fieldset">
                <RadioButton
                  name={"selected"}
                  title={"ACC Reconciliation Report"}
                  value={"1"}
                  onChange={(e)=>handleChange(e,setSelected)}
                  checked={selected}
                  disabled={loading && selected != 1}
                />
                <RadioButton
                  name={"selected"}
                  title={"ACC Invoices Report"}
                  value={"2"}
                  onChange={(e)=>handleChange(e,setSelected)}
                  checked={selected}
                  disabled={loading  && selected != 2}
                />
                <RadioButton
                  name={"selected"}
                  title={"Abatement Report"}
                  value={"3"}
                  onChange={(e)=>handleChange(e,setSelected)}
                  checked={selected}
                  disabled={loading  && selected != 3}
                />
                <RadioButton
                  name={"selected"}
                  title={"Employee Report"}
                  value={"4"}
                  onChange={(e)=>handleChange(e,setSelected)}
                  checked={selected}
                  disabled={loading  && selected != 4}
                />
              </FormGroup>
                { selected === "1" && <Reconciliation title="ACC Reconciliation Report" onSubmit={getReportdata} loading={loading} /> }
                { selected === "2" && <Invoice title="ACC Invoices Report" onSubmit={getReportdata} loading={loading}/> }
                { selected === "3" && <Abatement title="Abatement Report" onSubmit={getReportdata} loading={loading}/> }
                { selected === "4" && <Employee title="Employee Report" onSubmit={getReportdata} loading={loading}/> }
            </CardBody>
            {message != "" && <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="info">{message}</Alert>
            </Stack>}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Reprots;
