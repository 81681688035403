import * as Constant from '../../utils/constant';
import * as Action from '../actions/dataDownloadAction';
import getData from '../../utils/api/base/get';
//getListInvoice 

export const getDownloadDataAsync=(url)=>{
    console.log("url",url)
    return async dispatch=>{
        dispatch(Action.getDownloadDataRequest());
        try{
            const response = await getData(url,{},true); 
            console.log("12",response);
            if(response.errorData){
                dispatch(Action.getDownloadDataFailure(response.errorData));
            }else{
            const responseData = response.data;
            dispatch(Action.getDownloadDataSuccess(responseData));
            }
        } catch(error){
            dispatch(Action.getDownloadDataFailure(error));
        }
    }
}