import axios from 'axios';
import { formatDateForDisplay as formatDate} from '../../utils/formatDate'

export default (async function getClaim(id) {        
    return await new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_API_BASE_URL + `/claim/${id}`)
        .then(x => {
            // API returns dates in timestamp format YYYY-MM-DDTHH:mm:ss.nnnnnnn 
            // Extract just the dates, UI will adjust formats depending on whether
            // it is a date or text field
            x.data.claim.certificateValidTo = formatDate(x.data.claim.certificateValidTo)
            x.data.claim.dateOfInjury = formatDate(x.data.claim.dateOfInjury)
            x.data.claim.ltePeriodEnd = formatDate(x.data.claim.ltePeriodEnd)
            x.data.claim.ltePeriodStart = formatDate(x.data.claim.ltePeriodStart)
            x.data.claim.standDownEnd = formatDate(x.data.claim.standDownEnd)
            x.data.claim.standDownStart = formatDate(x.data.claim.standDownStart)
            x.data.claim.stePeriodEnd = formatDate(x.data.claim.stePeriodEnd)
            x.data.claim.stePeriodStart = formatDate(x.data.claim.stePeriodStart)
            x.data.claim.weeklyCompensationStart = formatDate(x.data.claim.weeklyCompensationStart)
            x.data.claim.createdOn = formatDate(x.data.claim.createdOn)
            x.data.claim.dofiDate = formatDate(x.data.claim.dofiDate)
            x.data.claim.continuationDate = formatDate(x.data.claim.continuationDate)
            x.data.claim.dateRuleGroupChange = formatDate(x.data.claim.dateRuleGroupChange)
            x.data.claim.standDownStart = formatDate(x.data.claim.standDownStart)
            x.data.claim.standDownEnd = formatDate(x.data.claim.standDownEnd)
            x.data.claim.weeklyCompensationStart = formatDate(x.data.claim.weeklyCompensationStart)
            x.data.claim.stePeriodStart = formatDate(x.data.claim.stePeriodStart)
            x.data.claim.stePeriodEnd = formatDate(x.data.claim.stePeriodEnd)
            x.data.claim.ltePeriodStart = formatDate(x.data.claim.ltePeriodStart)
            x.data.claim.ltePeriodEnd = formatDate(x.data.claim.ltePeriodEnd)
            x.data.employee.hireDate = formatDate(x.data.employee.hireDate)  
            x.data.claim.managedFromDate = formatDate(x.data.claim.managedFromDate)          
            //handle nulls from data migration
            if(x.data.claim.newDOSI === null || x.data.claim.newDOSI === undefined ){
                x.data.claim.newDOSI = ' ';
            }
            if(x.data.claim.pslTopUp === null || x.data.claim.pslTopUp === undefined ){
                x.data.claim.pslTopUp = ' ';
            }
            if(x.data.claim.worked4Weeks === null || x.data.claim.worked4Weeks === undefined ){
                x.data.claim.worked4Weeks = ' ';
            }
            if(x.data.claim.worked52Weeks === null || x.data.claim.worked52Weeks === undefined ){
                x.data.claim.worked52Weeks = ' ';
            }
            if(x.data.claim.working30Hours === null || x.data.claim.working30Hours === undefined ){
                x.data.claim.working30Hours = ' ';
            }
            if(x.data.claim.earnings52WAvailable === null || x.data.claim.earnings52WAvailable === undefined ){
                x.data.claim.earnings52WAvailable = ' ';
            }
            if(x.data.claim.indexationApplies === null || x.data.claim.indexationApplies === undefined ){
                x.data.claim.indexationApplies = ' ';
            }
            if(x.data.claim.eraOptOut === null || x.data.claim.eraOptOut === undefined ){
                x.data.claim.eraOptOut = ' ';
            }
            if(x.data.claim.managedByACC === null || x.data.claim.managedByACC === undefined ){
                x.data.claim.managedByACC = ' ';
            }
            if(x.data.claim.continuedClaim === null || x.data.claim.continuedClaim === undefined ){
                x.data.claim.continuedClaim = ' ';
            }
            if(x.data.claim.paymentVariance === null || x.data.claim.paymentVariance === undefined ){
                x.data.claim.paymentVariance = ' ';
            }
            if(x.data.indexationExemptionDates != null){
                x.data.indexationExemptionDates = x.data.indexationExemptionDates.map(row => {
                    row.fromDate = formatDate(row.fromDate);
                    row.toDate = formatDate(row.toDate);
                    return row;
                })
            }
            
            x.data.payments = x.data.payments.map(row => {
                row.dateOrdinaryPay = formatDate(row.dateOrdinaryPay)
                row.endOfPayrollPeriod = formatDate(row.endOfPayrollPeriod)
                row.endOfWeek = formatDate(row.endOfWeek)
                row.startOfPayrollPeriod = formatDate(row.startOfPayrollPeriod)
                row.startOfWeek = formatDate(row.startOfWeek)
                if(row.accWeeklyCompensation && row.accWeeklyCompensation !== 0) row.accWeeklyCompensation = Number(row.accWeeklyCompensation.toFixed(2));
                if(row.actualHours && row.actualHours !== 0) row.actualHours = Number(row.actualHours.toFixed(2));
                if(row.additionalPayments && row.additionalPayments !== 0) row.additionalPayments = Number(row.additionalPayments.toFixed(2));
                if(row.postIncapacityEarnings && row.postIncapacityEarnings !== 0) row.postIncapacityEarnings = Number(row.postIncapacityEarnings.toFixed(2));
                if(row.rate20 && row.rate20 !== 0) row.rate20 = Number(row.rate20.toFixed(2));
                if(row.abatedACCWeeklyCompensation && row.abatedACCWeeklyCompensation !== 0) row.abatedACCWeeklyCompensation = Number(row.abatedACCWeeklyCompensation.toFixed(2));
                if(row.netWeeklyCompensation && row.netWeeklyCompensation !== 0) row.netWeeklyCompensation = Number(row.netWeeklyCompensation.toFixed(2));
                return row
            })

            x.data.payperiodtotals = x.data.payperiodtotals.map(row => {
                row.datePSLBalance = formatDate(row.datePSLBalance)
                row.endOfPayrollPeriod = formatDate(row.endOfPayrollPeriod)
                row.startOfPayrollPeriod = formatDate(row.startOfPayrollPeriod)
                if(row.postIncapacityEarnings && row.postIncapacityEarnings !== 0) row.postIncapacityEarnings = Number(row.postIncapacityEarnings.toFixed(2));
                if(row.actualHours && row.actualHours !== 0) row.actualHours = Number(row.actualHours.toFixed(2));
                if(row.additionalPayments && row.additionalPayments !== 0) row.additionalPayments = Number(row.additionalPayments.toFixed(2));
                if(row.netCompensation && row.netCompensation !== 0) row.netCompensation = Number(row.netCompensation.toFixed(2));
                if(row.topUp && row.topUp !== 0) row.topUp = Number(row.topUp.toFixed(2));
                if(row.pslHours && row.pslHours !== 0) row.pslHours = Number(row.pslHours.toFixed(2));
                return row
            })

            resolve(x.data)
        })
        .catch(x => reject(x.data))        
    })
});
