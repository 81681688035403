import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'reactstrap';
import MatTable from './components/MatTable';
import * as Api from './../../../utils/api/base/apiUrl';
import { useSelector ,useDispatch } from 'react-redux';
import ConfirmDialog from '../../../shared/components/ConfirmDialog';
import MaintainFactors from './components/MaintainFactors';

import axios from 'axios';

const rows = [
  {
    id: 'factor', disablePadding: true, label: 'Factor',
  },
  {
    id: 'validFrom', disablePadding: false, label: 'Valid From',
  },
  {
    id: 'validTo', disablePadding: false, label: 'Valid To',
  },
  {
    id: 'value', disablePadding: false, label: 'Value',
  },
];
const MaterialTable = (props) => {
  const dispatch = useDispatch();
  const maintainfactors = useSelector(state => state.getMaintainFactors.data);
  const [factor, setFactor] = useState((maintainfactors)?maintainfactors:[]);
  const [selected, setSelected] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const childRef = useRef();

  async function fetchMaintainFactors(searchStr) {
    setLoading(true);
    let queryString = (searchStr)?`?factor=${searchStr}`:''
    let response = await axios.get(`${Api.maintainFactorsApi}${queryString}`);
    setFactor(response.data)
    setLoading(false);
  }
  
  useEffect(() => {
    //dispatch(getMaintainFactors(`${Api.getMaintainFactors}`));
    fetchMaintainFactors();
  }, []);

  const clickToSearch = () => {
    if(searchString !== "")
      fetchMaintainFactors(searchString)    
  }

  const onChangeInputData = (value) => {
    setSearchString(value);
    fetchMaintainFactors(value)    
  }

  const handleDelete = (id) => {
    childRef.current.handleClickOpen();
    setSelected(id)
  };

  const deleteCall = (confirm) =>{
    if(confirm){
      setLoading(true);
      async function deleteReq(){
         let resp = await axios.post(`${Api.maintainFactorsApi}/${selected}/delete`);
         if(resp.data = 'ok'){
          fetchMaintainFactors()
         }else{
          setLoading(false)
         }
      }
      deleteReq();
    }else{
      setSelected(null)
    }
  }

  const handleAdd = () => {
    setEdit(false)
    setOpen(true);
  }

  const handleEdit = (record) => {
    setEdit(record)
    setOpen(true);
  }

  const handleCloseAdd = (flag,data)=> {
    setOpen(false);
    let valid = data && data.factor !== '';
    if(flag === true && valid){
      // Create Factor
        setLoading(true);
        async function addReq(){
          const apiURL = (edit && data && data.id)?`${Api.maintainFactorsApi}/${data.id}/edit`:`${Api.maintainFactorsApi}/create`;
          let resp = await axios.post(apiURL,{ data });
          if(resp.data = 'ok'){
            fetchMaintainFactors()
          }else{
            setLoading(false)
          }
      }
      addReq();
    }
  }

  let title = (edit)?"Update Factor":"Create Factor";
  return (
    <Container>
      {/* <Row>
        <Col md={12}>
          <h3 className="page-subhead subhead">ACCelerator - Maintain Factors
          </h3>
        </Col>
      </Row> */}
      {/* <Row> */}
        <MatTable
          headerCellData={rows} 
          onButtonClick = {clickToSearch}
          mfdata={factor} 
          title={'Maintain Factors'} 
          pageName="maintainfactors" 
          onChangeSearch={onChangeInputData} 
          handleDelete={handleDelete}
          isLoading = {loading}
          handleAdd = {handleAdd}
          handleEdit = {handleEdit}
        />
      <ConfirmDialog ref={childRef} title="Delete" message="Are you sure you want to Delete?" callback={deleteCall}/>
      <MaintainFactors open={open} edit={edit} handleClose={handleCloseAdd}  title={title}/>
      {/* </Row> */}
    </Container>
  );
}
export default MaterialTable;
