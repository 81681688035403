import * as Constant from '../../utils/constant'

export const validateInvoiceListRequest=(requestBody)=>({
    type:Constant.VALIDATE_INVOICE_LIST_REQUEST,
    payload:requestBody
})

export const validateInvoiceListSuccess=(response)=>({
    type:Constant.VALIDATE_INVOICE_LIST_SUCCESS,
    payload:response
})

export const validateInvoiceListFailure=(error)=>({
    type:Constant.VALIDATE_INVOICE_LIST_FAILURE,
    payload:error
})