import React, { useState, useRef, useEffect } from "react";
import { reduxForm } from "redux-form";
import Box from "@mui/material/Box";
import { Button } from "reactstrap";
import ProgressBar from "../../../../shared/components/ProgressBar";
import Papa from "papaparse";
import Ajv from "ajv";

const BulkUpdateOrdinaryPay = ({ loading, handleSubmit }) => {
  const inputFile = useRef(null);
  //const [CSVData, setCSVData] = useState();
  var commonConfig = { delimiter: "," };
  const ajv = new Ajv();

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const schema = {
    type: "object",
    properties: {
      employeeNumber: {
        type: "string",
        minLength: 1,
      },
      employeeName: { type: "string", minLength: 1 },
      effectiveDate: { type: "string", minLength: 1 },
      ordinaryPay: { type: "string", minLength: 1 },
    },
    required: [
      "employeeNumber",
      "employeeName",
      "effectiveDate",
      "ordinaryPay",
    ],
    additionalProperties: false,
  };

  const validate = ajv.compile(schema);

  const handleFileChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    let valid = true;
    var file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        ...commonConfig,
        header: true,
        complete: (result) => {
          result.data.pop();
          result.reCalcReason = 'topUpOrdinaryPay';
          for (const row in result.data) {
            valid = validate(result.data[row]);
            if (!valid) {
              alert(validate.errors[0]["message"]);
              break;
            }
          }

          if (valid) {
            delete result.errors;
            delete result.meta;
            handleSubmit(result, 2);
            alert("Successfully uploaded");
          }
        },
      });
    }
  };

  return (
    <>
      <Box sx={{ "& > :not(style)": { m: 1 } }} noValidate autoComplete="off">
        <a
          href={
            process.env.PUBLIC_URL +
            "/bulk_update/BulkUpdateOrdinaryPayTemplate.csv"
          }
          download="BulkUpdateOrdinaryPayTemplate.csv"
        >
          <Button type="submit" color="primary" onClick={() => {}}>
            {"Download Template"}
          </Button>
        </a>
        <a>
          <Button color="primary" onClick={onButtonClick}>
          {loading ? <ProgressBar size={"8"} /> : "Upload Data"}
          </Button>
          <input
            type="file"
            id="file"
            onChange={handleFileChange}
            ref={inputFile}
            style={{ display: "none" }}
          />
        </a>
      </Box>
    </>
  );
};

export default reduxForm({
  form: "reclaim_form", // a unique identifier for this form
  enableReinitialize: true,
})(BulkUpdateOrdinaryPay);
