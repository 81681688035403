import * as Constant from '../../utils/constant';
import * as Action from '../actions/invoiceListAction';
import getData from '../../utils/api/base/get';
//getListInvoice 

export const getInvoiceListAsync=(url)=>{
    console.log("url",url)
    return async dispatch=>{
        dispatch(Action.getInvoiceListRequest());
        try{
            const response = await getData(url,{},true); 
            if(response.errorData){
                dispatch(Action.getInvoiceListFailure(response.errorData));
            }else{
            const responseData = response.data;
            dispatch(Action.getInvoiceListSuccess(responseData));
            }
        } catch(error){
            dispatch(Action.getInvoiceListFailure(error));
        }
    }
}