import axios from 'axios';
import store from '../App/store';
import { formatDateForAPI as formatDate, formatDateForDisplay } from '../../utils/formatDate';

/* eslint-disable no-alert */
// API expects dates in format DD/MM/YYYY
// UI sets the dates in format YYYY-MM-DD

export default (async function CalcClaim(formValues) { 
  let newValues = new Object();
  let values = JSON.parse(JSON.stringify(formValues)); 
  if(values.indexationExemptionDates){
    newValues.indexationExemptionDates = values.indexationExemptionDates.filter((row) => {
      if(row.fromDate == null || row.toDate == null) return false;
      return true;
    }).map((row) => {
      row.fromDate = formatDate(row.fromDate);
      row.toDate = formatDate(row.toDate);
      return row;
    })
    if(!newValues.indexationExemptionDates){
      delete newValues.indexationExemptionDates;
    }
  }
  const empData =  store.getState().employeeDetails.employee
  if (values.reasonForUpdate != null && values.reasonForUpdate.value != null) {
    newValues.updateReason = values.reasonForUpdate.value;
  }
  const claim = store.getState().claim;
  if(claim.mode != "CREATE"){
    newValues.id = claim.updateClaimAPIData.claim.id;
  }
  
  newValues.claimNumber = values.claimNumber || "$Calculate"
  newValues.employeeNumber = values.employeeNumber || 0
  newValues.dateOfInjury = formatDate(values.dateOfInjury)
  newValues.certificateValidTo = formatDate(values.certificateValidTo)
  newValues.claimType = ((values.claimType) && (values.claimType.value === "DOSI")) ? "DOSI" : "DOFI"
  newValues.workRelated = ((values.injury) && (values.injury.value == "Work")) ? "W" : "N"
  newValues.ordinaryPay = values.ordinaryPay || 0
  newValues.dateOrdinaryPay = formatDate(values.effectiveDate)
  newValues.extraEarning = values.extraEarning || 0
  newValues.hourlyRate = values.hourlyRate || 0
  newValues.annualSalary = values.annualSalary || 0  
  newValues.hoursPerDay = values.hoursPerDay || 0
  newValues.daysPerWeek = values.daysPerWeek.value || 0
  newValues.annualizedHours = values.annualizedHours || 0
  newValues.employmentWeeks = values.employmentWeeks || 0
  newValues.ordinaryPayFourWeeks = values.earning4weeks || 0
  newValues.ordinaryPay52Weeks = values.earning52weeks || 0
  newValues.unpaidDaysFourWeeks = values.unpaidDaysFourWeeks || 0
  newValues.unpaidDays52Weeks = values.unpaidDays52Weeks || 0
  newValues.accDaysFourWeeks = values.accDaysFourWeeks || 0
  newValues.accDays52Weeks = values.accDays52Weeks || 0
  newValues.otherEarningsFourWeeks = values.otherEarningsFourWeeks || 0
  newValues.otherEarnings52Weeks = values.otherEarnings52Weeks || 0  
  newValues.unpaidDaysFourWeeksPrevEmployer = values.unpaidDaysFourWeeksPrevEmployer || 0
  newValues.unpaidDays52WeeksPreviousEmployer = values.unpaidDays52WeeksPreviousEmployer || 0
  newValues.overwriteDivisor4Weeks = values.overwriteDivisor4Weeks
  newValues.overwriteDivisor52Weeks = values.overwriteDivisor52Weeks
  newValues.pslOpeningBalance = values.pslOpeningBalance || 0
  if(values.datePSLBalance){
    newValues.datePSLBalance = formatDate(values.datePSLBalance)
  }
  newValues.working30Hours = values.working30Hours || false
  newValues.worked52Weeks = values.yearlyEarnings || false
  newValues.pslTopUp = values.pslTopUp || false
  newValues.standDownHours = values.standDownHours || 0
  if(values.dofiDate){
    newValues.dofiDate = formatDate(values.dofiDate)
  }
  if(newValues.workRelated === 'W'){
    newValues.eraOptOut = values.optOutClaim.value || ''
    newValues.managedByACC = values.managedByACC.value || ''
    if(values.managedFromDate){
      newValues.managedFromDate = formatDate(values.managedFromDate)
    }
  }
  if(values.dateChangeRuleGrp){
    newValues.dateRuleGroupChange = formatDate(values.dateChangeRuleGrp)
  }
	newValues.continuedClaim = values.continueClaim ? "X": " "
  if(values.dateContinue){
	  newValues.continuationDate = formatDate(values.dateContinue)
  }
  newValues.abatementHourlyRate = values.hrsRateForTopup || 0
  newValues.accStatus = values.accStatus?.value
  newValues.employeeName = values.firstName + "  " + values.lastName      
  newValues.numberOfDaysCovered = values.days || 0  
  newValues.indexation = values.indexation || false
  newValues.newDOSI = values.newDOSI === "yes" ? true : false
  newValues.paymentFrequency = empData.payFrequency
  if(values.calculationBasis == "STE") newValues.calculationBasis = "S"
  else if(values.calculationBasis == "LTE") newValues.calculationBasis = "L"
  else if(values.calculationBasis == "STE & LTE") newValues.calculationBasis = "B"
  newValues.payType = empData.paymentType;
  newValues.employmentType = empData.employmentType;
    
  // window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_BASE_URL + `/claim/calculatecompensation`, newValues)
        .then(x => {            
            x.data.payments = x.data.payments.map(row => {
              row.dateOrdinaryPay = formatDateForDisplay(row.dateOrdinaryPay)
              row.endOfPayrollPeriod = formatDateForDisplay(row.endOfPayrollPeriod)
              row.endOfWeek = formatDateForDisplay(row.endOfWeek)
              row.startOfPayrollPeriod = formatDateForDisplay(row.startOfPayrollPeriod)
              row.startOfWeek = formatDateForDisplay(row.startOfWeek)
              return row
            })

          x.data.totals = x.data.totals.map(row => {
              row.datePSLBalance = formatDateForDisplay(row.datePSLBalance)
              row.endOfPayrollPeriod = formatDateForDisplay(row.endOfPayrollPeriod)
              row.startOfPayrollPeriod = formatDateForDisplay(row.startOfPayrollPeriod)
              return row
          })
          if(x.data.indexationExemptionDates != null){
            x.data.indexationExemptionDates = x.data.indexationExemptionDates.map(row => {
                row.fromDate = formatDate(row.fromDate);
                row.toDate = formatDate(row.toDate);
                return row;
            })
          }
          // console.log("GET CLAIM DATA:", x.data)
          resolve(x.data)
        })
        .catch(x => reject(new Error(x.data)))
  })
});
