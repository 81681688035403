import React, { forwardRef, useImperativeHandle  } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = forwardRef((props, ref) => {
  const { title, message, callback } = props;
  const [open, setOpen] = React.useState(false);
   
  useImperativeHandle(ref, () => ({
    handleClickOpen(){
        setOpen(true);
      }
  }));

  const handleClose = (flag) => {
    setOpen(false);
    if(true === flag)
      callback(flag)
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button onClick={() => handleClose(true)} autoFocus>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
export default ConfirmDialog;
