import { VALIDATE_INVOICE_LIST_REQUEST, VALIDATE_INVOICE_LIST_SUCCESS, VALIDATE_INVOICE_LIST_FAILURE } from '../../utils/constant';

export const initialState = {
    isLoading: false,
    validateInvoiceData: null,
    errorMessage: ''
}


export const validateInvoiceListReducer = (state = initialState, action) => {
    switch (action.type) {
        case VALIDATE_INVOICE_LIST_REQUEST:
            return { ...state, isLoading: true }
        case VALIDATE_INVOICE_LIST_SUCCESS:
            return { ...state, isLoading: false, validateInvoiceData: action.payload }
        case VALIDATE_INVOICE_LIST_FAILURE:
            return { ...state, isLoading: false, validateInvoiceData: action.payload }
        default:
            return state;
    }

}