import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UserProps } from '../../../shared/prop-types/ReducerProps';
import TopbarSidebarButton from './TopbarSidebarButton';
import { APP_VERSION } from '../../../index'

const versionStyle = {
  position:'absolute', 
  background:'red',
  top:0,
  left:'400px', 
  padding:'0px 10px',
  color: 'white',
  fontWeight: 'bold'
}

const Topbar = ({
  changeMobileSidebarVisibility, changeSidebarVisibility, user,
}) => (
  <div className="topbar">
    <div className="topbar__left">
      <TopbarSidebarButton
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      <Link className="topbar__logo" to="/tables/data_table" />
      <Link className="accelerator" to="/tables/data_table" />
      <div style={versionStyle}>{APP_VERSION}</div>
    </div>
  </div>
);

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  user: UserProps,
};

Topbar.defaultProps = {
  user: {},
};

export default Topbar;
