import {SET_WITHDRAW_CLAIM_MODE,SET_WITHDRAW_CLAIM_DATA,WITHDRAW_REQUEST_CLAIM_REQUEST,WITHDRAW_REQUEST_CLAIM_SUCCESS,WITHDRAW_REQUEST_CLAIM_FAILURE,RESET_WITHDRAW_CLAIM_DATA} from '../../utils/constant'

const initialState = {
    withdrawClaimMode:null,
    withdrawClaimData:{},
    withdrawClaimApiData:{}
};

const withdrawClaimReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WITHDRAW_CLAIM_MODE: return { ...state, withdrawClaimMode: action.payload };
        case SET_WITHDRAW_CLAIM_DATA: return { ...state, withdrawClaimData: action.payload };
        case WITHDRAW_REQUEST_CLAIM_REQUEST: return { ...state};
        case WITHDRAW_REQUEST_CLAIM_SUCCESS: return { ...state,withdrawClaimApiData:action.payload};
        case WITHDRAW_REQUEST_CLAIM_FAILURE: return { ...state,withdrawClaimApiData:action.payload};
        case RESET_WITHDRAW_CLAIM_DATA: return initialState
        default: return state;
    }
};

export default withdrawClaimReducer;
