import themeReducer from './themeReducer';
import rtlReducer from './rtlReducer';
import roundBordersReducer from './roundBordersReducer';
import blocksShadowsReducer from './blocksShadowsReducer';
import sidebarReducer from './sidebarReducer';
import customizerReducer from './customizerReducer';
import todoReducer from './todoReducer';
import authReducer from './authReducer';
import { getInvoiceListReducer } from './invoiceListReducer';
import { validateInvoiceListReducer } from './validateInvoiceReducer';
import { creteInvoiceMultiReducer } from './accInvoiceMultiCreateReducer';
import { getDataDownloadtReducer } from './getDownloadDataReducer';
import claimReducer from './claimReducer'
import withdrawClaimReducer from './withdrawClaimReducer';
import closeClaimReducer from './closeClaimReducer';
import { getMaintainFactorsReducer } from './maintainFactorsReducer';
import employeeDetailReducer from './employeeDetailReducer'

export {
  themeReducer,
  rtlReducer,
  sidebarReducer,
  customizerReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  todoReducer,
  authReducer,
  getInvoiceListReducer,
  validateInvoiceListReducer,
  creteInvoiceMultiReducer,
  getDataDownloadtReducer,
  claimReducer,
  withdrawClaimReducer,
  closeClaimReducer,
  getMaintainFactorsReducer,
  employeeDetailReducer
};
