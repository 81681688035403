import * as Constant from '../../utils/constant'

export const createInvoiceListRequest=(requestBody)=>({
    type:Constant.ACC_INVOICE_MULTI_REQUEST,
    payload:requestBody
})

export const createInvoiceListSuccess=(response)=>({
    type:Constant.ACC_INVOICE_MULTI_SUCCESS,
    payload:response
})

export const createInvoiceListFailure=(error)=>({
    type:Constant.ACC_INVOICE_MULTI_FAILURE,
    payload:error
})