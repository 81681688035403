import React, { useState } from "react";
import { Field, reduxForm } from 'redux-form';
import renderDateField from '../../../../shared/components/form/DatePicker';
import Box from '@mui/material/Box';
import { Button } from 'reactstrap';
import ProgressBar  from '../../../../shared/components/ProgressBar';

const Abatement = ({title, handleSubmit, loading, pristine, submitting, reset}) => {
  const [employeeNumber, setEno] = useState('');
  const [claimNumber, setCno] = useState('');
  const [startOfWeek, setPaymentStartDate] = useState();
  const [endOfWeek, setPaymentEndDate] = useState();

  const handleChange = (e,setIdentifierState) => {
    const { name, value} = e.target;
    setIdentifierState(value)
  };

  return (
    <>
      <Box component="form" sx={{  width: 500 ,'& > :not(style)': { m: 1 },}} noValidate autoComplete="off">
        <form className="form form--horizontal wizard__form">
          <h3 className="wizard__title">{ title }</h3>
          <div className="form__form-group">
              <span className="form__form-group-label">Employee Number</span>
              <div className="form__form-group-field">
                <div className="container">
                  <Field
                      name="employeeNumber"
                      id="employeeNumber"
                      placeholder="Employee Number"
                      type="text"
                      component="input"
                      value={employeeNumber}
                      onChange={(e)=>handleChange(e,setEno)}
                      // component={validation.renderField}
                      // validate={[validation.required]}
                  />
                </div>
              </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Claim Number</span>
            <div className="form__form-group-field">
              <div className="container">
                <Field
                    name="claimNumber"
                    id="claimNumber"
                    placeholder="Claim Number"
                    type="text"
                    component="input"
                    value={claimNumber}
                    onChange={(e)=>handleChange(e,setCno)}
                />
              </div>
            </div>
          </div>
        
         <div className="form__form-group">
            <span className="form__form-group-label">Payment Date</span>
            <div className="form__form-group-field">
              <div className="container">
                <Field
                id="startOfWeek"
                name="startOfWeek"
                placeholder="Start Date"
                value={startOfWeek}
                component={renderDateField}
                onChange={(val)=>setPaymentStartDate(val)}
              />
              </div>
              To
              <div className="container">
              <Field
                id="endOfWeek"
                name="endOfWeek"
                placeholder="End Date"
                value={endOfWeek}
                component={renderDateField}
                onChange={(val)=>setPaymentEndDate(val)}
                />
              </div>
            </div>
          </div>  
        </form>
      </Box>
      <Box sx={{'& > :not(style)': { m: 1 },}} noValidate autoComplete="off">  
        <Button type="submit" disabled={pristine || submitting}  color="primary" onClick={handleSubmit}> {loading?<ProgressBar size={"8"} />:'Download'} </Button>
        <Button disabled={pristine || submitting}  color="primary" onClick={reset}> {'Reset'} </Button>
      </Box>
    </>
  );
};

export default reduxForm({
    form: 'abatement_form', // a unique identifier for this form
    enableReinitialize:true
  })(Abatement);