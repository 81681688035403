import {SET_CLOSE_CLAIM_MODE,SET_CLOSE_CLAIM_DATA,CLOSE_REQUEST_CLAIM_REQUEST,CLOSE_REQUEST_CLAIM_SUCCESS,CLOSE_REQUEST_CLAIM_FAILURE,RESET_CLOSE_CLAIM_DATA} from '../../utils/constant'
export const setCloseClaimModeAction = mode => {
    return {
        type: SET_CLOSE_CLAIM_MODE,
        payload: mode
    }
}

export const setCloseClaimDataAction = data => {
    return {
        type: SET_CLOSE_CLAIM_DATA,
        payload: data
    }
}
export const closeClaimDataRequest = requestBody => {
    return {
        type: CLOSE_REQUEST_CLAIM_REQUEST,
        payload: requestBody
    }
}
export const closeClaimDataSuccess = resposne => {
    return {
        type: CLOSE_REQUEST_CLAIM_SUCCESS,
        payload: resposne
    }
}
export const closeClaimDataFailure = error => {
    return {
        type: CLOSE_REQUEST_CLAIM_FAILURE,
        payload: error
    }
}
export const resetcloseClaimData = () => {
    return {
        type: RESET_CLOSE_CLAIM_DATA,
    }
}