import React, { useState } from "react";
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../shared/components/form/Select';
import Box from '@mui/material/Box';
import { Button } from 'reactstrap';
import ProgressBar  from '../../../../shared/components/ProgressBar';

const Employee = ({title, handleSubmit, loading, pristine, submitting, reset}) => {
  const [employeeNumber, setEno] = useState('');
  const [employeeFirstName, setFname] = useState('');
  const [employeeLastName, setLname] = useState('');
  const [irdNumber, setEird] = useState('');
  const [claimNumber, setCno] = useState('');
  const [payCategory, setPayCategory] = useState(null);
  const [payType, setPayType] = useState(null);
  const [payFrequency, setPayFrequency] = useState(null);

  const handleChange = (e,setIdentifierState) => {
    const { name, value} = e.target;
    setIdentifierState(value)
  };

  return (
    <>
      <Box component="form" sx={{  width: 500 ,'& > :not(style)': { m: 1 },}} noValidate autoComplete="off">
        <form className="form form--horizontal wizard__form">
          <h3 className="wizard__title">{ title }</h3>
          <div className="form__form-group">
              <span className="form__form-group-label">Employee Number</span>
              <div className="form__form-group-field">
                <div className="container">
                  <Field
                      name="employeeNumber"
                      id="employeeNumber"
                      placeholder="Employee Number"
                      type="text"
                      component="input"
                      value={employeeNumber}
                      onChange={(e)=>handleChange(e,setEno)}
                  />
                </div>
              </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Employee</span>
            <div className="form__form-group-field">
              <div className="container">
                  <Field
                  name="employeeFirstName"
                  id="employeeFirstName"
                  placeholder="First Name"
                  type="text"  
                  component="input"
                  value={employeeFirstName}
                  onChange={(e)=>handleChange(e,setFname)}
                  />
              </div>
              <div className="container">
                  <Field
                  type="text"
                  name="employeeLastName"
                  id="employeeLastName"
                  placeholder="Last Name"
                  component="input"
                  value={employeeLastName}
                  onChange={(e)=>handleChange(e,setLname)}
                  />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Employee IRD</span>
            <div className="form__form-group-field">
              <div className="container">
                <Field
                    name="irdNumber"
                    id="irdNumber"
                    placeholder="Employee IRD"
                    type="text"
                    component="input"
                    value={irdNumber}
                    onChange={(e)=>handleChange(e,setEird)}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Claim Number</span>
            <div className="form__form-group-field">
              <div className="container">
                <Field
                    name="claimNumber"
                    id="claimNumber"
                    placeholder="Claim Number"
                    type="text"
                    component="input"
                    value={claimNumber}
                    onChange={(e)=>handleChange(e,setCno)}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Pay Category</span>
            <div className="form__form-group-field">
              <div className="container">
                <Field
                    name="payCategory"
                    id="payCategory"
                    placeholder="Pay Category"
                    type="text"
                    component="input"
                    value={payCategory}
                    onChange={(e)=>handleChange(e,setPayCategory)}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Pay Type</span>
            <div className="form__form-group-field">
              <div className="container">
                <Field
                    name="payType"
                    id="payType"
                    placeholder="Pay Type"
                    type="text"
                    component={renderSelectField}
                    options={[
                      { value: "S" , label: 'Salaried' },
                      { value: "W" , label: 'Waged' },
                    ]}
                    onChange={(e)=>setPayType(e.value)}
                />
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Pay Frequency</span>
            <div className="form__form-group-field">
              <div className="container">
                <Field
                    name="payFrequency"
                    id="payFrequency"
                    placeholder="Pay Frequency"
                    type="text"
                    component={renderSelectField}
                    options={[
                      { value: "W" , label: 'Weekly' },
                      { value: "F" , label: 'Fortnightly' },
                      { value: "M" , label: 'Monthly' },
                    ]}
                    onChange={(e)=>setPayFrequency(e.value)}
                />
              </div>
            </div>
          </div>  
        </form>
      </Box>
      <Box sx={{'& > :not(style)': { m: 1 },}} noValidate autoComplete="off">  
        <Button type="submit"disabled={pristine || submitting}  color="primary" onClick={handleSubmit}> {loading?<ProgressBar size={"8"} />:'Download'} </Button>
        <Button disabled={pristine || submitting}  color="primary" onClick={reset}> {'Reset'} </Button>
      </Box>
    </>
  );
};

export default reduxForm({
    form: 'employee_form', // a unique identifier for this form
  })(Employee);