import axios, { defaultParams } from './axios';

export default async (url, params,errorVal=false) => {
  try {
    return await axios.get(url, { ...defaultParams(), ...params });
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401){
      if (errorVal){
         return {
           errorData:e.response
         }
      } throw e;
    }
    try {
      return axios.get(url, { ...defaultParams(), ...params });
    } catch (err) {
      return null;
    }
  }
};
