import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import LabelPanelDivider from './components/LabelPanelDivider';
import { setDataChanged } from '../../../redux/actions/claimAction';
import store from '../../App/store';

const DataTable = () => {
  const { t } = useTranslation('common');
  store.dispatch(setDataChanged(false));
  return (
    <Container>
      {/* <Row>
        <Col md={12}>
          <h3 className="page-title">{t('tables.data_table.title')}</h3>
          <h3 className="page-subhead subhead">ACCelerator - Dashboard
          </h3>
        </Col>
      </Row> */}
      {/* <Row> */}
          <LabelPanelDivider />
      {/* </Row> */}
    </Container>
  );
};

export default DataTable;
