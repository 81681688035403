import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccInvoice from '../../../Form/ReduxForm/FileUpload/index';
import WizardForm from '../../../Form/ReduxForm/WizardForm/index';
import { ProtectedRoute } from '../../../../shared/components/auth/ProtectedRoute'
import { pageConf } from '../../../../shared/components/auth/routeGuard'

export default () => (
  <Switch>
    <ProtectedRoute path="/forms/redux_form/file_upload" roles={pageConf.ACCInvoiceRoute.roles} component={AccInvoice} />
    <ProtectedRoute path="/forms/redux_form/wizard_form" roles={pageConf.createClaimRoute.roles} component={WizardForm} />
  </Switch>
);
