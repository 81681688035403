import React from 'react';
import { FormGroup, Label, Input, NavItem } from 'reactstrap';

export const RadioButton = ({ title, value, name, onChange, checked, disabled }) => {
    return (
        <FormGroup check>
            <Label check>
                <Input type="radio" name={name} value={value} onChange={onChange} disabled= {disabled} checked={checked===value}/>{' '}
                {title}
            </Label>
        </FormGroup>
    )
}