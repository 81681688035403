export function factorsList(){
    return ( 
        [
            { value: 'accPaymentPercentage', label: 'ACC Payment %' },
            { value: 'accPaymentPercentageSalaried', label: 'ACC Payment % for Salaried Employees' },
            { value: 'annualizedHoursSalaried', label: 'Annualized Hours for Salaried Employees' },
            { value: 'divisorFortnightlyHours', label: 'Divisor for Fortnightly Hours' },
            { value: 'divisorFortnightlyLeapYear', label: 'Divisor for Fortnightly Hrs in Leap Year' },
            { value: 'lteWeeks', label: 'LTE Period in Weeks' },
            { value: 'maximumIndexationRate', label: 'Maximum Indexation Rate' },
            { value: 'minimumIndexationRate', label: 'Minimum Indexation Rate' },
            { value: 'PP Fortnightly', label: 'Payroll Period Fortnightly' },
            { value: 'PP Monthly', label: 'Payroll Period Monthly' },
            { value: 'PP Weekly', label: 'Payroll Period Weekly' },
            { value: 'steDivisor', label: 'Default STE Divisor' },
            { value: 'steWeeks', label: 'STE Period in Weeks' },
            { value: 'baseWeeklyCompensationIncrease', label: 'Base Weekly Comp Increase %'},
            { value: 'weeksBeforeCompensationIncrease', label: 'No. of weeks before Base Weekly Comp. Increases'}
        ]
    );
}