export const EMPLOYEE_GROUPS = {
    GRP1: "GRP1",
    GRP2: "GRP2",
    GRP3: "GRP3",
    GRP4: "GRP4",
    GRP5: "GRP5",
    GRP6: "GRP6",
    GRP7: "GRP7",
    GRP8: "GRP8",
    GRP9: "GRP9",
    GRP10: "GRP10",
    GRP11: "GRP11",
    GRP12: "GRP12",
    GRP13: "GRP13",
    GRP14: "GRP14",
}

export const getEmployeeGroup = (paymentType, payFrequency, employmentType, annualizedHours) => {
    if(paymentType === "S" && payFrequency === "M" && employmentType === "A") return EMPLOYEE_GROUPS.GRP1
    else if(paymentType === "S" && payFrequency === "F" && employmentType === "A") return EMPLOYEE_GROUPS.GRP2
    else if(paymentType === "S" && payFrequency === "M" && employmentType === "C") return EMPLOYEE_GROUPS.GRP3
    else if(paymentType === "S" && payFrequency === "F" && employmentType === "C") return EMPLOYEE_GROUPS.GRP4
    else if(paymentType === "S" && payFrequency === "M" && employmentType === "B") return EMPLOYEE_GROUPS.GRP5
    else if(paymentType === "S" && payFrequency === "F" && employmentType === "B") return EMPLOYEE_GROUPS.GRP6
    else if(paymentType === "S" && payFrequency === "M" && employmentType === "D") return EMPLOYEE_GROUPS.GRP7
    else if(paymentType === "S" && payFrequency === "F" && employmentType === "D") return EMPLOYEE_GROUPS.GRP8
    else if(paymentType === "W" && payFrequency === "M" && annualizedHours) return EMPLOYEE_GROUPS.GRP9
    else if(paymentType === "W" && payFrequency === "F" && annualizedHours) return EMPLOYEE_GROUPS.GRP10
    else if(paymentType === "W" && payFrequency === "W" && annualizedHours) return EMPLOYEE_GROUPS.GRP11
    else if(paymentType === "W" && payFrequency === "M" && (!annualizedHours || annualizedHours == 0)) return EMPLOYEE_GROUPS.GRP12
    else if(paymentType === "W" && payFrequency === "F" && (!annualizedHours || annualizedHours == 0)) return EMPLOYEE_GROUPS.GRP13
    else if(paymentType === "W" && payFrequency === "W" && (!annualizedHours || annualizedHours == 0)) return EMPLOYEE_GROUPS.GRP14
    else return ""
}

const FIELD_EDITABLE_MAPPING = {
    annualizedHours: [EMPLOYEE_GROUPS.GRP12, EMPLOYEE_GROUPS.GRP13, EMPLOYEE_GROUPS.GRP14],
    daysPerWeek: [EMPLOYEE_GROUPS.GRP1, EMPLOYEE_GROUPS.GRP2, EMPLOYEE_GROUPS.GRP3, EMPLOYEE_GROUPS.GRP4, EMPLOYEE_GROUPS.GRP5, EMPLOYEE_GROUPS.GRP6, EMPLOYEE_GROUPS.GRP7, EMPLOYEE_GROUPS.GRP8, EMPLOYEE_GROUPS.GRP9, EMPLOYEE_GROUPS.GRP10, EMPLOYEE_GROUPS.GRP11, EMPLOYEE_GROUPS.GRP12, EMPLOYEE_GROUPS.GRP13, EMPLOYEE_GROUPS.GRP14],
    hoursPerDay: [],
    hoursPerMonth: [EMPLOYEE_GROUPS.GRP3, EMPLOYEE_GROUPS.GRP5, EMPLOYEE_GROUPS.GRP7],
    hoursPerFornight: [EMPLOYEE_GROUPS.GRP2, EMPLOYEE_GROUPS.GRP4, EMPLOYEE_GROUPS.GRP6, EMPLOYEE_GROUPS.GRP8],
    weeklyHours: [],
    ordinaryPay: [],
    hourlyRate: []
}

const FIELD_HIDDEN_MAPPING = {
    annualizedHours: [],
    daysPerWeek: [],
    hoursPerDay: [EMPLOYEE_GROUPS.GRP12, EMPLOYEE_GROUPS.GRP13, EMPLOYEE_GROUPS.GRP14],
    hoursPerMonth: [EMPLOYEE_GROUPS.GRP2, EMPLOYEE_GROUPS.GRP4, EMPLOYEE_GROUPS.GRP6, EMPLOYEE_GROUPS.GRP8, EMPLOYEE_GROUPS.GRP10, EMPLOYEE_GROUPS.GRP11, EMPLOYEE_GROUPS.GRP12, EMPLOYEE_GROUPS.GRP13, EMPLOYEE_GROUPS.GRP14],
    hoursPerFornight: [EMPLOYEE_GROUPS.GRP1, EMPLOYEE_GROUPS.GRP3, EMPLOYEE_GROUPS.GRP5, EMPLOYEE_GROUPS.GRP7, EMPLOYEE_GROUPS.GRP9, EMPLOYEE_GROUPS.GRP11, EMPLOYEE_GROUPS.GRP12, EMPLOYEE_GROUPS.GRP13, EMPLOYEE_GROUPS.GRP14],
    weeklyHours: [EMPLOYEE_GROUPS.GRP12, EMPLOYEE_GROUPS.GRP13, EMPLOYEE_GROUPS.GRP14],
    ordinaryPay: [],
    hourlyRate: []
}

export const isFieldEditable = (fieldName, empGroup) => {
    return  FIELD_EDITABLE_MAPPING[fieldName].filter(fieldGroup => [empGroup].includes(fieldGroup)).length ? true : false  
}

export const isFieldHidden = (fieldName, empGroup) => {
    return  FIELD_HIDDEN_MAPPING[fieldName].filter(fieldGroup => [empGroup].includes(fieldGroup)).length ? true : false 
}