import { pageConf, appRoles } from "./routeGuard";

const UIGuardMappings = {
  sidebar: {
    dashboard: pageConf.dashboardRoute.roles,
    createClaim: pageConf.createClaimRoute.roles,
    maintainFactor: pageConf.maintainFactorRoute.roles,
    ACCInvoives: pageConf.ACCInvoiceRoute.roles,
    reports: pageConf.reportsRoute.roles,
    dataDownload: pageConf.dataDownloadRoute.roles,
    documentation: pageConf.documentationRoute.roles,
    reCalculation: pageConf.reCalculationRoute.roles,
  },
  dashboard: {
    buttons: {
      edit: [appRoles.ACCSpecialityAdmin, appRoles.ACCSpecialityNZ],
      updateClaim: [
        appRoles.ACCSpecialityAdmin,
        appRoles.ACCSpecialityNZ,
        appRoles.ACCPayrollNZ,
      ],
      viewClaim: [
        appRoles.ACCSpecialityAdmin,
        appRoles.ACCSpecialityNZ,
        appRoles.ACCPayrollNZ,
        appRoles.ACCHROpsSupport,
      ],
      withdrawClaim: [appRoles.ACCSpecialityAdmin, appRoles.ACCSpecialityNZ],
      closeClaim: [appRoles.ACCSpecialityAdmin, appRoles.ACCSpecialityNZ],
    },
  },
};

export const sideBarUIGuard = UIGuardMappings.sidebar;
export const dashboardButtonsUIGuard = UIGuardMappings.dashboard.buttons;
