// API returns dates in timestamp format YYYY-MM-DDTHH:mm:ss.nnnnnnn 
// API expects dates in format DD/MM/YYYY
// UI sets the dates in format YYYY-MM-DD

export const formatDateForDisplay = (oldDateFormat) => {
    if(!oldDateFormat) return oldDateFormat;
    let date = new Date(oldDateFormat);
    const day = date.toLocaleString('en-NZ', { day: '2-digit' });
    const month = date.toLocaleString('en-NZ', { month: '2-digit' });
    const year = date.toLocaleString('en-NZ', { year: 'numeric' });
    if (day !== 'Invalid Date') return year + '-' + month + '-' + day;
    return oldDateFormat
}

export const formatDateAsText = (oldDateFormat) => {
  if(!oldDateFormat) return oldDateFormat;
  let date = new Date(oldDateFormat);
  const day = date.toLocaleString('en-NZ', { day: '2-digit' });
  const month = date.toLocaleString('en-NZ', { month: 'short' });
  const year = date.toLocaleString('en-NZ', { year: 'numeric' });
  if (day !== 'Invalid Date') return day + '-' + month + '-' + year;
  return oldDateFormat
}

//oldDateFormat is expected to be DD/MM/YYYY
export const formatDateForAPI = (oldDateFormat) => {
    if(!oldDateFormat) return oldDateFormat;
    let dateComponents = oldDateFormat.split("/");
    if(dateComponents?.length === 3) return oldDateFormat;
    dateComponents = oldDateFormat.split("T");
    if(dateComponents.length > 1){
      dateComponents = dateComponents[0].split("-");
    }else{
      dateComponents = oldDateFormat.split("-");
    }
    if(dateComponents?.length < 3) return oldDateFormat;
    return dateComponents[2].length === 4?  dateComponents[0] + '/' + dateComponents[1] + '/' + dateComponents[2] : dateComponents[2] + '/' + dateComponents[1] + '/' + dateComponents[0];
    //let date = dateComponents[2].length === 4? new Date(dateComponents[2], dateComponents[1], dateComponents[0]): new Date(dateComponents[0], dateComponents[1], dateComponents[2]);
    // try{
    //   const day = date.toLocaleString('en-NZ', { day: '2-digit' });
    //   const month = date.toLocaleString('en-NZ', { month: '2-digit' });
    //   const year = date.toLocaleString('en-NZ', { year: 'numeric' });
    //   if (day != 'Invalid Date') return day + '/' + month + '/' + year;
    // }catch{
    //   return oldDateFormat;
    // }
}