import axios from './axios';

export default async (url, body, params,errorVal=false) => {
  try {
    return await axios.post(url, body);
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401){
      if (errorVal){
         return {
           errorData:e.response
         }
      } throw e;
    }
    try {
      return axios.post(url, body);
    } catch (err) {
      return null;
    }
  }
};
