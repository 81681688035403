const initialState = {
    employee: {},
};

const employeeDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_EMPLOYEE_DETAILS': return { ...state, employee: action.payload }
        default: return state;
    }
};

export default employeeDetailReducer;
