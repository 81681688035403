import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import renderToggleButtonField from '../../../../../shared/components/form/ToggleButton';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderDateFieldArray from '../../../../../shared/components/form/renderDateFieldArray';
import { isValidEditableReason } from './UpdateMatrix'
import * as validation from './validations'
import { loadAPIClaimData, duplicateClaimAPICheck } from './apiCalls'
import { formatDateForDisplay as formatDate} from '../../../../../utils/formatDate'
import { EMPLOYMENT_TYPES } from '../../../../../utils/constant'
import { Prompt } from "react-router-dom";
import { validReasonToCall } from './UpdateMatrix'
import { setDataChanged } from '../../../../../redux/actions/claimAction';
import store from '../../../../App/store';

const WizardFormTwo = ({ handleSubmit, previousPage, change }) => {
  const empDetails = useSelector((state)=>state.employeeDetails.employee)
  const [dofiDOSIDate, setDofiDOSIDate] = useState();
  const [medCertExpDate, setMedCertExpDate] = useState("");
  const [ACCDays, setACCDays] = useState(0);
  const [havePSLTopUpDays, setHavePSLTopUpDays] = useState(false);
  const formData =  useSelector((state)=>state.form.wizard.values);
  const updateClaimData = useSelector((state)=>state.claim)
  const isUpdateToClaim = useSelector((state)=>state.claim.mode === "UPDATE");
  const isCreateClaim = useSelector((state) => state.claim.mode === "CREATE")
  
  let reasonForUpdate = useSelector((state)=>state.form.wizard);
  try{
    reasonForUpdate = reasonForUpdate.values.reasonForUpdate.value
  } catch(e){
    reasonForUpdate = null
  }
  const setUpdateClaimValues = () => {
    let dbDateOfInjury = formatDate(updateClaimData.updateClaimAPIData.claim.dateOfInjury);
    change("dateOfInjury", dbDateOfInjury);
    setDofiDOSIDate(dbDateOfInjury);

    if(formData.certificateValidTo){
      change("certificateValidTo", formData.certificateValidTo);
      setMedCertExpDate(formData.certificateValidTo);
    }else if(updateClaimData.updateClaimAPIData.claim.certificateValidTo){
      let dbCertificateValidTo = formatDate(updateClaimData.updateClaimAPIData.claim.certificateValidTo)
      change("certificateValidTo", dbCertificateValidTo);
      setMedCertExpDate(dbCertificateValidTo);
    }

    if(formData.days) change("days",formData.days)
    else if(updateClaimData.updateClaimAPIData.claim.numberOfDaysCovered) change("days", updateClaimData.updateClaimAPIData.claim.numberOfDaysCovered)
    
    if(formData.calculationBasis){
      change("calculationBasis", formData.calculationBasis)
    } else {
      if(updateClaimData.updateClaimAPIData.claim.calculationBasis === 'S') change("calculationBasis", "STE")
      else if(updateClaimData.updateClaimAPIData.claim.calculationBasis === 'L') change("calculationBasis", "LTE")
      else if(updateClaimData.updateClaimAPIData.claim.calculationBasis === 'B') change("calculationBasis", "STE & LTE")
    }

    if(formData.accStatus){
      if(formData.accStatus.value === "Fully Unfit") change("accStatus", { value: 'Fully Unfit', label: 'Fully Unfit' })
      else if(formData.accStatus.value === "Light Duties") change("accStatus", { value: 'Light Duties', label: 'Light Duties' })
      else if(formData.accStatus.value === "Return to Work") change("accStatus", { value: 'Return to Work', label: 'Return to Work' })
      else if(formData.accStatus.value === "To be confirmed") change("accStatus", { value: 'To be confirmed', label: 'ACC Status TBC' })
    }
    else if(updateClaimData.updateClaimAPIData.claim.accStatus){
      if(updateClaimData.updateClaimAPIData.claim.accStatus === "Fully Unfit") change("accStatus", { value: 'Fully Unfit', label: 'Fully Unfit' })
      else if(updateClaimData.updateClaimAPIData.claim.accStatus === "Light Duties") change("accStatus", { value: 'Light Duties', label: 'Light Duties' })
      else if(updateClaimData.updateClaimAPIData.claim.accStatus === "Return to Work") change("accStatus", { value: 'Return to Work', label: 'Return to Work' })
      else if(updateClaimData.updateClaimAPIData.claim.accStatus === "To be confirmed") change("accStatus", { value: 'To be confirmed', label: 'ACC Status TBC' })      
    }    
    
    if(formData.pslTopUp){
      if(formData.pslTopUp){
        setHavePSLTopUpDays(true)
        localStorage.setItem("localpslTopUp", true)
      } else {
        setHavePSLTopUpDays(false)
        localStorage.setItem("localpslTopUp", false)
      }
    }
    else if(updateClaimData.updateClaimAPIData.claim.pslTopUp && !formData.pslTopUp){      
      if(updateClaimData.updateClaimAPIData.claim.pslTopUp === "X"){
        setHavePSLTopUpDays(true)
        localStorage.setItem("localpslTopUp", true)
      } else {
        setHavePSLTopUpDays(false)
        localStorage.setItem("localpslTopUp", false)
      }
    }    

    if(updateClaimData.updateClaimAPIData.claim.working30Hours === "X"){
      localStorage.setItem("localworking30Hours", true)
    } else {
      localStorage.setItem("localworking30Hours", false)
    }

    if(updateClaimData.updateClaimAPIData.claim.worked52Weeks === "X"){
      localStorage.setItem("localyearlyEarnings", true)
    } else {
      localStorage.setItem("localyearlyEarnings", false)
    }
    
    if(updateClaimData.updateClaimAPIData.claim.indexationApplies == "X"){      
      change("indexation", true)
      localStorage.setItem("localindexation", true)      
    } else {
      change("indexation", false)
      localStorage.setItem("localindexation", false)
    }

    if(updateClaimData.updateClaimAPIData.claim.eraOptOut === "X") change("optOutClaim", { value: 'X', label: 'Yes - Paid by ACC Directly' })
    else change("optOutClaim", { value: ' ', label: 'No - Payroll Pays' })

    if(updateClaimData.updateClaimAPIData.indexationExemptionDates?.length > 0){
      let index = 0;
      for(const i of updateClaimData.updateClaimAPIData.indexationExemptionDates){
        if(!i.fromDate || !i.toDate) continue;
        change(`indexationExemptionDates[${index}].fromDate`, formatDate(i.fromDate));
        change(`indexationExemptionDates[${index}].toDate`, formatDate(i.toDate));
        index++;
      }
    }
  }

  const setDiffDays = (e) => {
    e.target.id == 'dateOfInjury' ? setDofiDOSIDate(e.target.value) : setMedCertExpDate(e.target.value)
  }

  const setIndexationDates = (e) =>{
    //console.log( e.target.name,e.target.value)
  }
  
  const getDatesDiff = (date1, date2, isInclusive) => {
    let one_day = 1000*60*60*24; 
    let days;
    //when isInclusive, include both days in the difference. e.g. date1 = 08/10/2021, date 2 = 21/10/2021 then
    //inclusive date diff = 14
    //non-inclusive date diff = 13
    if(isInclusive === true) { days = Math.ceil( (date2.getTime() - date1.getTime() + one_day ) / one_day) }
    else { days = Math.ceil( (date2.getTime() - date1.getTime() ) / one_day) }
    return days;
  }

  const validateInputs = async (e)  => {
    e.preventDefault()
    //Carry out duplicate check only in creation mode
    if (!isUpdateToClaim){
      const isDuplicate = await duplicateClaimAPICheck(formData)
      if(isDuplicate){
        alert("A Duplicate Claim Already Exist for this time Period. Unable to continue")
        return
      }
    }
    handleSubmit()
  }
  

  const handlePSLTopupChange = (e) => {
    if(isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "pslTopUp")) return
    setHavePSLTopUpDays(e);
    localStorage.setItem("localpslTopUp", e)
    if(!e){
      change("datePSLBalance",null)
    }
  }

  const handleYearlyEarningsChange = (e) => {
    if(isUpdateToClaim) return
    localStorage.setItem("localyearlyEarnings", e);
    handleIndexation();
  }

  const handleWorkingWeeksChange = (e) => {
    if(isUpdateToClaim) return
    localStorage.setItem("localworking30Hours", e);
    handleIndexation();
  }

  const handleIndexation = () => {
    let workingYear = localStorage.getItem("localyearlyEarnings") === "true" ? true : false
    let workingWeek = localStorage.getItem("localworking30Hours") === "true" ? true : false
    
    if(empDetails.employmentType === EMPLOYMENT_TYPES.fullTimePermanent) {
        change("indexation", true)
        localStorage.setItem("localindexation", true)
      } 
      else if(workingYear && workingWeek){
        change("indexation", true)
        localStorage.setItem("localindexation", true)
      } 
      else {
        change("indexation", false)
        localStorage.setItem("localindexation", false)
      }
  }
  
  const getCalculationBasis = async (ACCDays, claimType) => {
    if(claimType == 'DOFI' && ACCDays<=35) change("calculationBasis","STE");
    else if(claimType == 'DOFI'  && ACCDays>35) change("calculationBasis","STE & LTE");
    else if(claimType == 'DOSI'){  
      let originalDOFIDate;
      let allClaimDetails;      

      if(formData.newDOSI == "yes") originalDOFIDate = new Date(formData.dofiDate.substr(0,4) + '-' + formData.dofiDate.substr(5,2) + '-' + formData.dofiDate.substr(8,2));
      else if(formData.newDOSI == "no"){        
        allClaimDetails = await loadAPIClaimData(formData.claimNumber, 'DOFI', formData.employeeNumber);
        if(allClaimDetails.length > 0){
          let allClaims = allClaimDetails.filter((claim) => {return claim.claimStatus!= 'Withdrawn'}).sort((a,b) => a.dateOfInjury - b.dateOfInjury)
          originalDOFIDate = new Date(allClaims[0].dateOfInjury.substr(0,4) + '-' + allClaims[0].dateOfInjury.substr(5,2) + '-' + allClaims[0].dateOfInjury.substr(8,2));
        }
      }

      if(originalDOFIDate && dofiDOSIDate){
        const Date_dofidosi =  new Date(dofiDOSIDate.substr(0,4) + '-' + dofiDOSIDate.substr(5,2) + '-' + dofiDOSIDate.substr(8,2));
        console.log(originalDOFIDate , Date_dofidosi)
        let diffDays = getDatesDiff(originalDOFIDate, Date_dofidosi, true)
        diffDays > 35 ? change("calculationBasis","LTE") : change("calculationBasis","STE & LTE") 
      }
    }
  }

  useEffect(()=>{
    if (isUpdateToClaim) setUpdateClaimValues()
    store.dispatch(setDataChanged(isCreateClaim ? true : (isUpdateToClaim && formData.reasonForUpdate && validReasonToCall.includes(formData.reasonForUpdate?.value) ? true : false)));
  }, [isUpdateToClaim, isCreateClaim])

  useEffect(()=>{
    if(isUpdateToClaim) return    
    setDofiDOSIDate(formData.dateOfInjury)
    setMedCertExpDate(formData.certificateValidTo)
    if(!formData.optOutClaim) change("optOutClaim", { value: ' ', label: 'No - Payroll Pays' })
  }, [])

  useEffect(()=>{
    if(isUpdateToClaim && (medCertExpDate)){
      console.log("CALLED",updateClaimData.updateClaimAPIData.claim.dateOfInjury,medCertExpDate || formData.certificateValidTo)
      const days = getDatesDiff(new Date(formatDate(updateClaimData.updateClaimAPIData.claim.dateOfInjury)), 
                                new Date(formatDate(medCertExpDate)),
                                true);
      change("days", days)
      getCalculationBasis(days, formData.claimType.value)
    }
    else if(dofiDOSIDate && medCertExpDate){
      let date1 = new Date(dofiDOSIDate);
      let date2 = new Date(medCertExpDate);
      const days = getDatesDiff(date1,date2, true);
      change("days",days)
      setACCDays(days)
    }
  }, [dofiDOSIDate,medCertExpDate]);

  useEffect(()=>{
    if(isUpdateToClaim) return
    getCalculationBasis(ACCDays, formData.claimType.value)
  }, [ACCDays, formData.newDOSI, formData.claimType.value])


  useEffect(() => {
    if(isUpdateToClaim) return
      if(empDetails.employmentType == EMPLOYMENT_TYPES.fullTimePermanent
      || empDetails.employmentType == EMPLOYMENT_TYPES.PartTimePermanent
      || empDetails.employmentType == EMPLOYMENT_TYPES.FullTimeFixedTerm
      || empDetails.employmentType == EMPLOYMENT_TYPES.PartTimeFT
      ) {
        change("indexation", true)
        localStorage.setItem("localindexation", true)
      } else {
        change("indexation", false)
        localStorage.setItem("localindexation", false)
      }

      return () => { };
    }, [empDetails])

  return (
  <form className="form form--horizontal wizard__form" onSubmit={validateInputs}>
    {/* <div>
        <Prompt
            when={dataChanged}
            message={location => `Changes made will be lost. Are you sure you want to go to ${location.pathname} ?`}
        />
    </div> */}
    <h3 className="wizard__title">Claim Data</h3>
    <div className="form__form-group">
      <span className="form__form-group-label">DOFI/DOSI Date</span>
      <div className="form__form-group-field">
        <Field
          id="dateOfInjury"
          name="dateOfInjury"
          component={validation.renderField}
          validate={[validation.required]}
          type="date"
          min={formData.dofiDate}
          max={new Date().toISOString().split("T")[0]}
          onChange={setDiffDays}
          disabled={isUpdateToClaim}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Medical Cert Expiry Date</span>
      <div className="form__form-group-field">
        <Field
          id="certificateValidTo"
          name="certificateValidTo"
          component={validation.renderField}
          validate={[validation.required]}
          type="date"
          min={dofiDOSIDate}
          onChange={setDiffDays}
          disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "certificateValidTo")}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Total Days on ACC</span>
      <div className="form__form-group-field">
        <Field
          id="days"
          name="days"
          component="input"
          type="text"
          disabled={true}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Calculation Basis</span>
      <div className="form__form-group-field">
        <Field
          name="calculationBasis"
          component="input"
          disabled={true}
          type="text"
          placeholder="Calculation Basis"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Medical Cert Status</span>
      <div className="form__form-group-field">
        <Field
          name="accStatus"
          component={renderSelectField}
          type="text"
          options={[
              { value: 'Fully Unfit', label: 'Fully Unfit' },
              { value: 'Light Duties', label: 'Light Duties' },
              { value: 'Return to Work', label: 'Return to Work' },
              { value: 'To be confirmed', label: 'ACC Status TBC' },
          ]}
          isDisabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "accStatus")}
          placeholder="Select"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">PSL Top Up</span>
      <div className="form__form-group-field">
        <Field
          name="pslTopUp"
          component={renderToggleButtonField}
          onChange={handlePSLTopupChange}
          defaultChecked={!localStorage.getItem("localpslTopUp") ? false : localStorage.getItem("localpslTopUp") === "false" ? false : true}
          disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "pslTopUp")}
        />
      </div>
      <p className="wizard__description">Is there a PSL top up?</p>
    </div>
    {/* <div hidden={!havePSLTopUpDays} className="form__form-group">
      <span className="form__form-group-label">PSL Balance</span>
      <div className="form__form-group-field">
       <Field
          name="pslOpeningBalance"
          component="input"
          type="numeric"
          disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "pslOpeningBalance")}
      />
      </div>
    </div> */}
    <div hidden={!havePSLTopUpDays} className="form__form-group">
      <span className="form__form-group-label">Effective date of PSL Top Up</span>
      <div className="form__form-group-field">
        <Field
           name="datePSLBalance"
           component="input"
           type="date"
           disabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "datePSLBalance")}
           min={updateClaimData?.updateClaimAPIData?.claim?.dateOfInjury}
           max={updateClaimData?.updateClaimAPIData?.claim?.certificateValidTo}
        />
      </div>
    </div>
    <div className="form__form-group" hidden={empDetails.employmentType === EMPLOYMENT_TYPES.fullTimePermanent}>
      <span className="form__form-group-label">Working Weeks</span>
      <div className="form__form-group-field">
        <Field
          name="working30Hours"
          component={renderToggleButtonField}
          onChange={handleWorkingWeeksChange}
          defaultChecked={localStorage.getItem("localworking30Hours")==="false"?false:true}
          disabled={isUpdateToClaim}
        />
      </div>
      <p className="wizard__description">Working more than or equal to 30 Hours Per Week?</p>
    </div>
    <div className="form__form-group" hidden={empDetails.employmentType === EMPLOYMENT_TYPES.fullTimePermanent}>
      <span className="form__form-group-label">Working Year</span>
      <div className="form__form-group-field">
        <Field
          name="yearlyEarnings"
          component={renderToggleButtonField}
          onChange={handleYearlyEarningsChange}
          defaultChecked={localStorage.getItem("localyearlyEarnings")==="false"?false:true}
          disabled={isUpdateToClaim}
        />
      </div>
      <p className="wizard__description">Would the employee have received earnings for next 12 months?</p>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Indexation</span>
      <div className="form__form-group-field">
        <Field
          name="indexation"
          component={renderToggleButtonField}
          disabled={true}
          defaultChecked={localStorage.getItem("localindexation")==="true"?true:false}
        />
      </div>
      <p className="wizard__description">{(localStorage.getItem("localindexation")==="true")? "Indexation applies to the weekly compensation rate":"Indexation does not apply to the weekly compensation rate"}</p>
    </div>
    { localStorage.getItem("localindexation")==="true" && 
      <div className="wizard__description">
        <FieldArray onChange={setIndexationDates} name="indexationExemptionDates" component={renderDateFieldArray} 
        isDisabled={isUpdateToClaim && !isValidEditableReason(reasonForUpdate, "indexationExemptionDates")}
        min={dofiDOSIDate}
        max={medCertExpDate}
        />
      </div> 
    }
    <div className="form__form-group">
      <span className="form__form-group-label">Is this an ERA Opt out claim?</span>
      <div className="form__form-group-field">
        <Field
          name="optOutClaim"
          component={renderSelectField}
          type="text"
          options={[
            { value: 'X', label: 'Yes - Paid by ACC Directly' },
            { value: ' ', label: 'No - Payroll Pays' },
          ]}
          placeholder="Claim Opt Out"
          isDisabled={isUpdateToClaim}
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button color="primary" type="button" className="previous" onClick={previousPage}>Back</Button>
      <Button color="primary" type="submit" className="next">Next</Button>
    </ButtonToolbar>
  </form>
 );
};

WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormTwo);
