import { ACC_INVOICE_MULTI_REQUEST, ACC_INVOICE_MULTI_SUCCESS, ACC_INVOICE_MULTI_FAILURE } from '../../utils/constant';

export const initialState = {
    isLoading: false,
    createInvoice: null,
    errorMessage: ''
}


export const creteInvoiceMultiReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACC_INVOICE_MULTI_REQUEST:
            return { ...state, isLoading: true }
        case ACC_INVOICE_MULTI_SUCCESS:
            return { ...state, isLoading: false, createInvoice: action.payload }
        case ACC_INVOICE_MULTI_FAILURE:
            return { ...state, isLoading: false, createInvoice: action.payload }
        default:
            return state;
    }

}