let counter = 0;

export const createData = (
  employerId,
  paymentDate,
  paymentStartDate,
  paymentEndDate,
  paymentTotal,
  firstName,
  lastName,
  irdNumber,
  claimNumber,
  reimburseStartDate,
  reimburseEndDate,
  numberOfDaysCovered,
  reimbursementAmount,
  dateOfInjury,
  eligibilityEndDate,
  eraStatus,
  workRelated,
  accId
  ) => {
  counter += 1;
  return {
    id:counter,
    employerId,
    paymentDate,
    paymentStartDate,
    paymentEndDate,
    paymentTotal,
    firstName,
    lastName,
    irdNumber,
    claimNumber,
    reimburseStartDate,
    reimburseEndDate,
    numberOfDaysCovered,
    reimbursementAmount,
    dateOfInjury,
    eligibilityEndDate,
    eraStatus,
    workRelated,
     accId
  };
};
