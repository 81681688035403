import store from '../../../../App/store'
import {UPDATE_REASON, CALCULATION_BASIS} from '../../../../../utils/constant'

export const required = value => (value || typeof value === 'number' ? undefined : '*')
export const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined
export const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined
export const maxLength15 = maxLength(15)
export const minLength2 = minLength(2)
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined
export const minValue13 = minValue(13)
export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined
export const alphaNumeric = value => value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined
export const phoneNumber = value => value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? 'Invalid phone number, must be 10 digits' : undefined

export const customValidation = (value, allValues, props, name) => {    
    switch (name) {        
        case 'newDOSI': return ((allValues.claimType && allValues.claimType.value === 'DOSI') && !value) ? 'required' : undefined
        case 'dofiDate': return ((allValues.newDOSI && allValues.newDOSI === 'yes') && !value) ? 'required' : undefined
        case 'reasonNotes': {
            if((allValues.reasonForUpdate && allValues.reasonForUpdate.value === 'othersWithNotes') && !value) return 'required'
            else if((allValues.reasonForWithdraw && allValues.reasonForWithdraw.value === 'othersWithNotes') && !value) return 'required'
            else return undefined
        }
        case 'dateChangeRuleGrp': return ((allValues.reasonForUpdate && allValues.reasonForUpdate.value === 'changeRuleGrouping') && !value) ? 'required' : undefined
        case 'datePSLBalance': return ((allValues.reasonForUpdate && allValues.reasonForUpdate.value === UPDATE_REASON.ChangePSLTopUp && allValues.pslTopUp) && !value) ? 'required' : undefined
        case 'annualSalary': {
            const empDetails =  store.getState().employeeDetails.employee  
            return ((empDetails.paymentType === 'S') && !value) ? 'required' : undefined
        }
        case 'hourlyRate': {
            const empDetails =  store.getState().employeeDetails.employee
            return ((empDetails.paymentType === 'W') && !value) ? 'required' : undefined
        }
        case 'annualizedHours': {
            const empDetails =  store.getState().employeeDetails.employee
            return ((empDetails.paymentType === 'W') && !value) ? 'required' : undefined
        }
        case 'reasonForUpdate': {
            const claimMode = store.getState().claim?.mode
            if(claimMode === "UPDATE" && allValues.paymentVariance) return undefined
            if(claimMode === "UPDATE" && !value) return 'required' 
            return (claimMode === "WITHDRAW" && !value ) ? 'required' : undefined
        }
        case 'calculationStatus':{
            const claimMode = store.getState().claim?.mode || store.getState().closeClaim?.closeClaimMode
            return (claimMode === "CLOSE" && !value) ? 'required' : undefined
        }
        case 'dateOfInjury': {
            return allValues.certificateValidTo && value <= allValues.certificateValidTo ? undefined : "DOFI/DOSI Date must be before Medical Cert Expiry Date"
        }
        case 'certificateValidTo':{
            return allValues.dateOfInjury && value >= allValues.dateOfInjury ? undefined : "Medical Cert Expiry Date must be after DOFI/DOSI Date"
        }
        case 'employmentWeeks':{
            const claimMode = store.getState().claim?.mode || store.getState().closeClaim?.closeClaimMode
            return (claimMode === "CREATE" && !value)? 'required': undefined;
        }
        case 'earning4weeks':{
            const calculationBasis = allValues.calculationBasis
            return (calculationBasis === CALCULATION_BASIS.STE || calculationBasis === CALCULATION_BASIS.BOTH) && !value ? 'required': undefined;
        }
        case 'earning52weeks':{
            const calculationBasis = allValues.calculationBasis
            return (calculationBasis === CALCULATION_BASIS.LTE || calculationBasis === CALCULATION_BASIS.BOTH) && !value ? 'required': undefined;
        }
        default: return undefined
    }
}

export const getErrorStyle = (error, touched=false) => {
    if(touched && error) return {borderBottom: '1px solid red'}
    return {}
}

export const renderField = ({ type, input, id, placeholder, disabled, max, min, meta: { touched, error }} ) => (
        <input {...input} type={type} id={id} placeholder={placeholder} max={max} min={min} disabled={disabled} style={getErrorStyle(error, touched)} />
)

export const renderTextArea = ({ type, input, id, placeholder, disabled, max, min, meta: { touched, error }} ) => (
    <textarea {...input} type={type} id={id} placeholder={placeholder} max={max} min={min} disabled={disabled} style={getErrorStyle(error, touched)} />
)