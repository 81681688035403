export const setClaimDataAction = data => {
    return {
        type: 'ADD_API_CLAIM_DATA',
        payload: data,
    };
};

export const setClaimModeAction = mode => {
    return {
        type: 'SET_CLAIM_MODE',
        payload: mode,
    }
}

export const setUpdateClaimFormAction = data => {
    return {
        type: 'UPDATE_FORM_CLAIM_DATA',
        payload: data,
    };
};

export const setUpdateAPIFormAction = data => {
    return {
        type: 'UPDATE_API_CLAIM_DATA',
        payload: data,
    };
};

export const setWithdrawClaimModeAction = mode => {
    return {
        type: 'SET_WITHDRAW_CLAIM_MODE',
        payload: mode
    }
}

export const setWithdrawClaimDataAction = data => {
    return {
        type: 'SET_WITHDRAW_CLAIM_DATA',
        payload: data
    }
}

export const createClaimSnapshot = data => {
    return {
        type: 'CREATE_CLAIM_SNAPSHOT',
        payload: data,
    };
};

export const clearClaimData = data => {
    return {
        type: 'CLEAR_CLAIM_DATA',
        payload: data,
    };
};

export const setCalcPayments = data => {
    let payments = []
    payments = JSON.parse(JSON.stringify(data));
    payments = payments.filter(line => line.startOfWeek)

    return {
        type: 'SET_CLAIM_CALC_PAYMENTS',
        payload: payments,
    };
};

export const setCalcTotals = data => {
    let payments = []
    payments = JSON.parse(JSON.stringify(data));
    payments = payments.filter(line => !line.startOfWeek)

    return {
        type: 'SET_CLAIM_CALC_TOTALS',
        payload: payments,
    };
}

export const setReCalcPayments = data => {
    let payments = []
    payments = JSON.parse(JSON.stringify(data));
    payments = payments.filter(line => line.startOfWeek)

    return {
        type: 'SET_CLAIM_RE_CALC_PAYMENTS',
        payload: payments,
    };
};

export const setReCalcTotals = data => {
    let payments = []
    payments = JSON.parse(JSON.stringify(data));
    payments = payments.filter(line => !line.startOfWeek)

    return {
        type: 'SET_CLAIM_RE_CALC_TOTALS',
        payload: payments,
    };
}

export const setTotalSnapshot = data => {
    return {
        type: 'SET_TOTAL_SNAPSHOT',
        payload: data,
    };
};

export const setNewClaimPayments = data => {
    let payments = []
    payments = JSON.parse(JSON.stringify(data));
    payments = payments.filter(line => line.startOfWeek)

    return {
        type: 'SET_NEW_CLAIM_PAYMENTS',
        payload: payments,
    };
}

export const setNewClaimTotals = data => {
    let totals = []
    totals = JSON.parse(JSON.stringify(data));
    totals = totals.filter(line => !line.startOfWeek)

    return {
        type: 'SET_NEW_CLAIM_TOTALS',
        payload: totals,
    };
}

export const setDataChanged = data => {
    return {
        type: 'DATA_CHANGED',
        payload: data
    }
}

export const setApiCalled = data => {
    return {
        type: 'SAVE_API_CALLED',
        payload: data
    }
}