import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { Field, reduxForm } from 'redux-form';
import renderDateField from '../../../../shared/components/form/DatePicker';
import moment from 'moment';
import { factorsList } from './FactorsList';
import renderSelectField from '../../../../shared/components/form/Select';

let MaintainFactors = (props) => {
  const { handleClose, open, title, edit, initialize } = props;
  const formFields = {
    factor:'',
    validFrom:'',
    validTo:'',
    value:''
  };
  const [data, setData] = useState(edit?edit:formFields);
  const format = "DD/MM/YYYY";
  useEffect(() => {
    let values = JSON.parse(JSON.stringify(edit));
    if(edit){
      values.factor = {value:edit.factor,label:edit.factor};
      edit.validFrom = moment(edit.validFrom).format(format);
      edit.validTo = moment(edit.validTo).format(format);
    }    
    initialize(edit?values:formFields);
    setData(edit?edit:formFields);
  },[edit]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box component="form" sx={{height:150,'& > :not(style)': { m: 1 },}} noValidate autoComplete="off">
              <form className="form form--horizontal wizard__form">
                <div className="form__form-group">
                  <span className="form__form-group-label">Name</span>
                  <div className="form__form-group-field">
                    <div className="container">
                      <Field
                          name="factor"
                          id="factor"
                          placeholder="Name"
                          component={renderSelectField}
                          options={factorsList()}
                          type="text"
                          onChange={(e)=>setData(prevState => ({
                            ...prevState,
                            factor: e.value,
                        }))}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Value</span>
                  <div className="form__form-group-field">
                    <div className="container">
                      <Field
                          name="value"
                          id="value"
                          placeholder="Value"
                          component="input"
                          value={data.value}
                          onChange={(e)=>setData(prevState => ({
                            ...prevState,
                            value: e.target.value,
                        }))}
                      />
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Date</span>
                  <div className="form__form-group-field">
                    <div className="container">
                      <Field
                      id="validFrom"
                      name="validFrom"
                      placeholder="Valid From"
                      component={renderDateField}
                      onChange={(e)=>setData(prevState => ({
                        ...prevState,
                        validFrom: moment(e).format(format),
                    }))}
                    />
                    </div>
                    To
                    <div className="container">
                    <Field
                      id="validTo"
                      name="validTo"
                      placeholder="Valid To"
                      component={renderDateField}
                      onChange={(e)=>setData(prevState => ({
                        ...prevState,
                        validTo: moment(e).format(format),
                    }))}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{paddingRight: "40px"}}>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button onClick={() => handleClose(true,data)} autoFocus>{(edit)?"Update":"Add"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default reduxForm({
  form: 'maintain_factors', // a unique identifier for this form
  enableReinitialize : true
})(MaintainFactors);
