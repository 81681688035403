import { GET_INVOICE_LIST_REQUEST, GET_INVOICE_LIST_SUCCESS, GET_INVOICE_LIST_FAILURE } from '../../utils/constant';

export const initialState = {
    isLoading: false,
    data: null,
    errorMessage: ''
}


export const getInvoiceListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVOICE_LIST_REQUEST:
            return { ...state, isLoading: true }
        case GET_INVOICE_LIST_SUCCESS:
            return { ...state, isLoading: false, data: action.payload }
        case GET_INVOICE_LIST_FAILURE:
            return { ...state, isLoading: false, data: action.payload }
        default:
            return state;
    }

}