import {SET_CLOSE_CLAIM_MODE,SET_CLOSE_CLAIM_DATA,CLOSE_REQUEST_CLAIM_REQUEST,CLOSE_REQUEST_CLAIM_SUCCESS,CLOSE_REQUEST_CLAIM_FAILURE,RESET_CLOSE_CLAIM_DATA} from '../../utils/constant'

const initialState = {
    closeClaimMode:null,
    closeClaimData:{},
    closeClaimApiData:{}
};

const closeClaimReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLOSE_CLAIM_MODE: return { ...state, closeClaimMode: action.payload };
        case SET_CLOSE_CLAIM_DATA: return { ...state, closeClaimData: action.payload };
        case CLOSE_REQUEST_CLAIM_REQUEST: return { ...state};
        case CLOSE_REQUEST_CLAIM_SUCCESS: return { ...state,closeClaimApiData:action.payload};
        case CLOSE_REQUEST_CLAIM_FAILURE: return { ...state,closeClaimApiData:action.payload};
        case RESET_CLOSE_CLAIM_DATA: return initialState
        default: return state;
    }
};

export default closeClaimReducer;
