import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import moment from 'moment';

const DatePickerField = ({ onChange, placeholder, value, disabled }) => {
  const [startDate, setStartDate] = useState(value);
  const handleChange = (date) => {
    const dt = date ? moment(date).toDate() : null;
    setStartDate(dt);
    onChange(dt);
  };
  return (
    <div className="date-picker">
      <DatePicker
        className="form__form-group-datepicker"
        selected={(value) ? moment(value).toDate(): ''}
        onChange={handleChange}
        dateFormat="dd/MM/yyyy"
        dropDownMode="select"
        popperPlacement="center"
        withPortal={isMobileOnly}
        placeholderText={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

DatePickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder:  PropTypes.string
};

const renderDatePickerField = ({ input, placeholder, disabled }) => <DatePickerField {...input}  placeholder={placeholder} disabled={disabled} />;

renderDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
};

export default renderDatePickerField;
