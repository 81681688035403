import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarHref from './SidebarHref';
import { sideBarUIGuard } from '../../../shared/components/auth/UIGuard'
import { APP_VERSION } from '../../../index'
    

const SidebarContent = ({ onClick, changeToLight, changeToDark, sidebarCollapse }) => {
  const userRoles = localStorage.getItem('ACCROLES') ? localStorage.getItem('ACCROLES') : []
  const versionStyle = {
    position:'absolute', 
    background:'red',
    bottom:0,
    left:'20px', 
    padding:'0px 10px',
    color: 'white',
    fontWeight: 'bold'
  }
  const hideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {
          Boolean(sideBarUIGuard.dashboard.filter(role => userRoles.includes(role)).length !== 0) && 
          <SidebarLink
            title="Dashboard"
            icon="home"
            route="/tables/data_table"
            onClick={hideSidebar}
          />
        }
        {
          Boolean(sideBarUIGuard.createClaim.filter(role => userRoles.includes(role)).length !== 0) && 
          <SidebarLink
            title="Create Claim"
            icon="file-add"
            route="/forms/redux_form/wizard_form"
            onClick={hideSidebar}
          />
        }
        {
          Boolean(sideBarUIGuard.maintainFactor.filter(role => userRoles.includes(role)).length !== 0) && 
          <SidebarLink
            title="Maintain Factors"
            icon="file-empty"
            route="/tables/material_table"
            onClick={hideSidebar}
          />
        }
        {
          Boolean(sideBarUIGuard.ACCInvoives.filter(role => userRoles.includes(role)).length !== 0) && 
          <SidebarLink
            title="ACC Invoices"
            icon="layers"
            route="/forms/redux_form/file_upload"
            onClick={hideSidebar}
          />
        }
        {
          Boolean(sideBarUIGuard.reports.filter(role => userRoles.includes(role)).length !== 0) && 
          <SidebarLink
            title="Reports"
            icon="chart-bars"
            route="/tables/reports_download"
            onClick={hideSidebar}
          />
        }
         {
          Boolean(sideBarUIGuard.reCalculation.filter(role => userRoles.includes(role)).length !== 0) && 
          <SidebarLink
            title="Re Calculation"
            icon="chart-bars"
            route="/tables/recalculation"
            onClick={hideSidebar}
          />
        }
        {
          Boolean(sideBarUIGuard.dataDownload.filter(role => userRoles.includes(role)).length !== 0) && 
          <SidebarLink
            title="Data Download"
            icon="cloud-download"
            route="/tables/table_data_download"
            onClick={hideSidebar}
          />
        }
      </ul>
      <ul className="sidebar__block">
        {
          Boolean(sideBarUIGuard.documentation.filter(role => userRoles.includes(role)).length !== 0) && 
          <SidebarHref
            title="Documentation"
            icon="text-align-justify"
            route="https://documents.fonterra.com/otcs/cs.exe/link/75127735"
            onClick={hideSidebar}
          />
        }
      </ul>
      {/* <div style={versionStyle}>{APP_VERSION}</div> */}
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
