import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@mui/material/Radio";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from '@mui/material/IconButton';

import MatTableHead from "./MatTableHead";
import MatTableToolbar from "./MatTableToolbar";
import { createData } from "../../../../utils/matTableData";
import { InputFields } from "../../../Layout/components/InputFields";
import { Buttons } from "../../../Layout/components/buttons";
import ProgressBar from "../../../../shared/components/ProgressBar";
import moment from "moment";
import DoubleScrollbar from 'react-double-scrollbar';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../shared/components/form/Select';
import { factorsList } from './FactorsList';

const getSorting = (order, orderBy) => {
  if (order === "desc") {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};

const MatTable = (props) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("employerId");
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const format = "DD-MM-YYYY";

  const {
    headerCellData,
    title,
    pageName,
    onClicks,
    invoiceData,
    onChangeSearch,
    onButtonClick,
    toggleChange,
    isLoading,
    toggleValue,
    invoiceHandleChange,
    checkedInvoiceRadio,
    clickTovalidat,
    discprenciesData,
    validateInvoiceLoading,
    mfdata,
    handleDelete,
    handleEdit,
    handleAdd
  } = props;

  useEffect(() => {
    setTableData();
  }, [invoiceData, mfdata]);

  const setTableData = () => {
    if (pageName == "maintainfactors") {
      setData(mfdata);
    } else {
      const invoiceUpdateData = [];
      if (invoiceData && invoiceData !== null) {
        invoiceData.map((data) => {
          const {
            claimNumber,
            createdBy,
            createdOn,
            dateOfInjury,
            eligibilityEndDate,
            employerId,
            eraStatus,
            firstName,
            id,
            irdNumber,
            lastName,
            numberOfDaysCovered,
            paymentDate,
            paymentEndDate,
            paymentStartDate,
            paymentTotal,
            reimburseEndDate,
            reimburseStartDate,
            reimbursementAmount,
            workRelated,
          } = data;

          const dataValue = createData(
            employerId,
            paymentDate,
            paymentStartDate,
            paymentEndDate,
            paymentTotal,
            firstName,
            lastName,
            irdNumber,
            claimNumber,
            reimburseStartDate,
            reimburseEndDate,
            numberOfDaysCovered,
            reimbursementAmount,
            dateOfInjury,
            eligibilityEndDate,
            eraStatus,
            workRelated,
            id
          );

          invoiceUpdateData.push(dataValue);
        });
        setData(invoiceUpdateData);
      }
    }
  };

  const handleRequestSort = (event, property) => {
    const orderByTemp = property;
    let orderTemp = "desc";
    if (orderBy === property && order === "desc") {
      orderTemp = "asc";
    }
    setOrder(orderTemp);
    setOrderBy(orderByTemp);
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      const newSelected = new Map();
      data.map((n) => newSelected.set(n.id, true));
      setSelected(newSelected);
      return;
    }
    setSelected(new Map([]));
  };

  const handleClick = (event, id) => {
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    setSelected(newSelected);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
  };

  const handleDeleteSelected = () => {
    let copyData = [...data];
    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }
    setData(copyData);
    setSelected(new Map([]));
  };

  const isSelected = (id) => !!selected.get(id);
  const data_length = data && data.length;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data_length - page * rowsPerPage);
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardHeader>
          <div className="card__title">
            <h5 className="bold-text">{title}</h5>
          </div>
        </CardHeader>
        <CardBody>
          {pageName == "ACC Invoices" ? (
            <>
              {" "}
              <Row>
                <Col md={{ size: "3" }} lg={3} sm={12}>
                  <InputFields
                    type={"text"}
                    name={"Claim Number"}
                    name="Claim Number"
                    placeholder={"Claim Number"}
                    onChangeInput={onChangeSearch}
                  />
                </Col>
                <Col md={2} lg={2} sm={12}>
                  <InputFields
                    type={"text"}
                    name={"First Name"}
                    name="First Name"
                    placeholder={"First Name"}
                    onChangeInput={onChangeSearch}
                  />
                </Col>
                <Col md={2} lg={2} sm={12}>
                  <InputFields
                    type={"text"}
                    name={"Last Name"}
                    name="Last Name"
                    placeholder={"Last Name"}
                    onChangeInput={onChangeSearch}
                  />
                </Col>
                <Col md={5} lg={5} className="buttonCls" sm={12}>
                  <Buttons
                    color={"primary"}
                    title={"Search"}
                    onClick={onButtonClick}
                  />
                  <Buttons
                    color={"primary"}
                    title={"Upload"}
                    onClick={onClicks}
                  />
                  <Buttons
                    color={"primary"}
                    title={"Revalidate"}
                    onClick={clickTovalidat}
                    loading={validateInvoiceLoading}
                  />
                </Col>
              </Row>
              <Row>
                <div className="custom-control custom-switch" id="switchTab">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customSwitch1"
                    value={toggleValue}
                    onChange={toggleChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitch1"
                  >
                    Discrepancy
                  </label>
                </div>
              </Row>
            </>
          ) : (
            <Row>
              <Col md={3} lg={3} sm={12}>
                <Field
                    name="factor"
                    component={renderSelectField}
                    type="text"
                    options={factorsList()}
                    placeholder="Factor"
                    onChange={(e) => onChangeSearch(e.value)}
                  />
              </Col>
              <Col md={5} lg={5} sm={12}>
                <Buttons
                  className="buttonCls"
                  color={"primary"}
                  title={"Search"}
                  onClick={onButtonClick}
                />
                <Buttons color={"primary"} title={"Add"} onClick={handleAdd} />
              </Col>
            </Row>
          )}

         {pageName == "ACC Invoices" && <MatTableToolbar
            numSelected={[...selected].filter((el) => el[1]).length}
            handleDeleteSelected={handleDeleteSelected}
            onRequestSort={handleRequestSort}
          />}
          <div className="material-table__wrap">
            {isLoading ?
              <Row>
                <Col sm={{ size: 6, offset: 6 }} md={{ size: 6, offset: 6 }}>
                  <ProgressBar page={'meta'}/>
                </Col>
              </Row> :
              <DoubleScrollbar>
              <Table className="material-table">
                <MatTableHead
                  numSelected={[...selected].filter((el) => el[1]).length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data && data.length}
                  headerData={headerCellData}
                  pageName={pageName}
                />
                <TableBody>
                  {data_length !== 0 &&
                    data
                      .sort(getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((d, index) => {
                        const select = isSelected(d.id);
                        return pageName == "ACC Invoices" ? (
                          <TableRow
                            className="material-table__row"
                            role="checkbox"
                            onClick={(event) => handleClick(event, d.id)}
                            aria-checked={select}
                            tabIndex={-1}
                            key={d.id}
                            selected={select}
                          >
                            <TableCell
                              className="material-table__cell"
                              padding="checkbox"
                            >
                              {/* <Checkbox checked={select} className="material-table__checkbox" /> */}
                              <Radio
                                checked={checkedInvoiceRadio == d.accId}
                                onChange={invoiceHandleChange}
                                value={d.accId}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "invoices" }}
                              />
                            </TableCell>
                            <TableCell
                              className="material-table__cell material-table__cell-right"
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              {d.employerId}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {moment(d.paymentDate).format(format)}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {moment(d.paymentStartDate).format(format)}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {moment(d.paymentEndDate).format(format)}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.paymentTotal}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.firstName}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right ">
                              {d.lastName}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.irdNumber}
                            </TableCell>
                            <TableCell
                              className={
                                "material-table__cell material-table__cell-right"
                              }
                            >
                              {d.claimNumber}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {moment(d.reimburseStartDate).format(format)}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {moment(d.reimburseEndDate).format(format)}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.numberOfDaysCovered}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.reimbursementAmount}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {moment(d.dateOfInjury).format(format)}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {moment(d.eligibilityEndDate).format(format)}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.eraStatus}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.workRelated}
                            </TableCell>
                          </TableRow>
                        )  : (
                          <TableRow>
                            <TableCell
                              className="material-table__cell"
                              padding="none"
                            >
                              <IconButton tooltip="Edit">
                                <EditIcon  onClick={() => handleEdit(d)} />
                              </IconButton>
                              <IconButton tooltip="Delete">
                                <DeleteIcon onClick={() => handleDelete(d.id)} />
                              </IconButton>
                            </TableCell>
                            <TableCell
                              className="material-table__cell material-table__cell-right"
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              {d.factor}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {moment(d.validFrom).format(format)}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {moment(d.validTo).format(format)}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.value}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </DoubleScrollbar>}
          </div>
          {isLoading ? null : (
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={data && data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ "aria-label": "Previous Page" }}
              nextIconButtonProps={{ "aria-label": "Next Page" }}
              //onChangePage={handleChangePage} deprecated prop
              onPageChange={handleChangePage} 
              //onChangeRowsPerPage={handleChangeRowsPerPage} deprecated prop
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[20, 50, 100]}
              dir="ltr"
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
export default reduxForm({
  form: 'mattable_form', // a unique identifier for this form
})(MatTable);
