import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import { formatdecimal2place, formatdecimal3place } from '../../../../../utils/formatter'

//Step 1 - API call to get employee search results
export const loadEmployeeData = async (setEmpData, setSuggestions, text) => {
    // eslint-disable-next-line no-template-curly-in-string
    const employeeData = await axios.get(process.env.REACT_APP_API_BASE_URL + `/employees?query=${text}`)
                                    .then(function (response) {
                                        if(response.data.count > 0){
                                            setEmpData(response.data.employees)
                                            setSuggestions(response.data.employees)
                                        }
                                        return response.data;
                                    })
                                    .catch(function (response){
                                        alert("Unable to get Employees from e-HR");
                                        console.log("Unable to get Employees from e-HR" + response.message)
                                        return;    
                                    });
   
}

//Step 1 - API call to get previous claims
export const loadAPIPreviousClaimRecords = async (data) => {
    const claimDetails = await axios.get(   process.env.REACT_APP_API_BASE_URL + `/claim?`, 
                                        {   params: { employeeNumber: data.employeeNumber, calculationStatus: ['Active', 'PendingClosure', 'Closed']},
                                            paramsSerializer: params => { return qs.stringify(params, {arrayFormat: 'repeat'})} })
                                    .then(function (response){
                                        if (response.data.length)
                                            return response.data
                                            .sort((b, a) => (a.createdOn > b.createdOn) ? 1 : ((b.createdOn > a.createdOn) ? -1 : 0))
                                            .splice(0, 10)
                                    })
                                    .catch(function (response){
                                        alert("Unable to get previous claims for employee");
                                        console.log("Unable to get previous claims for employee, details:" + response.message)
                                        return [{ claimNumber: "not-found" }];
                                    });
    // if (claimDetails.status == 200 && claimDetails.statusText == 'OK' && claimDetails.data.length)
    //     return claimDetails.data
    //         .sort((b, a) => (a.createdOn > b.createdOn) ? 1 : ((b.createdOn > a.createdOn) ? -1 : 0))
    //         .splice(0, 10)
    // return [{ claimNumber: "not-found" }];
    return claimDetails;
}

//Step 1 - Save record for over/under payment and any notes
export const saveQuickEdit = async (id, claimNumber, paymentVariance, notes, calculationStatus, varianceAmount) => {
    return await axios.post(process.env.REACT_APP_API_BASE_URL + `/claim/${id}/quickedit`,{
                            claim: {
                                id,
                                claimNumber,
                                paymentVariance,
                                notes,
                                calculationStatus,
                                varianceAmount
                            }
                        }).catch(function(response){
                            alert("Unable to save data");
                            console.log("Unable to data with quickedit API, details:" + response.message)
                        });
}

//Step 2 - Gets all the DOFI claims for employee
export const loadAPIClaimData = async (claimNumber = "~~abcba~~", claimType, employeeNumber) => {
    const dofiClaims = await axios.get(process.env.REACT_APP_API_BASE_URL + `/claim?`, { params: { claimNumber, claimType, employeeNumber } })
                                        .then(function (response){
                                            return response.data 
                                        }).catch(function (response){
                                            alert(`Unable to get DOFI claims for claim number ${claimNumber}`);
                                            console.log(`Unable to get previous claims for claim number  ${claimNumber}, details:` + response.message);
                                            return [];
                                        });

    //if (dofiClaims.status == 200 && dofiClaims.statusText == 'OK')
    return dofiClaims
    //return [];
}

//Step 2 - Validates backend for duplicate claims number for an employee
export const duplicateClaimAPICheck = async (formData) => {
    const claimNumber = formData.claimNumber;
    const claimType = formData.claimType.value;
    const dateOfInjury = moment(formData.dateOfInjury, 'YYYY-MM-DD').format("DD/MM/YYYY")
    const certificateValidTo = moment(formData.certificateValidTo, 'YYYY-MM-DD').format("DD/MM/YYYY")
    const continuedClaim = formData.continueClaim ? "X" : " ";
    const isDuplicate = await axios.post(process.env.REACT_APP_API_BASE_URL + `/claim/duplicatecheck`,  { claimNumber, claimType, dateOfInjury, certificateValidTo, continuedClaim })
                                    .then(function(response){
                                        return response.data.isDuplicate;
                                    }).catch(function (response){
                                        alert(`Unable to check duplicate claims for claim number ${claimNumber}`);
                                        console.log(`Unable to check duplicate claims for claim number ${claimNumber}, details:` + response.message);
                                        return true;
                                    });
    //if (isDuplicate.status == 200 && isDuplicate.statusText == 'OK') return isDuplicate.data.isDuplicate
    return isDuplicate;
}

//Step 3 - Gets STE Weeks Factor
export const getSTEPeriodFactorAPI = async (formData, isUpdateToClaim, change, setSTEPeriodFactor) => {
    if (formData.divisor4weeks || isUpdateToClaim) return
    const today = new Date()
    const factor = "steWeeks";
    const keyDate = formData.dateOfInjury;
    const factorData = await axios.get(process.env.REACT_APP_API_BASE_URL + `/factors?`, { params: { factor, keyDate } })
                                    .then(function(response){
                                        if(response.data.length > 0) {
                                            const factor = formatdecimal3place.format(response.data[0].value);
                                            setSTEPeriodFactor(factor);
                                            return factor;
                                        }else{
                                            alert("Unable to get factor to determine STE Period");
                                            console.log(`Unable to get factor ${factor} to determine STE Period, details:` + response.message);    
                                        };
                                    }).catch(function(response){
                                        alert("Unable to get factor to determine STE Period");
                                        console.log(`Unable to get factor ${factor} to determine STE Period, details:` + response.message);
                                    });
    // if (factorData.status == 200 && factorData.statusText == 'OK') setSTEPeriodFactor(formatdecimal3place.format(factorData.data[0].value))
    // else change("divisor4weeks", "Error connecting eHR API")
}

//Step 3 - Get Divisor Fortnightly Hours
export const getdivisorFortnightlyHoursFactorAPI = async (formData, divisorFortnightlyHours, setDivisorFortnightlyHours) => {
    if (divisorFortnightlyHours) return
    const factor = "divisorFortnightlyHours";
    const keyDate = formData.dateOfInjury;
    const factorData = await axios.get(process.env.REACT_APP_API_BASE_URL + `/factors?`, { params: { factor, keyDate } })
                                    .then(function(response){
                                        if(response.data){
                                            const factor = formatdecimal3place.format(response.data[0].value);
                                            setDivisorFortnightlyHours(factor);
                                            localStorage.setItem("localDivisorFortnightlyHours", factor);
                                            return factor;
                                        }
                                        return;
                                    })
                                    .catch(function(response){
                                        alert("Error fetching factor divisorFortnightlyHours, defaulting Divisor Fortnightly Hours to 26")
                                        setDivisorFortnightlyHours(26);
                                        localStorage.setItem("localDivisorFortnightlyHours", 26);
                                        return;
                                    });
    // if (factorData.status == 200 && factorData.statusText == 'OK' && factorData.data) {
    //     setDivisorFortnightlyHours(formatdecimal3place.format(factorData.data[0].value))
    //     localStorage.setItem("localDivisorFortnightlyHours", factorData.data[0].value)
    // }
    // else {
    //     alert("Error fetching factor divisorFortnightlyHours, defaulting Divisor Fortnightly Hours to 26")
    //     setDivisorFortnightlyHours(26)
    //     localStorage.setItem("localDivisorFortnightlyHours", 26)
    // }
}