import React, { useState } from "react";
import { Field, reduxForm } from 'redux-form';
import renderDateField from '../../../../shared/components/form/DatePicker';
// import renderSelectField from '../../../../shared/components/form/Select';
import Box from '@mui/material/Box';
import { Button } from 'reactstrap';
import ProgressBar  from '../../../../shared/components/ProgressBar';
import moment from 'moment';

const Reclaim = ({title, handleSubmit, loading, pristine, submitting, reset}) => {
  const [startDate, setStartDate] = useState();
  const [employeeNumber, setEno] = useState();
  
  const handleChange = (e,setIdentifierState) => {
    const { name, value} = e.target;
    setIdentifierState(value)
  };
  
  const resetForm = () =>{
    setStartDate();
    setEno();
    reset();
  }

  return (
    <>
      <Box component="form" sx={{  width: 500 ,'& > :not(style)': { m: 1 },}} noValidate autoComplete="off">
        <form className="form form--horizontal wizard__form">
          <h3 className="wizard__title">{ title }</h3>
          <div className="form__form-group">
            <span className="form__form-group-label">Start Date</span>
            <div className="form__form-group-field">
              <div className="container">
                <Field
                id="startDate"
                name="dt"
                placeholder="Start Date"
                value={startDate}
                component={renderDateField}
                onChange={(val)=>setStartDate(val)}
                disabled={employeeNumber && employeeNumber.length}
              />
              </div>
            </div>
          </div>
          <div className="form__form-group">
              <span className="form__form-group-label">Employee Number</span>
              <div className="form__form-group-field">
                <div className="container">
                  <Field
                      name="empNumber"
                      id="employeeNumber"
                      placeholder="Employee Number"
                      type="text"
                      component="input"
                      value={employeeNumber}
                      onChange={(e)=>handleChange(e,setEno)}
                      disabled={startDate && startDate !== undefined}
                  />
                </div>
              </div>
          </div>  
        </form>
      </Box>
      <Box sx={{'& > :not(style)': { m: 1 },}} noValidate autoComplete="off">  
        <Button type="submit" disabled={pristine || submitting}  color="primary" onClick={handleSubmit}> {loading?<ProgressBar size={"8"} />:'Submit'} </Button>
        <Button disabled={pristine || submitting}  color="primary" onClick={resetForm}> {'Reset'} </Button>
      </Box>
    </>
  );
};

export default reduxForm({
    form: 'reclaim_form', // a unique identifier for this form
    enableReinitialize:true
  })(Reclaim);