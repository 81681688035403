const initialState = {
    mode: null,
    withdrawClaimMode: null,
    closeClaimMode: null,
    claimData: {},
    updateClaimFormData: {},
    updateClaimAPIData: {},
    withdrawClaimData: {},
    closeClaimData: {},
    claimFormSnapshot: {},
    totals: [], //we will require a copy of totals for calculations when payments are edited
    dataChanged: false,
    apiCalled: false,
};

const claimReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CLAIM_MODE': return { ...state, mode: action.payload }
        case 'ADD_API_CLAIM_DATA': return { ...state, claimData: action.payload };
        case 'UPDATE_FORM_CLAIM_DATA': return { ...state, updateClaimFormData: action.payload };
        case 'UPDATE_API_CLAIM_DATA': return { ...state, updateClaimAPIData: action.payload };
        case 'SET_WITHDRAW_CLAIM_MODE': return { ...state, withdrawClaimMode: action.payload };
        case 'SET_WITHDRAW_CLAIM_DATA': return { ...state, withdrawClaimData: action.payload };
        case 'SET_CLOSE_CLAIM_MODE': return { ...state, closeClaimMode: action.payload };
        case 'SET_CLOSE_CLAIM_DATA': return { ...state, closeClaimData: action.payload };
        case 'CREATE_CLAIM_SNAPSHOT': return { ...state, claimFormSnapshot: action.payload }
        case 'CLEAR_CLAIM_DATA': return initialState
        case 'SET_CLAIM_CALC_PAYMENTS': return {
            ...state, updateClaimAPIData: {
                ...state.updateClaimAPIData, payments: action.payload
            }
        }
        case 'SET_CLAIM_CALC_TOTALS':  return {
            ...state, updateClaimAPIData: {
                ...state.updateClaimAPIData, payperiodtotals: action.payload
            }
        }
        case 'SET_CLAIM_RE_CALC_PAYMENTS': return {
            ...state, claimData: {
                ...state.claimData, payments: action.payload
            }
        }
        case 'SET_CLAIM_RE_CALC_TOTALS':  return {
            ...state, claimData: {
                ...state.claimData, totals: action.payload
            }
        }
        case 'SET_NEW_CLAIM_PAYMENTS': return {
            ...state, claimData: {
                ...state.claimData, payments: action.payload
            }
        }
        case 'SET_NEW_CLAIM_TOTALS':  return {
            ...state, claimData: {
                ...state.claimData, totals: action.payload
            }
        }
        case 'SET_TOTAL_SNAPSHOT': return { ...state, totals: action.payload }
        case 'DATA_CHANGED': return { ...state, dataChanged: action.payload }
        case 'SAVE_API_CALLED': return { ...state, apiCalled: action.payload }
        default: return state;
    }
};

export default claimReducer;
