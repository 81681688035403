import * as Constant from '../../utils/constant';
import * as Action from '../actions/accInvoiceMultiCreate';
import postData from '../../utils/api/base/post';
import * as API from '../../utils/api/base/apiUrl';

//getListInvoice 

export const createMultiInvoiceListAsync=(requestBody)=>{
    return async dispatch=>{
        dispatch(Action.createInvoiceListRequest(requestBody));
        try{
            const response = await postData(API.multiCreateInvoiceApi,requestBody,{},true); 
            console.log("response=====",response)
            if(response.errorData){
                dispatch(Action.createInvoiceListFailure(response.errorData));
            }else{
            const responseData = response.data;
            dispatch(Action.createInvoiceListSuccess(responseData));
            }
        } catch(error){
            dispatch(Action.createInvoiceListFailure(error));
        }
    }
}