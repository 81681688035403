import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from '../src/containers/App/App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import axios from 'axios';
import { loginRequest } from './authConfig';

export const APP_VERSION = "v" + (process.env.REACT_APP_VERSION || '05.1.2022-12');

const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account)
    msalInstance.setActiveAccount(event.payload.account);

  if (event.eventType === EventType.LOGIN_FAILURE)
    console.log(JSON.stringify(event));
});

const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount()

  if (!activeAccount && accounts.length === 0) {
    alert("Please login with your Fonterra Domain. Refreshing Page")
    window.location.reload()
  }

  const authResult = await msalInstance.acquireTokenSilent({
    scopes: loginRequest.scopes,
    account: activeAccount || accounts[0]
  })

  return authResult.accessToken
}

axios.interceptors.request.use(async config => {
  const accessToken = await acquireAccessToken(msalInstance)
  config.headers.authorization = accessToken ? `Bearer ${accessToken}` : '';
  return config;
});

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  const successResponses = ["200", "201", "202", "203", "204", "205", "206", "207", "208", "226"]
  if(error.response && !(successResponses.includes(error.response.status))) {
    console.log(error);
    if (error.response.data?.title) {
      const errorDetail = error.response.data?.detail ? error.response.data.detail : (error.response.data.title + " occurred");
      let alertMessage = `Unable to proceed, ${errorDetail}.`;
      alertMessage = error.response.data?.detail ? alertMessage : alertMessage + ` Details: ${error.response.data?.detail}`;
      alert(alertMessage);
    } else {
      alert(`${error.response.statusText}, backend responded with http ${error.response.status}`);
    }
    return Promise.reject(error.response)
  }
  else if (error.response && error.response.data) return Promise.reject(error.response.data)
  return Promise.reject(error.message);
});

ReactDOM.render(  
  <MsalProvider instance={msalInstance}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </MsalProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();
reportWebVitals();
