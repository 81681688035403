import { GET_MAINTAINFACTORS_REQUEST, GET_MAINTAINFACTORS_SUCCESS, GET_MAINTAINFACTORS_FAILURE } from '../../utils/constant';

export const initialState = {
    isLoading: false,
    data: null,
    errorMessage: ''
}


export const getMaintainFactorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MAINTAINFACTORS_REQUEST:
            return { ...state, isLoading: true }
        case GET_MAINTAINFACTORS_SUCCESS:
            return { ...state, isLoading: false, data: action.payload }
        case GET_MAINTAINFACTORS_FAILURE:
            return { ...state, isLoading: false, data: action.payload }
        default:
            return state;
    }

}