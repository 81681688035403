import { useSelector, useDispatch } from 'react-redux'
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { Field, reduxForm, reset } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderRadioButtonField from '../../../../../shared/components/form/RadioButton';
import renderToggleButtonField from '../../../../../shared/components/form/ToggleButton';
import CheckBoxField from '../../../../../shared/components/form/CheckBox'
import axios from 'axios';
import store from '../../../../App/store';
import { setClaimDataAction, setUpdateAPIFormAction, setUpdateClaimFormAction, setClaimModeAction, setDataChanged } from '../../../../../redux/actions/claimAction';
import { resetWithdrawClaimData } from '../../../../../redux/actions/withdrawClaimAction';
import { withdrawClaimActionCreator } from '../../../../../redux/actionCreator/withdrawClaimActionCreator';
import { setEmployeeDetailAction } from '../../../../../redux/actions/employeeDetailAction';
import './custom.css';
// import ProgressBar from '../../../../../shared/components/ProgressBar';
import * as validation from './validations'
import { setFormFields as setFormFieldsViewMode } from './setFormFields'
import { getEmployeeGroup } from './getEmpGroup'
import { loadEmployeeData, loadAPIPreviousClaimRecords, saveQuickEdit } from './apiCalls'
import { setCloseClaimDataAction, resetcloseClaimData } from '../../../../../redux/actions/closeClaimAction';
import { formatDateForDisplay as formatDate} from '../../../../../utils/formatDate'
import { Prompt } from "react-router-dom";
//import toast, { Toaster } from 'react-hot-toast';

let previousText;
let WizardFormOne = ({ handleSubmit, handleChange, change, reset }) => {  
  const [empData, setEmpData] = useState([])
  const [data, setData] = useState([])
  const [isDOSI, setIsDOSI] = useState()
  const [empDetails, setEmpDetails] = useState([])
  const [disable, setDisable] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [text, setText] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [previousClaims, setPreviousClaims] = useState([])
  const [selected, setSelected] = useState("")
  const [isPaymentVariance, setIsPaymentVariance] = useState(false)
  const [haveContinueClaim, setHaveContinueClaim] = useState(false);
  const [callAPI, setCallAPI] = useState(0);
  const updateClaimData = useSelector((state) => state.claim)
  const withdrawClaimData = useSelector((state) => state.withdrawClaim.withdrawClaimData);
  const withdrawApiClaimData = useSelector((state) => state.withdrawClaim.withdrawClaimApiData);
  const closeClaimData = useSelector((state) => state.closeClaim.closeClaimData);
  const closeClaim = useSelector((state) => state.closeClaim);
  const [isUpdateToClaim, setIsUpdateToClaim] = useState(false)
  const [isCloseClaimAction, setIsCloseClaimAction] = useState(closeClaim.mode === "CLOSE")
  const [isWithdrawClaim, setWithdrawClaim] = useState(false)
  const [isViewClaim, setIsViewClaim] = useState(updateClaimData.mode === "VIEW")
  const isCreateClaim = useSelector((state) => state.claim.mode === "CREATE")
  const formData = useSelector((state) => state.form.wizard);
  const [updateReason, setUpdateReason] = useState(formData?.values?.reasonForUpdate?.value || false)
  const [calculationStatus, setCalculationStatus] = useState()
  const [varianceAmountValue, setVarianceAmount] = useState()
  const [showNewDOSI, setShowNewDOSI] = useState(false)
  const [withdrawOtherReason, setWithdrawOtherReason] = useState(false);
  const [withdrawClaimId, updateWithdrawClaimId] = useState(null);
  const [reasonWithdrawValue, updateReasonValue] = useState("");
  const [reasonRequired, setReasonRequired] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [noteValue, updateNoteValue] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  
  useEffect(() => {
    //const closeClaimMode = closeClaim?.mode;
    if(//closeClaimMode === "CLOSE" && 
    Object.size(closeClaimData) > 0){
      setIsCloseClaimAction(true)
    }
    if (updateClaimData.updateClaimAPIData.claim) {
      setIsUpdateToClaim(true)
    }
    if (Object.size(withdrawClaimData) > 0) {
      console.log("withdrawClaimData", withdrawClaimData)
      setWithdrawClaim(true)
      updateWithdrawClaimId(withdrawClaimData.id)
    }
    if (Object.size(withdrawClaimData) > 0 && Object.size(withdrawApiClaimData) >0) {
      console.log("withdrawApiClaimData", withdrawApiClaimData)
      const {status,statusText}=withdrawApiClaimData;
      if (status == 200){
        alert("Request has been submitted successfully!");
      } 
      else if(status && statusText && status!==200) alert(statusText);
      updateLoading(false);
    }
    if (formData && formData.reasonForUpdate) setUpdateReason(formData.reasonForUpdate.value)
  }, [updateClaimData, withdrawClaimData, withdrawApiClaimData, closeClaimData])

  const changeSelectOptionHandler = (event) => {
    setSelected(event.target.value);
  };

  Object.size = function (obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };


  const debouncedSearchTerm = useDebounce(text, 900);

  const yes = ["DOSI"];
  const no = ["DOFI"];
  let type = null;
  let options = null;
  if (selected === "Yes") {
    type = yes;
  } else if (selected === "No") {
    type = no;
  }
  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }

  const setUpdateClaimValues = () => {
    change("firstName", updateClaimData.updateClaimFormData.employeeFirstName)
    change("lastName", updateClaimData.updateClaimFormData.employeeLastName)
    change("employeeNumber", updateClaimData.updateClaimAPIData.claim.employeeNumber)

    if (updateClaimData.updateClaimFormData.workRelated === "N") change("injury", { value: 'Non-Work', label: 'Non-Work' })
    else if (updateClaimData.updateClaimFormData.workRelated === "W") change("injury", { value: 'Work', label: 'Work' })

    if (updateClaimData.updateClaimFormData.claimType === "DOFI") {
      change("subInjury", { value: 'No', label: 'No' })
      change("claimType", { value: 'DOFI', label: 'DOFI' })
    }
    else if (updateClaimData.updateClaimFormData.claimType === "DOSI") {
      change("subInjury", { value: 'Yes', label: 'Yes' })
      change("claimType", { value: 'DOSI', label: 'DOSI' })
    }

    if (updateClaimData.updateClaimAPIData.claim.newDOSI === "X") {
      change("newDOSI", "yes")
      setIsDOSI(true)
    }
    else if (updateClaimData.updateClaimAPIData.claim.newDOSI === " ") {
      change("newDOSI", "no")
      setIsDOSI(false)
    }

    change("dofiDate", formatDate(updateClaimData.updateClaimAPIData.claim.dofiDate))
    change("claimNumber", updateClaimData.updateClaimAPIData.claim.claimNumber)

    if (updateClaimData.updateClaimAPIData.claim.continuedClaim === "X") {
      setHaveContinueClaim(true);
      localStorage.setItem("localcontinueClaim", true)
    } else if (updateClaimData.updateClaimAPIData.claim.continuedClaim === " ") {
      setHaveContinueClaim(false);
      localStorage.setItem("localcontinueClaim", false)
    }

    change("dateContinue", formatDate(updateClaimData.updateClaimAPIData.claim.continuationDate))
    change("serviceNowRequest", updateClaimData.updateClaimAPIData.claim.serviceNowRequest)    
    updateClaimData.updateClaimAPIData.claim.paymentVariance === "X" ? setIsPaymentVariance(true) : setIsPaymentVariance(false)    

    change("dateChangeRuleGrp",formatDate(updateClaimData.updateClaimAPIData.claim.dateChangeRuleGrp));
  }
  const setWithdrawClaimValues = () => {
    change("firstName", withdrawClaimData.employeeFirstName)
    change("lastName", withdrawClaimData.employeeLastName)
    change("employeeNumber", withdrawClaimData.employeeNumber)
    change("reasonNotes", null);

    if (withdrawClaimData.workRelated === "N") change("injury", { value: 'Non-Work', label: 'Non-Work' })
    else if (withdrawClaimData.workRelated === "W") change("injury", { value: 'Work', label: 'Work' })

    if (withdrawClaimData.claimType === "DOFI") {
      change("subInjury", { value: 'No', label: 'No' })
      change("claimType", { value: 'DOFI', label: 'DOFI' })
    }
    else if (withdrawClaimData.claimType === "DOSI") {
      change("subInjury", { value: 'Yes', label: 'Yes' })
      change("claimType", { value: 'DOSI', label: 'DOSI' })
    }

    if (withdrawClaimData.newDOSI === "X") {
      change("newDOSI", "yes")
      setIsDOSI(true)
    }
    else if (withdrawClaimData.newDOSI === " ") {
      change("newDOSI", "no")
      setIsDOSI(false)
    }

    change("dofiDate", formatDate(withdrawClaimData.dofiDate))
    change("claimNumber", withdrawClaimData.claimNumber)

    if (withdrawClaimData.continuedClaim === "X") {
      setHaveContinueClaim(true);
      localStorage.setItem("localcontinueClaim", true)
    } else if (withdrawClaimData.continuedClaim === " ") {
      setHaveContinueClaim(false);
      localStorage.setItem("localcontinueClaim", false)
    }

    change("dateContinue", formatDate(withdrawClaimData.continuationDate))
    change("serviceNowRequest", withdrawClaimData.serviceNowRequest)
  }

  const setCloseClaimValues = () => {
    const claimData = closeClaimData;
    change("firstName", claimData.employeeFirstName)
    change("lastName", claimData.employeeLastName)
    change("employeeNumber", claimData.employeeNumber)
    change("reasonNotes", null);

    if (claimData.workRelated === "N") change("injury", { value: 'Non-Work', label: 'Non-Work' })
    else if (claimData.workRelated === "W") change("injury", { value: 'Work', label: 'Work' })

    if (claimData.claimType === "DOFI") {
      change("subInjury", { value: 'No', label: 'No' })
      change("claimType", { value: 'DOFI', label: 'DOFI' })
    }
    else if (claimData.claimType === "DOSI") {
      change("subInjury", { value: 'Yes', label: 'Yes' })
      change("claimType", { value: 'DOSI', label: 'DOSI' })
    }

    if (claimData.newDOSI === "X") {
      change("newDOSI", "yes")
      setIsDOSI(true)
    }
    else if (claimData.newDOSI === " ") {
      change("newDOSI", "no")
      setIsDOSI(false)
    }

    change("dofiDate", formatDate(claimData.dofiDate))
    change("claimNumber", claimData.claimNumber)

    if (claimData.continuedClaim === "X") {
      setHaveContinueClaim(true);
      localStorage.setItem("localcontinueClaim", true)
    } else if (claimData.continuedClaim === " ") {
      setHaveContinueClaim(false);
      localStorage.setItem("localcontinueClaim", false)
    }

    change("dateContinue", formatDate(claimData.continuationDate))
    change("serviceNowRequest", claimData.serviceNowRequest)
    change("varianceAmount", claimData.varianceAmount)
    change("calculationStatus", claimData.calculationStatus)
  }

  const completeReset = () => {
    store.dispatch(reset('wizard'))
    localStorage.removeItem("localpslTopUp")
    localStorage.removeItem("localworking30Hours")
    localStorage.removeItem("localyearlyEarnings")
    localStorage.removeItem("localindexation")
    localStorage.removeItem("localearningsDataWeekly")
    localStorage.removeItem("localworkingWeeks")
    localStorage.removeItem("localcontinueClaim")
    store.dispatch(setClaimDataAction({}))
    store.dispatch(setUpdateClaimFormAction({}))
    store.dispatch(setUpdateAPIFormAction({}))
    store.dispatch(setCloseClaimDataAction({}))
    store.dispatch(setDataChanged(false))
  }


  const handleSubInjury = (e) => {
    if (e.value === "No") {
      change("claimType", { value: 'DOFI', label: 'DOFI' })      
      change("newDOSI", null)
      change("dofiDate", null)
      setShowNewDOSI(false)
      setIsDOSI(false)
    } else {
      change("claimType", { value: 'DOSI', label: 'DOSI' })
      setShowNewDOSI(true)
    }
  }

  const handleReasonForUpdate = (e) => {
    if(e.value !== 'changeRuleGrouping') change('dateChangeRuleGrp',null)
    setUpdateReason(e.value);
    if(e.value) store.dispatch(setDataChanged(isUpdateToClaim ? true : false))
  }
  const handleCalculationStatus = (e) => {
    setCalculationStatus(e.value)
    if(e.value) store.dispatch(setDataChanged(isCloseClaimAction ? true : false))
  }

  const handleContinueClaim = (e) => {
    if(!e) change('dateContinue', null)
    setHaveContinueClaim(e); 
    localStorage.setItem("localcontinueClaim", e) 
  }

  const handlePrevClaim = (e, claim) => {
    console.log(e.target.innerHTML.split(',')[0].split(' ')[1])
    if(e.target.innerHTML.split(',')[0].split(' ')[1] !== "not-found") change("claimNumber", claim.claimNumber);
    setPreviousClaims([]) 
  }  

  const validateInputs = (e)  => {
    // TODO - is preventDefault stopping copy paste when creating consecutive claims?
    e.preventDefault()
    loadEmployeeDetails()
    handleSubmit()
  }

  const quickSubmit = async (e) => {
    e.preventDefault()
    var claimID;
    var claimNumber; 
    var dbCalculationStatus;
    if(isCloseClaimAction){
      claimID = closeClaimData.id;
      claimNumber = closeClaimData.claimNumber;
      dbCalculationStatus = closeClaimData.calculationStatus;
    }else{
      claimID = updateClaimData.updateClaimAPIData.claim.id;
      claimNumber = updateClaimData.updateClaimAPIData.claim.claimNumber;
      dbCalculationStatus = updateClaimData.updateClaimAPIData.calculationStatus;
    }
    const notes = noteValue ? noteValue : null
    const payVariance = isPaymentVariance ? "X"  : " "
    const calculationStatusValue = calculationStatus || dbCalculationStatus;
    const posted = await saveQuickEdit(claimID, claimNumber, payVariance ,notes, calculationStatusValue, varianceAmountValue)
    if(posted.status === 200 && posted.statusText === "OK"){
      alert("Claim updated Successfully");
      store.dispatch(resetcloseClaimData());
      history.push({pathname: "/tables/data_table"})
    }
    else alert("Update Failed")
    return
  }

  useEffect(() => {
    if (isUpdateToClaim && Object.size(updateClaimData.withdrawClaimData) == 0) setUpdateClaimValues()
    if (isWithdrawClaim && (Object.size(updateClaimData.updateClaimAPIData) == 0 && Object.size(updateClaimData.claimData) == 0)) setWithdrawClaimValues()
    if (isViewClaim && Object.size(updateClaimData.updateClaimAPIData)) setViewClaimValues()
    if (isCloseClaimAction) setCloseClaimValues()
    if((isUpdateToClaim && updateReason) || isWithdrawClaim || isCloseClaimAction) store.dispatch(setDataChanged(isUpdateToClaim ? true : false));
  }, [isUpdateToClaim, isWithdrawClaim, isViewClaim, isCloseClaimAction, closeClaimData])

  useEffect(() => {
    if (isUpdateToClaim) return;
    try {
      if (formData.values.employeeNumber) setDisable(false);
      formData.values.newDOSI === "yes" ? setIsDOSI(true) : setIsDOSI(false);      
      (formData.values.subInjury && formData.values.subInjury.value === "Yes") ? setShowNewDOSI(true) : setShowNewDOSI(false);
    } catch (e) {
      console.log("Emp Number Empty")
    }
    if (initialLoad) {
      setInitialLoad(false)
      return
    }
    if (debouncedSearchTerm) {
      loadEmployeeData(setEmpData, setSuggestions, text)
      const empDataToSet = (!empData) ? [{ employeeNumber: "No Employees found"}] : empData
      setSuggestions(empDataToSet)
    }
  }, [debouncedSearchTerm])

  const setViewClaimValues = async () => {          
      await loadEmployeeDetails()
      setFormFieldsViewMode(change, updateClaimData, formData)
      handleSubmit()
  }

  useEffect( async () => {
    if(callAPI){
      const allClaimData = await loadAPIPreviousClaimRecords(data);
      setPreviousClaims(allClaimData)
    }
  }, [callAPI])
  

  const loadEmployeeDetails = async () => {
    if (isUpdateToClaim || isViewClaim) {
      const group = getEmployeeGroup(updateClaimData.updateClaimAPIData.employee.payType, updateClaimData.updateClaimAPIData.employee.payFrequency, updateClaimData.updateClaimAPIData.employee.employmentType, updateClaimData.updateClaimAPIData.employee.annualizedHours)
      const emp = {
        data: {
          "employeeNumber": updateClaimData.updateClaimAPIData.employee.employeeNumber,
          "irdNumber": updateClaimData.updateClaimAPIData.employee.irdNumber,
          "employmentType": updateClaimData.updateClaimAPIData.employee.employmentType,
          "employmentTypeDescription": updateClaimData.updateClaimAPIData.employee.employmentTypeDescription,
          "contractType": updateClaimData.updateClaimAPIData.employee.contractType,
          "contractTypeDescription": updateClaimData.updateClaimAPIData.employee.contractTypeDescription,
          "payCategory": updateClaimData.updateClaimAPIData.employee.payCategory,
          "payCategoryDescription": updateClaimData.updateClaimAPIData.employee.payCategoryDescription,
          "paymentType": updateClaimData.updateClaimAPIData.employee.payType,
          "paymentTypeDescription": updateClaimData.updateClaimAPIData.employee.payTypeDescription,
          "payCycle": updateClaimData.updateClaimAPIData.employee.payCycle,
          "payCycleDescription": updateClaimData.updateClaimAPIData.employee.payCycleDescription,
          "payFrequency": updateClaimData.updateClaimAPIData.employee.payFrequency,
          "payFrequencyDescription": updateClaimData.updateClaimAPIData.employee.payFrequencyDescription,
          "hireDate": updateClaimData.updateClaimAPIData.employee.hireDate.substr(0, 10),
          "hrsPerDay": updateClaimData.updateClaimAPIData.employee.hrsPerDay,
          "daysPerWeek": updateClaimData.updateClaimAPIData.employee.daysPerWeek,
          "annualSalary": updateClaimData.updateClaimAPIData.employee.annualSalary,
          "contractHoursPerWeek": updateClaimData.updateClaimAPIData.employee.contractHoursPerWeek,
          group,
          "annualizedHours": updateClaimData.updateClaimAPIData.employee.annualizedHours,
          "hourlyRate": updateClaimData.updateClaimAPIData.employee.hourlyRate
        }
      }
      dispatch(setEmployeeDetailAction(emp.data));
      return;
    }
    if(!formData.values.employeeNumber) return
    const empToLoad = data.employeeNumber || formData.values.employeeNumber.toString()
    const employmentDetails = await axios.get(process.env.REACT_APP_API_BASE_URL + `/employees/${empToLoad}/employmentdetails`);    
    const empGroup = getEmployeeGroup(employmentDetails.data.paymentType, employmentDetails.data.payFrequency, employmentDetails.data.employmentType, employmentDetails.data.annualizedHours)
    employmentDetails.data.group = empGroup
    setEmpDetails(employmentDetails)
    if(employmentDetails.data.paymentType == 'S' && !employmentDetails.data.annualizedHours) employmentDetails.data.annualizedHours = 2080
    dispatch(setEmployeeDetailAction(employmentDetails.data));
  }

  const onSuggestHandler = (text) => {
    setText(text)
    setSuggestions([])
  }

  function findMatches() {
    empData.filter(employee => {
      const regex = new RegExp(`${text}`, "gi");
      return employee.employeeNumber.match(regex) || employee.firstName.match(regex) || employee.lastName.match(regex) || employee.locationName.match(regex) || employee.positionName.match(regex)
    })

  }
  const onChangeHandler = async (text) => {
    let matches = []
    if (text !== previousText) {
      if(!text) {
        setSuggestions([])
        setEmpData([])
      }
      else if (empData) {
        matches = findMatches()
        setSuggestions(empData)
      }
      setText(text)
      previousText = text
    }
  }

  const setWithdrawClaimReason = (value) => {
    setReasonRequired(false)
    updateReasonValue(value)
    if (value === "Others + with notes" || value === "othersWithNotes") {
      setWithdrawOtherReason(true)
    } else {
      setWithdrawOtherReason(false)
    }
    if(value) store.dispatch(setDataChanged(isWithdrawClaim ? true : false));
  }

  function useDebounce(value, delay) {

    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
      () => {
        // Set debouncedValue to value (passed in) after the specified delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Return a cleanup function that will be called every time ...
        // ... useEffect is re-called. useEffect will only be re-called ...
        // ... if value changes (see the inputs array below). 
        // This is how we prevent debouncedValue from changing if value is ...
        // ... changed within the delay period. Timeout gets cleared and restarted.
        // To put it in context, if the user is typing within our app's ...
        // ... search box, we don't want the debouncedValue to update until ...
        // ... they've stopped typing for more than delay amount of time.
        return () => {
          clearTimeout(handler);
        };
      },
      // Only re-call effect if value changes
      // You could also add the "delay" var to inputs array if you ...
      // ... need to be able to change that dynamically.
      [value]
    );

    return debouncedValue;
  }

  function updateNoteValueData(value) {
    updateNoteValue(value);
  }

  function submitWithdrawHandler(e) {
    e.preventDefault();
    const inputFields = e.target;
    let reasonWithdrawValues = reasonWithdrawValue;
    let reasonNotes = inputFields.reasonNotes.value;
    if (withdrawOtherReason && (reasonNotes == "" || reasonNotes == null)) {
      alert("Please enter a reason for withdraw the claim!")
    } else if (!reasonWithdrawValues) {
      //alert("Please select a reason!")
      setReasonRequired(true);
    } else {
      if ((Object.size(updateClaimData.updateClaimAPIData) == 0 && Object.size(withdrawClaimData) > 0)) {
        updateLoading(true)
        let withdrawClaimRequest;
        if (reasonWithdrawValues == "Others + with notes") {
          withdrawClaimRequest = { id: withdrawClaimId, changeReason: reasonWithdrawValues, notes: reasonWithdrawValues == "Others + with notes" ? reasonNotes : "" }
        } else {
          withdrawClaimRequest = { id: withdrawClaimId, changeReason: reasonWithdrawValues }
          //withdrawClaimRequest = { id: withdrawClaimId, changeReason: reasonWithdrawValues, notes: reasonWithdrawValues == "Others + with notes" ? reasonNotes : "" }

        }
        dispatch(withdrawClaimActionCreator(withdrawClaimRequest));
        //history.push({pathname: "/temp"})
        dispatch(resetWithdrawClaimData());
        dispatch(reset('wizard'));
        //store.dispatch(setClaimModeAction('CREATE'))
        history.push({pathname: "/tables/data_table"})
        updateLoading(false)
      }
    }

  }

  return (
    <form className="form form--horizontal wizard__form" onSubmit={(Object.size(updateClaimData.updateClaimAPIData) == 0 && Object.size(withdrawClaimData) > 0) ? submitWithdrawHandler : (isPaymentVariance || isCloseClaimAction) ?  quickSubmit : validateInputs} onChange={handleChange}>
      {/* <div>
        <Prompt
            when={dataChanged}
            message={location => `Changes made will be lost. Are you sure you want to go to ${location.pathname} ?`}
        />
      </div> */}
      <h3 className="wizard__title">Employee Data</h3>
      <div hidden={isUpdateToClaim || !Object.size(withdrawClaimData) == 0 || isCloseClaimAction} className="form__form-group">
        <span className="form__form-group-label">Search Employee</span>
        <div className="form__form-group-field">
          <div className="container">
            <input
              type="text"
              className="col-md-12 input"
              onChange={(e) => { onChangeHandler(e.target.value) }}
              value={text}
              maxLength="50"
            // onBlur={() => setSuggestions([])}
            />
            {suggestions && suggestions.map((suggestion, i) =>
              <div className="suggestion col-md-12 justify-content-md-center" key={suggestion.employeeNumber} onClick={() => { completeReset(); setDisable(false); change("firstName", suggestion.firstName); change("lastName", suggestion.lastName); change("employeeNumber", suggestion.employeeNumber); setData({ firstName: suggestion.firstName, lastName: suggestion.lastName, employeeNumber: suggestion.employeeNumber }); setSuggestions([]); setPreviousClaims([]); store.dispatch(setDataChanged(true));}}>{suggestion.employeeNumber}, {suggestion.firstName} {suggestion.lastName}, {suggestion.positionName}, {suggestion.locationName}</div>
            )}
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Employee</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              name="firstName"
              id="firstName"
              component={validation.renderField}
              validate={[validation.required]}
              placeholder="First Name"
              type="text"              
              disabled={true}
            />
          </div>
          <div className="container">
            <Field
              type="text"
              name="lastName"
              id="lastName"
              component={validation.renderField}
              validate={[validation.required]}
              placeholder="Last Name"
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Employee Number</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              type="text"
              name="employeeNumber"
              id="employeeNumber"
              component={validation.renderField}
              validate={[validation.required]}
              placeholder="Employee Number"
              className="col-md-12 input"
              disabled={true}
              value={data.employeeNumber}
            />
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Work/Non-Work Injury?</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              name="injury"
              component={renderSelectField}
              validate={[validation.required]}
              type="text"
              options={[
                { value: 'Work', label: 'Work' },
                { value: 'Non-Work', label: 'Non-Work' },
              ]}
              placeholder="Injury Type"
              isDisabled={disable || isUpdateToClaim}
            />
          </div>
        </div>
      </div>
      <div hidden={isUpdateToClaim} className="form__form-group">
        <span className="form__form-group-label">Subsequent Injury?</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              name="subInjury"
              component={renderSelectField}
              validate={[validation.required]}
              type="text"
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
              placeholder="Yes/No"
              isDisabled={disable || isUpdateToClaim}
              onChange={handleSubInjury}
            />
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Claim Type</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              name="claimType"
              component={renderSelectField}
              validate={[validation.required]}
              type="text"
              options={[
                { value: 'DOFI', label: 'DOFI' },
                { value: 'DOSI', label: 'DOSI' },
              ]}
              placeholder="DOFI/DOSI"
              isDisabled={true}
            />
          </div>
        </div>
      </div>
      <div hidden={!showNewDOSI} className="form__form-group">
        <span className="form__form-group-label">New DOSI for Fonterra?</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              name="newDOSI"
              disabled={disable || isUpdateToClaim}
              component={renderRadioButtonField}
              label="Yes"
              radioValue="yes"
              onChange={(e) => {setIsDOSI(true); setPreviousClaims([]);}}
              validate={[validation.customValidation]}
            />
            <Field
              name="newDOSI"
              disabled={disable || isUpdateToClaim}
              component={renderRadioButtonField}
              label="No"
              radioValue="no"
              onChange={(e) => {setIsDOSI(false);change("dofiDate",null);setCallAPI(prev=>prev+1); change('claimNumber', null)}}
              validate={[validation.customValidation]}
            />
          </div>
        </div>
      </div>
      <div hidden={!isDOSI} className="form__form-group">
        <span className="form__form-group-label">DOFI Date</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              id="dofiDate"
              name="dofiDate"
              disabled={disable || isUpdateToClaim}
              component={validation.renderField}
              type="date"
              validate={[validation.customValidation]}
            />
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Claim Number</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              name="claimNumber"
              component={validation.renderField}
              validate={[validation.required]}
              type="text"
              placeholder="Claim Number"
              disabled={isUpdateToClaim || !Object.size(withdrawClaimData) == 0 || isCloseClaimAction}
            />
            {previousClaims && previousClaims.map((claim, i) =>
              <div className="suggestion col-md-12 justify-content-md-center" onClick={(e)=>handlePrevClaim(e,claim)}>{claim.claimNumber && "Claim " + claim.claimNumber}, {claim.calculationStatus && "Status " + claim.calculationStatus} {claim.dateOfInjury && "DOFI " + claim.dateOfInjury.substr(0,10)}, {claim.monitoredBy && "Monitored By " + claim.monitoredBy}</div>
            )}
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Continuation claim due to change in Rule Group?</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              name="continueClaim"
              component={renderToggleButtonField}
              onChange={handleContinueClaim}
              defaultChecked={localStorage.getItem("localcontinueClaim") === "true" ? true : false}
              disabled={disable || isUpdateToClaim || isCloseClaimAction}
            />
          </div>
        </div>
      </div>
      <div hidden={!haveContinueClaim} className="form__form-group">
        <br /><br /><br />
        <span className="form__form-group-label">Date to continue the claim from</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              name="dateContinue"
              component="input"
              type="date"
              disabled={isUpdateToClaim}
            />
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">ServiceNow Ticket?</span>
        <div className="form__form-group-field">
          <div className="container">
            <Field
              name="serviceNowRequest"
              component="input"
              type="text"
              placeholder="ServiceNow Ticket"
              disabled={isUpdateToClaim || isCloseClaimAction }
            />
          </div>
        </div>
      </div>

      <div hidden={!(isUpdateToClaim)} className="form__form-group">
        <span className="form__form-group-label">{isWithdrawClaim == true && isUpdateToClaim == false ? 'Reason for withdraw?' : 'Reason for Update?'}</span>
        <div className="form__form-group-field">
          <Field
            name="reasonForUpdate"
            component={renderSelectField}
            type="text"
            display="none"
            options={updateClaimData.updateClaimFormData.workRelated === "W" ? [
              { value: 'abatementLightDuties', label: 'Abatement - Light Duties' },
              { value: 'abatementAnnualLeave', label: 'Abatement - Annual Leave' },
              { value: 'abatementPublicHoliday', label: 'Abatement - Public Holiday' },
              { value: 'abatementContractHours', label: 'Abatement - CHC Contract Hours Completed' },
              { value: 'abatementTerminatedEmployee', label: 'Abatement - Terminated Employee' },
              { value: 'topUpOrdinaryPay', label: 'Top Up - Update Ordinary Pay' },
              { value: 'editMedicalExpiryDate', label: 'Edit Medical Expiry Date' },
              { value: 'changeRuleGrouping', label: 'Change in Rule Grouping' },
              { value: 'changePSLTopUp', label: 'Changes to PSL top up decision' },
              { value: 'ownershipChange', label: 'Ownership change'}  
            ]: [
              { value: 'abatementLightDuties', label: 'Abatement - Light Duties' },
              { value: 'abatementAnnualLeave', label: 'Abatement - Annual Leave' },
              { value: 'abatementPublicHoliday', label: 'Abatement - Public Holiday' },
              { value: 'abatementContractHours', label: 'Abatement - CHC Contract Hours Completed' },
              { value: 'abatementTerminatedEmployee', label: 'Abatement - Terminated Employee' },
              { value: 'topUpOrdinaryPay', label: 'Top Up - Update Ordinary Pay' },
              { value: 'editMedicalExpiryDate', label: 'Edit Medical Expiry Date' },
              { value: 'changeRuleGrouping', label: 'Change in Rule Grouping' },
              { value: 'changePSLTopUp', label: 'Changes to PSL top up decision' },
            ]}
            placeholder="Reason"
            onChange={handleReasonForUpdate}
            validate={[validation.customValidation]}
          />
        </div>
      </div>
      <div hidden={(Object.size(updateClaimData.updateClaimAPIData) == 0 && Object.size(withdrawClaimData) > 0) ? false : true} className="form__form-group">
        <span className="form__form-group-label">{isWithdrawClaim == true && isUpdateToClaim == false ? 'Reason for withdraw?' : 'Reason for Update?'}</span>
        <div className="form__form-group-field" style = {{ borderBottom: reasonRequired? '1px solid red': ''}}> 
          <Field
            name="reasonForWithdraw"
            component={renderSelectField}
            type="text"
            display="none"
            options={[
              { value: 'changeinDofiDosidates', label: 'Recalculate claim - Change in DOFI/ DOSI dates' },
              { value: 'incorrectOrdinaryPay', label: 'Incorrect Ordinary Pay' },
              // { value: 'editLTESTEEarningsAwaitingDecision', label: 'Overwrite LTE/STE earnings for claims created on Awaiting decision status' },
              { value: 'changeInLTESTEEarnings', label: 'Mismatch with ACC on LTE/STE earnings' },
              { value: 'othersWithNotes', label: 'Others + with notes' },
            ]}
            placeholder="Reason"
            onChange={(e) => setWithdrawClaimReason(e.label)}
            validate={[validation.customValidation]}
          />
        </div>
      </div>
      <div hidden={!(isUpdateToClaim)} className="form__form-group">
        <span className="form__form-group-label">Overpayment / Underpayment</span>
        <div className="form__form-group-field">
          <Field
            name="paymentVariance"
            component={CheckBoxField}
            onChange={(e)=>{setIsPaymentVariance(e.target.checked); if(e.target.checked) change("reasonForUpdate", null); store.dispatch(setDataChanged(true));}}
            defaultChecked={isPaymentVariance}
          />
        </div>
      </div>
      <div hidden={withdrawOtherReason == true ? false : (isUpdateToClaim && updateClaimData.updateClaimAPIData.claim.calculationStatus == "Submitted") ? false : isPaymentVariance ? false : true} className="form__form-group">
        <span className="form__form-group-label">Notes</span>
        <div className="form__form-group-field">
          <Field
            onChange={(e) => updateNoteValueData(e.target.value)}
            name="reasonNotes"
            component={validation.renderTextArea}
            type="text"
            value={noteValue}
            validate={[validation.customValidation]}
          />
        </div>
      </div>
      <div hidden={!(isUpdateToClaim && updateReason == "changeRuleGrouping")} className="form__form-group">
        <span className="form__form-group-label">Effective Date of Change in Rule Grouping</span>
        <div className="form__form-group-field">
          <Field
            name="dateChangeRuleGrp"
            //component="input"
            component={validation.renderField}
            type="date"
            validate={[validation.customValidation]}
            min={updateClaimData?.updateClaimAPIData?.claim?.dateOfInjury}
            max={updateClaimData?.updateClaimAPIData?.claim?.certificateValidTo}
          />
        </div>
      </div>
      <div hidden={!isCloseClaimAction} className="form__form-group">
        <span className="form__form-group-label">Calculation Status</span>
        <div className="form__form-group-field">
          <Field
            name="calculationStatus"
            component={renderSelectField}
            type="text"
            display="none"
            options={[
              { value: 'PendingClosure', label: 'Claim Pending Closure' },
              { value: 'Closed', label: 'Close Claim' },
              { value: 'Active', label: 'Set as Active' }
            ]}
            placeholder="Closure"
            onChange={handleCalculationStatus}
            validate={[validation.customValidation]}
          />
        </div>
      </div>    
      <div hidden={!isCloseClaimAction} className="form__form-group">
        <span className="form__form-group-label">Variance Amount</span>
        <div className="form__form-group-field">
          <Field
            name="varianceAmount"
            component="input"
            type="number"
            placeholder="123456"
            onChange={e => setVarianceAmount(e.target.value)}
          />
        </div>
      </div>  
      {/* <div>
        <Toaster/>
      </div> */}
      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        {!isViewClaim && <Button color="primary" type="button" disabled className="previous">Back</Button>}
        {!isViewClaim && <Button color="primary" type="submit" disabled={(loading || isPaymentVariance || isCloseClaimAction)?true:false} className="next">{(Object.size(updateClaimData.updateClaimAPIData) == 0 && Object.size(withdrawClaimData) > 0) ?'Withdraw' : 'Next'}</Button>}
        {((isUpdateToClaim && isPaymentVariance) || (isCloseClaimAction)) && <Button color="primary" type="submit" className="next">Save</Button>}
      </ButtonToolbar>
    </form>
  );
};

WizardFormOne.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  // handleChange: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormOne);
