import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import showResults from '../../Show';
import getClaim from '../../getClaim';
import CalcClaim from '../../CalcClaim';
import updateClaim from '../../updateClaim';
import Wizard from './components/WizardForm';
import { reset } from 'redux-form';
import store from '../../../App/store';
import { setUpdateClaimFormAction,setUpdateAPIFormAction, setClaimModeAction, clearClaimData, setClaimDataAction, setDataChanged } from '../../../../redux/actions/claimAction'
import { setWithdrawClaimDataAction, withdrawClaimDataSuccess,withdrawClaimDataFailure } from '../../../../redux/actions/withdrawClaimAction'
import { setCloseClaimDataAction, closeClaimDataSuccess,closeClaimDataFailure } from '../../../../redux/actions/closeClaimAction'

const WizardForm = () => {
  const { t } = useTranslation('common');
  const history = useHistory();

  useEffect(async () => {
    if (history.location.state && history.location.state.from === "/tables/data_table" && history.location.state.type === "Update") {
        store.dispatch(setUpdateClaimFormAction(history.location.state.rowData))
        const APIClaimDetails = await getClaim(history.location.state.rowData.id)
        store.dispatch(setUpdateAPIFormAction(APIClaimDetails))
        store.dispatch(setCloseClaimDataAction({}));
    } else if (history.location.state && history.location.state.from === "/tables/data_table" && history.location.state.type === "Withdraw") {      
        store.dispatch(setWithdrawClaimDataAction(history.location.state.rowData))
        store.dispatch(setCloseClaimDataAction({}));
      } else if (history.location.state && history.location.state.from === "/tables/data_table" && history.location.state.type === "Close") {      
        store.dispatch(setCloseClaimDataAction(history.location.state.rowData))
    } else if (history.location.state && history.location.state.from === "/tables/data_table" && history.location.state.type === "View") {
        store.dispatch(setUpdateClaimFormAction(history.location.state.rowData))
        const APIClaimDetails = await getClaim(history.location.state.rowData.id)
        store.dispatch(setUpdateAPIFormAction(APIClaimDetails))
        store.dispatch(setCloseClaimDataAction({}));
    } else {
        store.dispatch(setUpdateClaimFormAction({}))
        store.dispatch(setWithdrawClaimDataAction({}))
        store.dispatch(withdrawClaimDataSuccess({}));
        store.dispatch(withdrawClaimDataFailure({}));
        store.dispatch(setCloseClaimDataAction({}))
        store.dispatch(closeClaimDataSuccess({}));
        store.dispatch(closeClaimDataFailure({}));
        store.dispatch(clearClaimData())
        store.dispatch(setClaimModeAction('CREATE'))
    }
    store.dispatch(setDataChanged(false))
  }, [])

  useEffect(() => {
    return () => {
      store.dispatch(reset('wizard'))
      localStorage.removeItem("localpslTopUp")
      localStorage.removeItem("localworking30Hours")
      localStorage.removeItem("localyearlyEarnings")
      localStorage.removeItem("localindexation")
      localStorage.removeItem("localearningsDataWeekly")
      localStorage.removeItem("localworkingWeeks")
      localStorage.removeItem("localcontinueClaim")
      localStorage.removeItem("localDivisorFortnightlyHours")
      localStorage.removeItem("weekData")
      store.dispatch(setClaimDataAction({}))
      store.dispatch(setUpdateClaimFormAction({}))
      store.dispatch(setUpdateAPIFormAction({}))
      store.dispatch(withdrawClaimDataFailure({}));
      store.dispatch(withdrawClaimDataSuccess({}));
      store.dispatch(closeClaimDataSuccess({}));
      store.dispatch(closeClaimDataFailure({}));
      localStorage.removeItem('totals')
      store.dispatch(clearClaimData())
      store.dispatch(setDataChanged(false))
    }
  }, [])

  return (
    <Container>
      {/* <Row>
        <Col md={12}>
          <h3 className="page-title">{t('forms.wizard_from.title')}</h3>
          <h3 className="page-subhead subhead">ACCelerator - New Claim</h3>
        </Col>
      </Row> */}
      <Wizard onSubmit={(e)=>showResults(e, history)} onCalcClaim={CalcClaim} onUpdate={(e)=>updateClaim(e, history)} />
    </Container>
  );
};

export default WizardForm;
