import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import RefreshIcon from 'mdi-react/RefreshIcon';
import ProgressBar  from '../../../shared/components/ProgressBar';

export const Buttons = (props) => {
    const { color, title, onClick, onButtonClick,loading=false } = props;
    let clickButton;
    if (onClick) {
        clickButton = onClick;
    } else {
        clickButton = onButtonClick;
    }
    return (
        title == "Revalidate" ? <Button color={color} onClick={clickButton}>{loading?<ProgressBar size={"10"} />:<RefreshIcon />}</Button> : <Button color={color} onClick={clickButton}>{title}</Button>

    )
}

export const LoaderButton = ({color,onClick,isLoading,title}) => {
    return (
       <Button color={color} onClick={isLoading?null:onClick}>{isLoading?<ProgressBar size={"8"} />:title}</Button>
    )
}

Buttons.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
};
LoaderButton.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
};