
import * as Constant from '../../utils/constant'

export const getInvoiceListRequest=()=>({
    type:Constant.GET_INVOICE_LIST_REQUEST,
})

export const getInvoiceListSuccess=(response)=>({
    type:Constant.GET_INVOICE_LIST_SUCCESS,
    payload:response
})

export const getInvoiceListFailure=(error)=>({
    type:Constant.GET_INVOICE_LIST_FAILURE,
    payload:error
})