import * as Constant from '../../utils/constant';
import * as Action from '../actions/withdrawClaimAction';
import postData from '../../utils/api/base/post';
import * as API from '../../utils/api/base/apiUrl';

//withdrawClaim action creator

export const withdrawClaimActionCreator=(requestBody)=>{
    return async dispatch=>{
        dispatch(Action.withdrawClaimDataRequest(requestBody));
        console.log("request body",requestBody);
        console.log("`${API.withdrawClaimData}/${requestBody.id}/withdraw`",`${API.withdrawClaimData}/${requestBody.id}/withdraw`);
        try{
            const response = await postData(`${API.withdrawClaimData}/${requestBody.id}/withdraw`,requestBody,{},true); 
            console.log("response=====",response)
            if(response.errorData){
                dispatch(Action.withdrawClaimDataFailure(response.errorData));
            }else{
            const responseData = response
            const responseDataWithStatus={status:responseData.status,statusText:responseData.statusText}
            dispatch(Action.withdrawClaimDataSuccess(responseDataWithStatus));
            }
        } catch(error){
            dispatch(Action.withdrawClaimDataFailure(error));
        }
    }
}