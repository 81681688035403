import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { blue } from '@mui/material/colors';
import { dashboardButtonsUIGuard as buttonGuard } from './auth/UIGuard'


export default function DialogBoxMenu(props) {
  const { data,handleClose,handleListItemClick, open } = props;
  const userRoles = localStorage.getItem('ACCROLES') ? localStorage.getItem('ACCROLES') : []

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Click to select value</DialogTitle>
      <List sx={{ pt: 0 }}>
        {data.map((value) => {
          const item = 
          <ListItem button onClick={()=>handleListItemClick(value)} key={value}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                {value === 'Withdraw Claim' && <CancelPresentationIcon /> }
                {value === 'Update Claim' &&  <PersonIcon />  }
                {value === 'View Claim' &&  <SearchIcon />  }
                {value === 'Close Claim' &&  <AssignmentTurnedInIcon />  }
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={value} />
          </ListItem>
          if(value === 'Withdraw Claim' && Boolean(buttonGuard.withdrawClaim.filter(role => userRoles.includes(role)).length !== 0)) return item
          else if(value === 'Update Claim' && Boolean(buttonGuard.updateClaim.filter(role => userRoles.includes(role)).length !== 0)) return item
          else if(value === 'View Claim' && Boolean(buttonGuard.viewClaim.filter(role => userRoles.includes(role)).length !== 0)) return item
          else if(value === 'Close Claim' && Boolean(buttonGuard.closeClaim.filter(role => userRoles.includes(role)).length !== 0)) return item
          else return null
        })}
      </List>
    </Dialog>
  );
}

DialogBoxMenu.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
};

