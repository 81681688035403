import axios from 'axios';
import store from '../App/store';
import { formatDateForAPI as formatDate} from '../../utils/formatDate';
import { setDataChanged } from '../../redux/actions/claimAction';
/* eslint-disable no-alert */

// API expects dates in format DD/MM/YYYY
// UI sets the dates in format YYYY-MM-DD


const dbtrue = "X"
const dbfalse = " "
const dbemptystring = " "
const dbzero = 0;
const dbCalculationStatusDefault = "Active"

export default (async function updateClaim(formValues, history) {  
  let allClaimData = store.getState().claim;
  const userData = store.getState().user.user
  let values = JSON.parse(JSON.stringify(formValues))
  const claimID = allClaimData.updateClaimAPIData.claim.id
  let newValues = new Object();
  let employeeValues = new Object(allClaimData.updateClaimAPIData.employee);
  //Allowed fields for employee
  //employeeValues.id                         (Mandatory)
  //employeeValues.claimId                    (Mandatory)
  //employeeValues.claimNumber                (Mandatory)
  //employeeValues.employeeNumber             (Mandatory)
  //employeeValues.employeeFirstName          (Mandatory)
  //employeeValues.employeeLastName           (Mandatory)
  //employeeValues.positionName               (Null allowed)
  //employeeValues.employmentType             (Mandatory)
  //employeeValues.employmentTypeDescription  (Null allowed)
  //employeeValues.payCategory                (Mandatory)
  //employeeValues.payCategoryDescription     (Null allowed)
  //employeeValues.payType                    (Mandatory)
  //employeeValues.payTypeDescription         (Null allowed)
  //employeeValues.contractType
  //employeeValues.contractTypeDescription    (Null allowed)
  //employeeValues.payCycle
  //employeeValues.payCycleDescription        (Null allowed)
  //employeeValues.payFrequency               (Mandatory)
  //employeeValues.payFrequencyDescription    (Null allowed)
  //employeeValues.irdNumber
  //employeeValues.hireDate
  //employeeValues.hrsPerDay                  (Mandatory)
  //employeeValues.daysPerWeek                (Mandatory)
  //employeeValues.hoursPerWeek               (Mandatory)
  //employeeValues.annualSalary
  //employeeValues.contractHoursPerWeek
  //employeeValues.annualizedHours            (Mandatory)
  //employeeValues.hourlyRate                 (Mandatory)

  employeeValues.claimId      = employeeValues.claimId || claimID; 
  employeeValues.claimNumber  = allClaimData.updateClaimAPIData.claim.claimNumber
  employeeValues.hireDate     = formatDate(employeeValues.hireDate)
  //The below dont are UI only and not fields on the API
  delete employeeValues.hoursPerFortnight
  delete employeeValues.hoursPerMonth
  delete employeeValues.abatementHoursPerDay
  //Handle null values
  employeeValues.contractType = employeeValues.contractType ?? dbemptystring;
  employeeValues.payCycle     = employeeValues.payCycle     ?? dbemptystring;
  if (employeeValues.irdNumber == null) {
    delete employeeValues.irdNumber;
  }
  if (employeeValues.hireDate == null) {
    delete employeeValues.hireDate;
  }
  employeeValues.annualSalary         = employeeValues.annualSalary         ?? dbzero;
  employeeValues.contractHoursPerWeek = employeeValues.contractHoursPerWeek ?? dbzero;
  if (employeeValues.employmentType == null) {
    delete employeeValues.employmentType;
  }
  if (employeeValues.payCategory == null) {
    delete employeeValues.payCategory;
  }
  if (employeeValues.payType == null) {
    delete employeeValues.payType;
  }
  if (employeeValues.payFrequency == null) {
    delete employeeValues.payFrequency;
  }
  employeeValues.hrsPerDay        = employeeValues.hrsPerDay        || dbzero;
  employeeValues.daysPerWeek      = employeeValues.daysPerWeek      || dbzero;
  employeeValues.hoursPerWeek     = employeeValues.hoursPerWeek     || dbzero;
  employeeValues.annualizedHours  = employeeValues.annualizedHours  || dbzero;
  employeeValues.hourlyRate       = employeeValues.hourlyRate       || dbzero;
  

  let claimValues = new Object(allClaimData.updateClaimAPIData.claim);
  //Allowed fields for claim
  //claimValues.id                      (Mandatory)
  //claimValues.claimNumber             (Mandatory)
  //claimValues.serviceNowRequest
  //claimValues.employeeNumber          (Mandatory)
  //claimValues.workRelated             (Mandatory)
  //claimValues.claimType               (Mandatory)
  //claimValues.calculationStatus       (Mandatory)
  //claimValues.monitoredBy
  //claimValues.accStatus               (Mandatory)
  //claimValues.claimStatus
  //claimValues.dateOfInjury            (Mandatory)
  //claimValues.certificateValidTo      (Mandatory)
  //claimValues.numberOfDaysCovered     
  //claimValues.calculationBasis        (Mandatory)
  //claimValues.standDownStart          (Null allowed)
  //claimValues.standDownEnd            (Null allowed)
  //claimValues.weeklyCompensationStart
  //claimValues.stePeriodStart          (Mandatory)
  //claimValues.stePeriodEnd            (Mandatory)
  //claimValues.ltePeriodStart          (Null allowed)
  //claimValues.ltePeriodEnd            (Null allowed)
  //claimValues.newDOSI                 (Mandatory)
  //claimValues.pslTopUp
  //claimValues.worked4Weeks            (Obsolete)
  //claimValues.worked52Weeks           (Mandatory)
  //claimValues.working30Hours          (Mandatory)
  //claimValues.earnings52WAvailable    (Obsolete)
  //claimValues.indexationApplies       (Mandatory)
  //claimValues.eraOptOut
  //claimValues.updateReason
  //claimValues.createdOn               (Null allowed)
  //claimValues.createdBy               (Null allowed)
  //claimValues.standDownHours
  //claimValues.dofiDate                (Mandatory)
  //claimValues.dateRuleGroupChange     (Null allowed)
  //claimValues.continuedClaim
  //claimValues.continuationDate        (Null allowed)
  //claimValues.paymentVariance
  //claimValues.varianceAmount
  
  claimValues.dateOfInjury = formatDate(claimValues.dateOfInjury)
  if(values.certificateValidTo){
    claimValues.certificateValidTo = formatDate(values.certificateValidTo);
  }else{
    claimValues.certificateValidTo = formatDate(claimValues.certificateValidTo);
  }
  claimValues.standDownStart = formatDate(claimValues.standDownStart)
  if (values.standDownEnd){
    claimValues.standDownEnd = formatDate(values.standDownEnd);
  }else {
    claimValues.standDownEnd = formatDate(claimValues.standDownEnd)
  }
  if(values.weeklyCompensationStart){
    claimValues.weeklyCompensationStart = formatDate(values.weeklyCompensationStart)
  }else{
    claimValues.weeklyCompensationStart = formatDate(claimValues.weeklyCompensationStart)
  }
  if(values.stePeriodStart){
    claimValues.stePeriodStart = formatDate(values.stePeriodStart)
  }else{
    claimValues.stePeriodStart = formatDate(claimValues.stePeriodStart)
  }
  if(values.stePeriodEnd){
    claimValues.stePeriodEnd = formatDate(values.stePeriodEnd)
  }else{
    claimValues.stePeriodEnd = formatDate(claimValues.stePeriodEnd)
  }
  if(values.ltePeriodStart){
    claimValues.ltePeriodStart = formatDate(values.ltePeriodStart)
  }else{
    claimValues.ltePeriodStart = formatDate(claimValues.ltePeriodStart)
  }
  if(values.ltePeriodEnd){
    claimValues.ltePeriodEnd = formatDate(values.ltePeriodEnd)
  }else{
    claimValues.ltePeriodEnd = formatDate(claimValues.ltePeriodEnd)
  }
  claimValues.claimStatus = allClaimData.updateClaimFormData.claimStatus;
  claimValues.monitoredBy = allClaimData.updateClaimFormData.monitoredBy ?? dbemptystring;
  claimValues.dofiDate = formatDate(claimValues.dofiDate)
  claimValues.workRelated = values.injury.value == "Work" ?  "W" : "N"
  claimValues.newDOSI = values.newDOSI == "yes" ? "X" : " "
  claimValues.pslTopUp = values.pslTopUp ? "X" : " "
  claimValues.working30Hours = values.working30Hours ? "X" : " "
  claimValues.worked52Weeks = values.worked52Weeks ? dbtrue : dbfalse;
  claimValues.indexationApplies = values.indexation ? "X" :" "
  claimValues.updateReason = values.reasonForUpdate.value
  claimValues.continuedClaim = values.continuedClaim ? "X" : " "
  claimValues.createdBy = userData.idTokenClaims.given_name + " " + userData.idTokenClaims.family_name
  claimValues.createdOn = formatDate(claimValues.createdOn);
  claimValues.paymentVariance = values.paymentVariance == "X" ? "X" : " "
  claimValues.accStatus = values.accStatus?.value || claimValues.accStatus
  if(values.calculationBasis != null){
    if(values.calculationBasis == "STE") claimValues.calculationBasis = "S"
    else if(values.calculationBasis == "LTE") claimValues.calculationBasis = "L"
    else if(values.calculationBasis == "STE & LTE") claimValues.calculationBasis = "B"
  }
  claimValues.minimumIndexation = allClaimData?.claimData?.minimumIndexation || allClaimData?.updateClaimAPIData?.claim?.minimumIndexation;
  if(claimValues.minimumIndexation == null) delete claimValues.minimumIndexation;
  claimValues.maximumIndexation = allClaimData?.claimData?.maximumIndexation || allClaimData?.updateClaimAPIData?.claim?.maximumIndexation;
  if(claimValues.maximumIndexation == null) delete claimValues.maximumIndexation;
  if (claimValues.accStatus == null) {
    delete claimValues.accStatus;
  }
  //Handle nulls
  if(claimValues.standDownStart == null){
    delete claimValues.standDownStart;
  }
  if(claimValues.standDownEnd ==  null){
    delete claimValues.standDownEnd;
  }
  if(claimValues.weeklyCompensationStart == null){
    delete claimValues.weeklyCompensationStart;
  }  
  if(claimValues.dofiDate == null){
    delete claimValues.dofiDate;
  }
  if (claimValues.claimStatus == null) {
    delete claimValues.claimStatus;
  }
  if (claimValues.claimType == null) {
    delete claimValues.claimType;
  }
  if (claimValues.stePeriodStart == null) {
    delete claimValues.stePeriodStart;
  }
  if(claimValues.stePeriodEnd ==  null){
    delete claimValues.stePeriodEnd;
  }
  if(claimValues.ltePeriodStart ==  null){
    delete claimValues.ltePeriodStart;
  }
  if(claimValues.ltePeriodEnd ==  null){
    delete claimValues.ltePeriodEnd;
  }
  if(claimValues.createdOn ==  null){
    delete claimValues.createdOn;
  }
  if(claimValues.dateRuleGroupChange ==  null){
    delete claimValues.dateRuleGroupChange;
  }
  if(claimValues.continuationDate ==  null){
    delete claimValues.continuationDate;
  }
  if(claimValues.calculationBasis ==  null){
    delete claimValues.calculationBasis;
  }
  if(claimValues.workRelated ==  null){
    delete claimValues.workRelated;
  }
  if(claimValues.dateOfInjury ==  null){
    delete claimValues.dateOfInjury;
  }
  if(claimValues.certificateValidTo ==  null){
    delete claimValues.certificateValidTo;
  }
  claimValues.calculationStatus   = claimValues.calculationStatus || dbCalculationStatusDefault;
  claimValues.numberOfDaysCovered = claimValues.numberOfDaysCovered || dbzero;
  claimValues.standDownHours      = claimValues.standDownHours || dbzero;
  delete claimValues.changedOn;
  delete claimValues.changedBy;
  claimValues.varianceAmount      = values.varianceAmount || claimValues.varianceAmount || dbzero;
  claimValues.serviceNowRequest   = values.serviceNowRequest || claimValues.serviceNowRequest || claimValues.serviceNowRequest || dbemptystring;
  delete claimValues.serviceNowRequest
  //Delete obsolete fields
  delete claimValues.worked4Weeks;
  delete claimValues.earnings52WAvailable;
  //Migrated claim is not relevant for UI update
  delete claimValues.migratedClaim;
  delete claimValues.indexationExemptionDates;
  claimValues.earningsLte = values.earning52weeks || 0
  claimValues.otherEarningsLte = values.otherEarnings52Weeks || 0
  claimValues.unpaidDaysLte = values.unpaidDays52Weeks || 0
  claimValues.unpaidDaysPreviousEmpLte = values.unpaidDays52WeeksPreviousEmployer || 0
  claimValues.accDaysLte = values.accDays52Weeks || 0
  claimValues.overwriteDivisorLte = values.overwriteDivisor52Weeks || 0
  claimValues.rateFullLte = values.lteRate100 || 0

  newValues.employee = employeeValues
  newValues.totals = values.payperiodtotals
  newValues.payments = values.payments
  newValues.claim = claimValues
  newValues.notes = values.reasonNotes
  if(newValues.notes == null || newValues.notes === undefined){
    delete newValues.notes
  }
  let indexationExemptionDates = [];
  if(values.indexationExemptionDates){
    indexationExemptionDates = values.indexationExemptionDates.filter((row) => {
                                                        if(row.fromDate == null || row.toDate == null) return false;
                                                        return true;
                                                    }).map((row) => {
                                                        row.fromDate = formatDate(row.fromDate);
                                                        row.toDate = formatDate(row.toDate);
                                                        row.claimNumber = values.claimNumber;
                                                        return row;
                                                      })
    if(indexationExemptionDates.length > 0){
      newValues.indexationExemptionDates = indexationExemptionDates;
    }
  }

  for(let i=0;i<newValues.payments.length;i++){
    //Allowed fields for payments
    //newValues.payments[i].id                            (Mandatory)
    //newValues.payments[i].claimId                       (Mandatory)
    //newValues.payments[i].netWeeklyCompensation         (Mandatory)
    //newValues.payments[i].hoursAbsent
    //newValues.payments[i].claimNumber                   (Mandatory)
    //newValues.payments[i].employeeNumber
    //newValues.payments[i].claimType
    //newValues.payments[i].startOfPayrollPeriod          (Mandatory)
    //newValues.payments[i].endOfPayrollPeriod            (Mandatory)
    //newValues.payments[i].numberOfPayrollPeriod
    //newValues.payments[i].startOfWeek                   (Mandatory)
    //newValues.payments[i].endOfWeek                     (Mandatory)
    //newValues.payments[i].paymentType                   (Mandatory)
    //newValues.payments[i].earnings                      (Mandatory)
    //newValues.payments[i].otherEarnings
    //newValues.payments[i].unpaidDays
    //newValues.payments[i].unpaidDaysPreviousEmp
    //newValues.payments[i].accDays
    //newValues.payments[i].divisor                       (Null allowed)
    //newValues.payments[i].overwriteDivisor              (Null allowed)
    //newValues.payments[i].employmentWeeks               (Mandatory)
    //newValues.payments[i].rate                          (Mandatory)
    //newValues.payments[i].rateFull                      (Mandatory)
    //newValues.payments[i].ordinaryPay                   (Mandatory)
    //newValues.payments[i].extraEarning
    //newValues.payments[i].dateOrdinaryPay               (Mandatory)
    //newValues.payments[i].abatementHourlyRate
    //newValues.payments[i].actualHours
    //newValues.payments[i].additionalPayments
    //newValues.payments[i].rate20
    //newValues.payments[i].workingDays
    //newValues.payments[i].accWeeklyCompensation         (Mandatory)
    //newValues.payments[i].abatedACCWeeklyCompensation
    //newValues.payments[i].calculationStatus             (Mandatory)
    //newValues.payments[i].postIncapacityEarnings
    //newValues.payments[i].notes
    //newValues.payments[i].updateReason

    delete newValues.payments[i].tableData
    newValues.payments[i].startOfPayrollPeriod  = formatDate(newValues.payments[i].startOfPayrollPeriod);
    newValues.payments[i].endOfPayrollPeriod    = formatDate(newValues.payments[i].endOfPayrollPeriod);
    newValues.payments[i].startOfWeek           = formatDate(newValues.payments[i].startOfWeek);
    newValues.payments[i].endOfWeek             = formatDate(newValues.payments[i].endOfWeek);
    
    //Handle nulls
    newValues.payments[i].claimId         = newValues.payments[i].claimId || claimID; 
    newValues.payments[i].claimNumber     = newValues.payments[i].claimNumber || claimValues.claimNumber;
    newValues.payments[i].employeeNumber  = newValues.payments[i].employeeNumber || employeeValues.employeeNumber;
    newValues.payments[i].claimType       = newValues.payments[i].claimType || claimValues.claimType;
    //cannot default it and it is not nullable, hence delete any of the following that are null
    if (newValues.payments[i].dateOrdinaryPay == null) {
      delete newValues.payments[i].dateOrdinaryPay;
    }else{
      newValues.payments[i].dateOrdinaryPay       = formatDate(newValues.payments[i].dateOrdinaryPay);
    }
    if (newValues.payments[i].updateReason == null || newValues.payments[i].updateReason === dbemptystring) {
      delete newValues.payments[i].updateReason;
    }
    //weeks is UI only and not present on the table
    delete newValues.payments[i].weeks
    newValues.payments[i].earnings                    = newValues.payments[i].earnings || dbzero;
    newValues.payments[i].extraEarning                = newValues.payments[i].extraEarning || dbzero;
    newValues.payments[i].otherEarnings               = newValues.payments[i].otherEarnings || dbzero;
    newValues.payments[i].unpaidDays                  = newValues.payments[i].unpaidDays || dbzero;
    newValues.payments[i].unpaidDaysPreviousEmp       = newValues.payments[i].unpaidDaysPreviousEmp || dbzero;
    newValues.payments[i].accDays                     = newValues.payments[i].accDays || dbzero;
    newValues.payments[i].employmentWeeks             = newValues.payments[i].employmentWeeks || dbzero;
    newValues.payments[i].abatementHourlyRate         = newValues.payments[i].abatementHourlyRate || dbzero;
    newValues.payments[i].actualHours                 = newValues.payments[i].actualHours || dbzero;
    newValues.payments[i].additionalPayments          = newValues.payments[i].additionalPayments || dbzero;
    newValues.payments[i].ordinaryPay                 = newValues.payments[i].ordinaryPay || dbzero;
    newValues.payments[i].rate                        = newValues.payments[i].rate || dbzero;
    newValues.payments[i].rateFull                    = newValues.payments[i].rateFull || dbzero;
    newValues.payments[i].rate20                      = newValues.payments[i].rate20 || dbzero;
    newValues.payments[i].workingDays                 = newValues.payments[i].workingDays || dbzero;
    newValues.payments[i].accWeeklyCompensation       = newValues.payments[i].accWeeklyCompensation || dbzero;
    newValues.payments[i].abatedACCWeeklyCompensation = newValues.payments[i].abatedACCWeeklyCompensation || dbzero;
    newValues.payments[i].postIncapacityEarnings      = newValues.payments[i].postIncapacityEarnings || dbzero;
    delete newValues.payments[i].notes;
    newValues.payments[i].calculationStatus           = newValues.payments[i].calculationStatus || dbCalculationStatusDefault;

    newValues.payments[i].numberOfPayrollPeriod = newValues.payments[i].numberOfPayrollPeriod || 0
    newValues.payments[i].netWeeklyCompensation = newValues.payments[i].netWeeklyCompensation || 0
    //Delete obsolete fields
    delete newValues.payments[i].rosterHours    
    delete newValues.payments[i].hoursAbsent
    
  }

  for(let i=0;i<newValues.totals.length;i++){
    //Allowed fields for totals
    //newValues.totals[i].id                    (Mandatory)
    //newValues.totals[i].claimId               (Mandatory)
    //newValues.totals[i].claimNumber           (Mandatory)
    //newValues.totals[i].employeeNumber        
    //newValues.totals[i].claimType 
    //newValues.totals[i].startOfPayrollPeriod  (Mandatory)
    //newValues.totals[i].endOfPayrollPeriod    (Mandatory)
    //newValues.totals[i].accCompensation       (Mandatory)
    //newValues.totals[i].topUp
    //newValues.totals[i].hoursAbsent
    //newValues.totals[i].actualHours
    //newValues.totals[i].additionalPayments    (Null allowed)
    //newValues.totals[i].postIncapacityEarnings(Null allowed)
    //newValues.totals[i].abatedACCCompensation
    //newValues.totals[i].payrollCode           (Mandatory)
    //newValues.totals[i].payrollCodeTopUp
    //newValues.totals[i].netCompensation       (Mandatory)
    //newValues.totals[i].pslOpeningBalance
    //newValues.totals[i].datePSLBalance
    //newValues.totals[i].pslClosingBalance
    //newValues.totals[i].pslHours
    newValues.totals[i].startOfPayrollPeriod  = formatDate(newValues.totals[i].startOfPayrollPeriod);
    newValues.totals[i].endOfPayrollPeriod    = formatDate(newValues.totals[i].endOfPayrollPeriod);
    newValues.totals[i].pslHours = newValues.totals[i].pslHours || 0
    newValues.totals[i].pslClosingBalance = newValues.totals[i].pslClosingBalance || 0
    newValues.totals[i].actualHours = newValues.totals[i].actualHours || 0
    delete newValues.totals[i].tableData
    delete newValues.totals[i].hoursAbsent
    //Handle nulls
    newValues.totals[i].claimId                 = newValues.totals[i].claimId || claimID; 
    newValues.totals[i].claimNumber             = newValues.totals[i].claimNumber || claimValues.claimNumber;
    newValues.totals[i].employeeNumber          = newValues.totals[i].employeeNumber || employeeValues.employeeNumber;
    newValues.totals[i].claimType               = newValues.totals[i].claimType || claimValues.claimType;
    newValues.totals[i].pslOpeningBalance       = newValues.totals[i].pslOpeningBalance || dbzero;
    newValues.totals[i].accCompensation         = newValues.totals[i].accCompensation || dbzero;
    newValues.totals[i].topUp                   = newValues.totals[i].topUp || dbzero;
    newValues.totals[i].additionalPayments      = newValues.totals[i].additionalPayments || 0
    newValues.totals[i].postIncapacityEarnings  = newValues.totals[i].postIncapacityEarnings || 0
    newValues.totals[i].netCompensation         = newValues.totals[i].netCompensation || 0
    newValues.totals[i].abatedACCCompensation   = newValues.totals[i].abatedACCCompensation || 0
    
    if (newValues.totals[i].payrollCode == null) {
      delete newValues.totals[i].payrollCode;
    }
    if (newValues.totals[i].payrollCodeTopUp == null) {
      delete newValues.totals[i].payrollCodeTopUp;
    } 
    if (newValues.totals[i].datePSLBalance == null) {
      delete newValues.totals[i].datePSLBalance;
    }else{
      newValues.totals[i].datePSLBalance = formatDate(newValues.totals[i].datePSLBalance);
    }
    if(newValues.totals[i].updateReason == null || newValues.totals[i].updateReason === dbemptystring){
      delete newValues.totals[i].updateReason;
    } 
  }

  // window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`);
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_BASE_URL + `/claim/${claimID}`, newValues)
        .then(x => {
          resolve(x.data)
          store.dispatch(setDataChanged(false));
          alert(`Claim ${x.data.claimNumber} Edited Successfully`)
          //61439 - Remain on the calculation screen after saving
          ///history.push({pathname: "/tables/data_table"})
          window.scrollTo(0,0);
          localStorage.removeItem("weekData");
        })
        .catch(x => reject(x.data))        
  })
});
