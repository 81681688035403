import React, { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import WrappedRoutes from './WrappedRoutes';
import { loginRequest } from '../../../authConfig';
import { setAuthUserAction } from '../../../redux/actions/authActions'
import store from '../store';
import { useHistory } from "react-router-dom";

const Router = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [user, setUser] = useState(null);
  const history = useHistory()

  useEffect( async () => {
    if (account && inProgress === "none" && !user) {
      let MSADResponseData = {}
      try{
          MSADResponseData = await instance.acquireTokenSilent({
              scopes: loginRequest.scopes,
              account: account
          });
      } catch(error){
          if (error instanceof InteractionRequiredAuthError) {
              if (account && inProgress === "none") {
                  MSADResponseData = await instance.acquireTokenPopup({
                    scopes: loginRequest.scopes,
                  })
              }
          }
      }
      setUser(MSADResponseData)
      store.dispatch(setAuthUserAction(MSADResponseData))
      localStorage.setItem('ACCROLES',MSADResponseData.idTokenClaims.roles)
      history.push({pathname: "/tables/data_table"})
    }
  }, [account, inProgress, instance]);

  return (
    <MsalAuthenticationTemplate authenticationRequest={loginRequest} interactionType={InteractionType.Redirect} >
      <MainWrapper>
        <main>
          <Switch>
            <Route exact path="/tables/data_table" component={WrappedRoutes} />
            <Route path="/404" component={NotFound404} />
            <Route path="/" component={WrappedRoutes} />
          </Switch>
        </main>
      </MainWrapper>
    </MsalAuthenticationTemplate>
  );

}
export default Router;
