import { useHistory } from "react-router-dom";
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component , ...rest }) => {
    const history = useHistory()    
    const userRoles = localStorage.getItem('ACCROLES') ? localStorage.getItem('ACCROLES') : []
    const pushedRoles = (history.location.state && history.location.state.roles) ? history.location.state.roles : []
    const isValid = rest.roles.filter(role => userRoles.includes(role)).length || pushedRoles.filter(role => userRoles.includes(role)).length

    return (
        <Route {...rest} render={
            props => {
                if (isValid) return <Component {...rest} {...props} />
                return <Redirect to={"/"} />
            }
        } />
    )
}
