import axios from 'axios';
import store from '../App/store';
import { formatDateForAPI as formatDate} from '../../utils/formatDate';
import { setDataChanged, setApiCalled } from '../../redux/actions/claimAction';
/* eslint-disable no-alert */

// API expects dates in format DD/MM/YYYY
// UI sets the dates in format YYYY-MM-DD


export default (async function showResults(formValues, history) {
  let APIClaimData = store.getState().claim.claimData
  let values = JSON.parse(JSON.stringify(formValues))
  let newValues = new Object();
  let employeeValues = new Object();
  let claimValues = new Object();
  const empData =  store.getState().employeeDetails.employee;
  let indexationExemptionDates = []; 
  if(values.indexationExemptionDates){
    indexationExemptionDates = values.indexationExemptionDates.filter((row) => {
                                                        if(row.fromDate == null || row.toDate == null) return false;
                                                        return true;
                                                    }).map((row) => {
                                                        row.fromDate = formatDate(row.fromDate);
                                                        row.toDate = formatDate(row.toDate);
                                                        row.claimNumber = values.claimNumber;
                                                        return row;
                                                      })
  }  
  
  claimValues.claimNumber = values.claimNumber || "0"
  claimValues.serviceNowRequest = values.serviceNowRequest
  claimValues.employeeNumber = values.employeeNumber || ""
  claimValues.workRelated = values.injury.value == "Non-Work" ?  "N" : "W"
  claimValues.claimType = values.claimType?.value || ""
  // claimValues.calculationStatus = values.
  // claimValues.monitoredBy = values.
  claimValues.accStatus = values.accStatus?.value
  if(claimValues.accStatus == null) delete claimValues.accStatus;
  // claimValues.claimStatus = values.
  claimValues.dateOfInjury = formatDate(values.dateOfInjury) || ""
  claimValues.certificateValidTo = formatDate(values.certificateValidTo) || ""
  claimValues.numberOfDaysCovered = values.days || ""
  if(values.calculationBasis == "STE") claimValues.calculationBasis = "S"
  else if(values.calculationBasis == "LTE") claimValues.calculationBasis = "L"
  else if(values.calculationBasis == "STE & LTE") claimValues.calculationBasis = "B"

  claimValues.standDownStart = APIClaimData.standDownStart ? formatDate(APIClaimData.standDownStart.substring(0,10)) : null
  claimValues.standDownEnd = APIClaimData.standDownEnd ? formatDate(APIClaimData.standDownEnd.substring(0,10)) : null
  claimValues.weeklyCompensationStart = APIClaimData.weeklyCompensationStart ? formatDate(APIClaimData.weeklyCompensationStart.substring(0,10)) : null
  claimValues.stePeriodStart = APIClaimData.stePeriodStart ? formatDate(APIClaimData.stePeriodStart.substring(0,10)) : null
  claimValues.stePeriodEnd = APIClaimData.stePeriodEnd ? formatDate(APIClaimData.stePeriodEnd.substring(0,10)) : null
  claimValues.ltePeriodStart = APIClaimData.ltePeriodStart ? formatDate(APIClaimData.ltePeriodStart.substring(0,10)) : null
  claimValues.ltePeriodEnd = APIClaimData.ltePeriodEnd ? formatDate(APIClaimData.ltePeriodEnd.substring(0,10)) : null
  
  claimValues.newDOSI = values.newDOSI == "yes" ? "X" : " "
  claimValues.pslTopUp = values.pslTopUp ? "X" : " "
  
  claimValues.working30Hours = values.working30Hours ? "X" : " "
  claimValues.worked52Weeks = values.yearlyEarnings ? "X" : " "
  claimValues.indexationApplies = values.indexation ? "X" :" "
  claimValues.eraOptOut = values.optOutClaim.value
  claimValues.standDownHours = values.standDownHours || 0
  claimValues.dofiDate = formatDate(values.dofiDate) || null
  claimValues.continuedClaim = values.continueClaim ? "X" : " "
  claimValues.continuationDate = formatDate(values.dateContinue) || null
  claimValues.minimumIndexation = APIClaimData.minimumIndexation;
  claimValues.maximumIndexation = APIClaimData.maximumIndexation
  claimValues.earningsLte = values.earning52weeks || 0
  claimValues.otherEarningsLte = values.otherEarnings52Weeks || 0
  claimValues.unpaidDaysLte = values.unpaidDays52Weeks || 0
  claimValues.unpaidDaysPreviousEmpLte = values.unpaidDays52WeeksPreviousEmployer || 0
  claimValues.accDaysLte = values.accDays52Weeks || 0
  claimValues.overwriteDivisorLte = values.overwriteDivisor52Weeks || 0
  claimValues.rateFullLte = values.lteRate100 || 0
  if(claimValues.workRelated === "W"){
    claimValues.managedByACC = values.managedByACC.value || ""
    claimValues.managedFromDate = formatDate(values.managedFromDate) || null
  }
  employeeValues.employeeNumber = values.employeeNumber || 0
  employeeValues.employeeFirstName = values.firstName || ""
  employeeValues.employeeLastName = values.lastName  || ""
  delete employeeValues.positionName
  employeeValues.employmentType = empData.employmentType
  employeeValues.employmentTypeDescription = empData.employmentTypeDescription || 0
  employeeValues.payCategory = empData.payCategory || 0
  employeeValues.payCategoryDescription = empData.payCategoryDescription || 0
  employeeValues.payType = empData.paymentType || 0
  employeeValues.payTypeDescription = empData.paymentTypeDescription || 0
  employeeValues.contractType = empData.contractType || 0
  employeeValues.contractTypeDescription = empData.contractTypeDescription || 0
  employeeValues.payCycle = empData.payCycle || 0
  employeeValues.payCycleDescription = empData.payCycleDescription || 0
  employeeValues.payFrequency = empData.payFrequency || 0
  employeeValues.payFrequencyDescription = empData.payFrequencyDescription || 0
  employeeValues.irdNumber = empData.irdNumber.toString() || "0"
  employeeValues.hireDate = formatDate(empData.hireDate)
  employeeValues.hrsPerDay = values.hoursPerDay || 0
  employeeValues.daysPerWeek = values.daysPerWeek.value || 0
  employeeValues.hoursPerWeek = values.weeklyHours || 0
  employeeValues.annualSalary = empData.annualSalary || 0
  employeeValues.contractHoursPerWeek = empData.contractHoursPerWeek || 0
  employeeValues.annualizedHours = values.annualizedHours || 0
  employeeValues.hourlyRate = values.hourlyRate || 0


  newValues.employee = employeeValues
  newValues.totals = values.totals
  newValues.payments = values.payments
  newValues.claim = claimValues
  if(indexationExemptionDates.length > 0){
    newValues.indexationExemptionDates = indexationExemptionDates;
  }

  for(let i=0;i<newValues.payments.length;i++){
    newValues.payments[i].abatementHourlyRate = values.hrsRateForTopup
    delete newValues.payments[i].tableData
    delete newValues.payments[i].weeks
    newValues.payments[i].startOfPayrollPeriod  = formatDate(newValues.payments[i].startOfPayrollPeriod);
    newValues.payments[i].endOfPayrollPeriod    = formatDate(newValues.payments[i].endOfPayrollPeriod);
    newValues.payments[i].startOfWeek           = formatDate(newValues.payments[i].startOfWeek);
    newValues.payments[i].endOfWeek             = formatDate(newValues.payments[i].endOfWeek);
    if (newValues.payments[i].dateOrdinaryPay == null) {
      delete newValues.payments[i].dateOrdinaryPay;
    }else{
      newValues.payments[i].dateOrdinaryPay     = formatDate(newValues.payments[i].dateOrdinaryPay);
    }
  }

  for(let i=0;i<newValues.totals.length;i++){
    newValues.totals[i].pslHours = newValues.totals[i].pslHours || 0
    newValues.totals[i].pslClosingBalance = newValues.totals[i].pslClosingBalance || 0
    delete newValues.totals[i].tableData
    newValues.totals[i].startOfPayrollPeriod  = formatDate(newValues.totals[i].startOfPayrollPeriod);
    newValues.totals[i].endOfPayrollPeriod    = formatDate(newValues.totals[i].endOfPayrollPeriod);
    if (newValues.totals[i].datePSLBalance == null) {
      delete newValues.totals[i].datePSLBalance;
    }else{
      newValues.totals[i].datePSLBalance      = formatDate(newValues.totals[i].datePSLBalance);
    }
  }

  console.log(newValues)
  // return

  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API_BASE_URL + `/claim/create`, newValues)
        .then(x => {
          resolve(x.data)
          store.dispatch(setDataChanged(false));
          alert(`Claim ${x.data.claimNumber} Created Successfully`);
          store.dispatch(setApiCalled(true));
          //61439 - Remain on the calculation screen after saving
          //history.push({pathname: "/tables/data_table"})
          window.scrollTo(0,0);
        })
        .catch(x => reject(x.data))        
  })
});
