import React from 'react';
import {  FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';

export const InputFields=(props)=>{
    const {type,name,placeholder,title,onChangeInput}=props;

    return(
        <FormGroup>
            <Label for="input fields">{title}</Label>
            <Input type={type} name={name} id="exampleEmail" placeholder={placeholder} onChange={onChangeInput}/>
          </FormGroup>
    )
}

InputFields.propTypes = {
    color: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
  };