export const appRoles = {
  ACCSpecialityAdmin: "ACC.SpecialityAdmin",
  ACCSpecialityNZ: "ACC.SpecialityNZ",
  ACCPayrollNZ: "ACC.PayrollNZ",
  ACCHROpsSupport: "ACC.HROpsSupport",
  ACCViewer: "ACC.Viewer",
  ACCTechSupport: "ACC.TechSupport",
};

function getAllRoles(appRoles) {
  var res = [];
  for (let roles in appRoles) res.push(appRoles[roles]);
  return res;
}

export const pageConf = {
  dashboardRoute: {
    roles: getAllRoles(appRoles),
  },
  createClaimRoute: {
    roles: [appRoles.ACCSpecialityNZ, appRoles.ACCSpecialityAdmin],
  },
  maintainFactorRoute: {
    roles: [appRoles.ACCSpecialityAdmin],
  },
  ACCInvoiceRoute: {
    roles: [appRoles.ACCSpecialityAdmin, appRoles.ACCSpecialityNZ],
  },
  reportsRoute: {
    roles: [appRoles.ACCSpecialityNZ, appRoles.ACCSpecialityAdmin],
  },
  dataDownloadRoute: {
    roles: [appRoles.ACCSpecialityNZ, appRoles.ACCSpecialityAdmin],
  },
  documentationRoute: {
    roles: [
      appRoles.ACCPayrollNZ,
      appRoles.ACCSpecialityAdmin,
      appRoles.ACCHROpsSupport,
      appRoles.ACCSpecialityNZ,
    ],
  },
  reCalculationRoute: {
    roles: [
      appRoles.ACCSpecialityAdmin,
    ],
  },
  _404Route: {
    roles: getAllRoles(appRoles),
  },
};
