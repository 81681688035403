import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import FileUploadDefault from '../../Form/ReduxForm/FileUpload/components/FileUploadDefault';
import showResults from '../../Form/Show';
import ProgressBar  from '../../../shared/components/ProgressBar';
const ModalPopup = (props) => {
  const {
    buttonLabel,
    className,
    toggle,
    modal,
    inputDropFile,
    uploadFile,
    isLoading
  } = props;


  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={className} animation={false}>
        <ModalHeader toggle={toggle}>Upload CSV File Here</ModalHeader>
        <ModalBody>
          <FileUploadDefault onSubmit={showResults} pageName="modal" onChangeFile={(files)=>inputDropFile(files)} />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={isLoading?null:toggle}>{isLoading?<ProgressBar size={"10"} />:'Cancel'}</Button>
          <Button color="primary" onClick={isLoading?null:uploadFile}>{isLoading?<ProgressBar size={"10"} />:'Upload'}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

Modal.propTypes = {
    isOpen:  PropTypes.bool,
    autoFocus: PropTypes.bool,
    centered: PropTypes.bool,
    size: PropTypes.string,
    toggle:  PropTypes.func,
    role: PropTypes.string, 
    labelledBy: PropTypes.string,
    keyboard: PropTypes.bool,
    
    scrollable: PropTypes.bool,
    external: PropTypes.node,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    className: PropTypes.string,
    wrapClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    backdropClassName: PropTypes.string,
    contentClassName: PropTypes.string,
   
    cssModule: PropTypes.object,
    

  }

export default ModalPopup;