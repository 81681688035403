import * as Constant from '../../utils/constant'

export const getDownloadDataRequest=()=>({
    type:Constant.GET_DATATABLE_REQUEST,
})

export const getDownloadDataSuccess=(response)=>({
    type:Constant.GET_DATATABLE_SUCCESS,
    payload:response
})

export const getDownloadDataFailure=(error)=>({
    type:Constant.GET_DATATABLE_FAILURE,
    payload:error
})