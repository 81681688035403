import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import {
  Col, Card, Row, CardBody, Alert,
} from 'reactstrap';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import WizardFormThree from './WizardFormThree';
import WizardFormResults from './WizardFormResults';
import store from '../../../../App/store';
import { setClaimDataAction, createClaimSnapshot } from '../../../../../redux/actions/claimAction'
import { validReasonToCall } from './UpdateMatrix'
import { Prompt } from "react-router-dom";

const WizardForm = ({ onSubmit, onCalcClaim, onUpdate }) => {
  const allClaimData = useSelector((state)=>state.claim)  
  const [isDataChanged, setIsDataChanged] = useState(false);  
  const mode = allClaimData.mode
  const [page, setPage] = useState(1);

  const nextPage = (e) => {
    if(mode === "VIEW") setPage(4)
    else setPage(page + 1);
  };

  const handleSubmit = (e) => {
    if(mode === "CREATE") createClaim(e)
    else if(mode === "UPDATE") updateClaim(e)
  }

  const calcClaim = async (e) => {   
    store.dispatch(createClaimSnapshot(e)) 
    nextPage();
    if(e.reasonForUpdate && !validReasonToCall.includes(e.reasonForUpdate.value)) {
      return
    }
    
    try{
      const APIClaimData = await onCalcClaim(e);
      store.dispatch(setClaimDataAction(APIClaimData))
    }catch(e){
      setPage(page);
    }
  }

  const createClaim = (e) => {
    e.payments = allClaimData.claimData.payments
    e.totals = allClaimData.claimData.totals
    onSubmit(e)
  }

  const updateClaim = (e) => {   
    if(e.reasonForUpdate && validReasonToCall.includes(e.reasonForUpdate.value)){
      e.payments = allClaimData.claimData.payments
      e.payperiodtotals = allClaimData.claimData.totals
    }  else {
        e.payments = allClaimData.updateClaimAPIData.payments
        e.payperiodtotals = allClaimData.updateClaimAPIData.payperiodtotals
    }     
    onUpdate(e)
  }

  const previousPage = () => {
    setPage(page - 1);
  };

  store.subscribe(()=>{
    const hasDataChanged = store.getState()?.claim?.dataChanged;
    if(hasDataChanged) {setIsDataChanged(true);}
    else {setIsDataChanged(false);}
  })

  return (
    <Row>
      <h3 className="page-subhead subhead">ACCelerator - New Claim</h3>
      <div>
        <Prompt
            when={isDataChanged}
            message={location => `Any changes made will be lost. Are you sure you want to navigate away from this step?`} // ${location.pathname}
        />
      </div>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="wizard">
            <div className="wizard__steps">
              <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}><p>Step 1</p></div>
              <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}><p>Step 2</p></div>
              <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}><p>Step 3</p></div>
              <div className={`wizard__step${page === 4 ? ' wizard__step--active' : ''}`}><p>Calculation</p></div>
            </div>
            <div className="wizard__form-wrapper">
              {page === 1 && <WizardFormOne  onSubmit={nextPage} />}
              {page === 2
              && (
              <WizardFormTwo
                previousPage={previousPage}
                onSubmit={nextPage}                
              />
              )}
              {page === 3
              && (
              <WizardFormThree
                previousPage={previousPage}                
                onSubmit={calcClaim}                
              />
              )}
              {page === 4
              && (
              <WizardFormResults
                previousPage={previousPage}
                onSubmit={handleSubmit}
              />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

WizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default WizardForm;
