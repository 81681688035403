import { GET_DATATABLE_REQUEST, GET_DATATABLE_SUCCESS, GET_DATATABLE_FAILURE } from '../../utils/constant';

export const initialState = {
    isLoading: false,
    downloadData: null,
    errorMessage: ''
}


export const getDataDownloadtReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATATABLE_REQUEST:
            return { ...state, isLoading: true }
        case GET_DATATABLE_SUCCESS:
            return { ...state, isLoading: false, downloadData: action.payload }
        case GET_DATATABLE_FAILURE:
            return { ...state, isLoading: false, downloadData: action.payload }
        default:
            return state;
    }

}