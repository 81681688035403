import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import MatTable from '../../../Tables/MaterialTable/components/MatTable';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { getInvoiceListAsync } from '../../../../redux/actionCreator/getListInvoiceActionCreator';
import { createMultiInvoiceListAsync } from '../../../../redux/actionCreator/createMultiInvoiceActionCreator';
import { validateInvoiceListAsync } from '../../../../redux/actionCreator/validateInvoiceActionCreator';
import * as Api from '../../../../utils/api/base/apiUrl';
import ModalPopup from '../../../Layout/components/modalPopup';
import * as XLSX from 'xlsx';
import moment from 'moment';
import ProgressBar from '../../../../shared/components/ProgressBar';
import AlertDialog from '../../../../shared/components/DialogBox';
const rows = [
  {
    id: 'employerId', disablePadding: false, label: 'Employer ID',
  },
  {
    id: 'paymtDate', disablePadding: false, label: 'Paymt. Date',
  },
  {
    id: 'paymtStart', disablePadding: false, label: 'Paymt. Start',
  },
  {
    id: 'paymtEnd', disablePadding: false, label: 'Paymt. End',
  },
  {
    id: 'paymtTot', disablePadding: false, label: 'Paymt. Tot.',
  },
  {
    id: 'firstName', disablePadding: false, label: 'First Name',
  },
  {
    id: 'lastName', disablePadding: false, label: 'Last Name',
  },
  {
    id: 'ird', disablePadding: false, label: 'IRD #',
  },
  {
    id: 'claimId', disablePadding: false, label: 'Claim ID',
  },
  {
    id: 'reimbStart', disablePadding: false, label: 'Reimb. Start',
  },
  {
    id: 'reimbEnd', disablePadding: false, label: 'Reimb. End',
  },
  {
    id: 'noOfDays', disablePadding: false, label: 'No. of days',
  },
  {
    id: 'reimbAmt.', disablePadding: false, label: 'Reimb. Amt.',
  },
  {
    id: 'accidentDt', disablePadding: false, label: 'Accident Dt',
  },
  {
    id: 'eligibEnd', disablePadding: false, label: 'Eligib. End',
  },
  {
    id: 'eraStatus', disablePadding: false, label: 'ERA Status',
  },
  {
    id: 'workRel', disablePadding: false, label: 'Work Rel.',
  }
];

// const { t } = useTranslation('common');

class AccInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      claimNumber: '',
      dialogConfirm: false,
      firstName: '',
      lastName: '',
      discrepancy: false,
      headingInvoice: [],
      dataInvoice: [],
      radioInvoiceChecked:0,
      accInvoiceSelectedData:{}
    }
  }

  componentDidMount() {
    this.setState({ dataInvoice: [] })
  }
  componentDidUpdate(prevProps) {
    const invoiceData = this.props.invoiceData;
    const createInvoiceData = this.props.createInvoiceValue;
    const validateInvoiceData = this.props.validateInvoiceData;
    if (invoiceData && invoiceData != prevProps.invoiceData) {
      if (invoiceData.status && invoiceData.statusText) {
        this.setState({ dataInvoice: [] }, () => {
          alert(invoiceData.statusText)
        })
      } else {
        const checkDataLength = invoiceData !== null ? invoiceData.length > 0 ? invoiceData : (invoiceData.invoices && invoiceData.invoices.length) > 0 ? invoiceData.invoices : invoiceData.discrepancies : null;
        this.setState({ dataInvoice: checkDataLength || []})
      }
    } else if (createInvoiceData && createInvoiceData != prevProps.createInvoiceValue) {
      if (createInvoiceData.length > 0) {
        this.setState({ dialogConfirm: true, modal: false })
      } else if (createInvoiceData.invoices && createInvoiceData.invoiceDiscrepancies) {
          alert("File has been saved successfully!");
      } else if (createInvoiceData.invoices && createInvoiceData.discrepancies) {
        this.setState({ modal: false, dataInvoice: createInvoiceData.invoices }, () => {
          alert("File has been updated successfully!");
        })
      } else if (createInvoiceData.status && createInvoiceData.statusText) {
        this.setState({ dataInvoice: [], modal: false }, () => {
          alert(createInvoiceData.statusText)
        })
      }
    } else if (validateInvoiceData && validateInvoiceData != prevProps.validateInvoiceData) {
      if (validateInvoiceData.status && validateInvoiceData.statusText) {
        this.setState({ dataInvoice: [] }, () => {
          alert(validateInvoiceData.statusText)
        })
      }
      else if (validateInvoiceData.invoices && validateInvoiceData.invoiceDiscrepancies && validateInvoiceData.invoices.length > 0) {
        this.setState({ dataInvoice: validateInvoiceData.invoices }, () => {
             alert("Data has been revalidated successfully!")
          })
        }
    }
  }

  toggle = () => this.setState({ modal: !this.state.modal });

  onChangeInputData = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name == "Claim Number") {
      this.setState({ claimNumber: value });
    } else if (name == "First Name") {
      this.setState({ firstName: value });
    } else if (name == "Last Name") {
      this.setState({ lastName: value });
    }

  }

  clickToSearch = () => {
    const { modal, firstName, lastName, claimNumber, discrepancy } = this.state;
    if (firstName == "" && lastName == "" && claimNumber == "" && discrepancy == false) {
      this.props.getInvoiceListAsync(`${Api.getInvoiceListApi}`);
    } else {
      this.props.getInvoiceListAsync(`${Api.getInvoiceListApi}?claimNumber=${claimNumber}&firstName=${firstName}&lastName=${lastName}&discrepancy=${discrepancy==false?'':discrepancy}`);
    }
  }

  clickToCheckToggle = (event) => {
    const checkToggleValue = event.target.value;
    this.setState({ discrepancy: !this.state.discrepancy,dataInvoice: [] })
  }

  onDrop = (file) => {
    if (file.length == 0) {
      this.setState({ createInvoiceData: [] })
      alert("Please select a file!")
    } else {
      this.handleFileUpload(file[0])
    }

  };

  processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j].replace(/\s/g, '_')] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map(c => ({
      name: c,
      selector: c,
    }));

    const invoiceArr = [];
    for (const key in list) {
      if (list.hasOwnProperty(key)) {
        invoiceArr.push(
          {
            claimNumber: list[key].Employee_Claim_Id,
            employerId: list[key].Employer_Id,
            paymentDate: moment(list[key].Payment_Date).format("DD/MM/YYYY"),
            paymentStartDate: moment(list[key].Payment_start_date).format("DD/MM/YYYY"),
            paymentEndDate: moment(list[key].Payment_end_date).format("DD/MM/YYYY"),
            paymentTotal: list[key].Payment_total,
            firstName: list[key].Employee_first_name,
            lastName: list[key].Employee_last_name,
            irdNumber: list[key].Employee_IRD.replace(/-/g, ""),
            reimburseStartDate: moment(list[key].Reimbursement_start_date).format("DD/MM/YYYY"),
            reimburseEndDate: moment(list[key].Reimbursement_end_date).format("DD/MM/YYYY"),
            numberOfDaysCovered: list[key].No_of_days,
            reimbursementAmount: list[key].Reimbursement_Amount,
            dateOfInjury: moment(list[key].Date_of_accident).format("DD/MM/YYYY"),
            eligibilityEndDate: moment(list[key].Eligibility_end_date).format("DD/MM/YYYY"),
            eraStatus: list[key].ERA_status.toUpperCase()=="TRUE"?"X":" ",
            workRelated: list[key].Work_related.toUpperCase()=="TRUE"?"W":"N"
          })

      }
    }
    const createMultipleInvoiceData = {
      overwrite: false,
      invoices: invoiceArr
    }
    //console.log("invoiceArr", invoiceArr);
    this.setState({ createInvoiceData: createMultipleInvoiceData })
  }

  // handle file upload
  handleFileUpload = file => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      //console.log("data====65", data)
      this.processData(data);
    };
    reader.readAsBinaryString(file);
  }

  handleClickOpen = () => {
    this.setState({ dialogConfirm: true })
  };
  handleClose = () => {
    this.setState({ dialogConfirm: false, modal: false })
  };

  clickToUpload = () => {
    if (this.state.createInvoiceData == undefined || this.state.createInvoiceData.length == 0) {
      alert("Please select a file!")
    } else {
      this.props.createMultiInvoiceListAsync(this.state.createInvoiceData);
    }
  }

  validateFunc = () => {
    let claimData = this.state.dataInvoice;
    const { createInvoiceData: { invoices } } = this.state;          
    const updateClaimData = { overwrite: true, invoices }
    this.setState({ dialogConfirm: false, modal: false })
    this.props.createMultiInvoiceListAsync(updateClaimData);
  }
  invoiceHandleChange=(event)=>{
    let selectInvoiceData=this.state.dataInvoice.find((data)=>{return data.id==event.target.value});
    const {claimNumber,id,paymentDate,paymentStartDate,paymentEndDate,paymentTotal,firstName,lastName}=selectInvoiceData;
    const validateInvoiceObject={
      claimNumber:claimNumber,
      employerId:id,
      paymentDate:moment(paymentDate).format("DD/MM/YYYY"),
      paymentStartDate:moment(paymentStartDate).format("DD/MM/YYYY"),
      paymentEndDate:moment(paymentEndDate).format("DD/MM/YYYY"),
      paymentTotal:paymentTotal,
      firstName:firstName,
      lastName:lastName,

    }
    this.setState({radioInvoiceChecked:event.target.value,accInvoiceSelectedData:validateInvoiceObject})
  }
  validateInvoiceData=()=>{
    if(Object.entries(this.state.accInvoiceSelectedData).length === 0){
      alert("Please select a data for revalidate!")
    }else{
        this.props.validateInvoiceListAsync(this.state.accInvoiceSelectedData);
    }
  }

  render() {
    const validateInvoiceData = this.props.validateInvoiceData;
    const createInvoiceData = this.props.createInvoiceValue;
    return (
      <Container>
        <Row>
          <MatTable 
          headerCellData={rows} 
          invoiceData={this.state.dataInvoice} 
          title={'ACC Invoice'} 
          pageName="ACC Invoices" 
          onClicks={this.toggle} 
          onChangeSearch={this.onChangeInputData} 
          onButtonClick={this.clickToSearch} 
          toggleChange={this.clickToCheckToggle} 
          isLoading={this.props.loading} 
          validateInvoiceLoading={this.props.validateInvoiceDataLoading}
          toggleValue={this.state.discrepancy} 
          invoiceHandleChange={this.invoiceHandleChange}
          checkedInvoiceRadio={this.state.radioInvoiceChecked}
          clickTovalidat={this.validateInvoiceData}
          discprenciesData={validateInvoiceData ? validateInvoiceData.status ? [] : validateInvoiceData.invoiceDiscrepancies.length > 0 ? validateInvoiceData : [] : []}
          />
        </Row>
        <AlertDialog dialogValue={this.state.dialogConfirm} dialogClose={this.handleClose} claimData={createInvoiceData ? createInvoiceData.length > 0 ? createInvoiceData : [] : []} validateData={this.validateFunc} />
        <ModalPopup modal={this.state.modal} toggle={this.toggle} inputDropFile={(files) => this.onDrop(files)} uploadFile={this.clickToUpload} isLoading={this.props.loading} />
      </Container>
    );
  };

}

const mapStateToProps = state => {
  return {
    invoiceData: state.getInvoice.data,
    createInvoiceValue: state.createInvoiceData.createInvoice,
    isLoadingValidate:state.validateInvoice.isLoading,
    validateInvoiceData:state.validateInvoice.validateInvoiceData,
    validateInvoiceDataLoading:state.validateInvoice.isLoading,
    loading: state.getInvoice.isLoading ? state.getInvoice.isLoading : state.createInvoiceData.isLoading
  }
}

const mapDispatchToProps = {
  getInvoiceListAsync,
  createMultiInvoiceListAsync,
  validateInvoiceListAsync
}

export default connect(mapStateToProps, mapDispatchToProps)(AccInvoice);
