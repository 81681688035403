import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  FormGroup,
} from "reactstrap";
import * as Api from './../../../utils/api/base/apiUrl';
import { RadioButton } from "../../../shared/components/radioButton/radioButton";
import axios from 'axios';
import moment from 'moment';
import Reclaim  from "./components/Reclaim";
import BulkUpdateOrdinaryPay from "./components/BulkUpdateOrdinaryPay";
import ClaimDetails from "./components/ReclaimList";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const ReCalculation = () => {
  const [response, setResponse] = useState(false);  
  const [claimList, setClaimList] = useState([]);
  const [selected, setSelected] = useState('1');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const format = "YYYY-MM-DD";
  
   const handleChange = (e,setIdentifierState) => {
    const { name, value} = e.target;
    setIdentifierState(value);
    setMessage("");
  };

  const setInfo = (message) =>{
    setMessage(message);
    setTimeout(()=>{setMessage("")},3000)
  }
  useEffect(()=>{
   // fetch Claim list
     callFetchApi();
  },[])
  const callFetchApi = async()=> {
    let apiUrl =  Api.reCalculateClaim; 
    try{
      let resp = await axios.get(`${apiUrl}`,{ });
      if(resp.status == 200){
        setClaimList(resp.data)
      }
    } catch(error){
      console.log(error)
    }
  }

  const callCreateApi = async(payload) => {
    let apiUrl =  Api.reCalculateClaim; 
    setLoading(true);
    try{
      let resp = await axios.post(`${apiUrl}`,{ reqparams:payload});
      if(resp.status == 200){
        setResponse(true);
        setLoading(false);
        setInfo("Batch job created successfully")
        callFetchApi();
      }else{
        setLoading(false);
      }
    } catch(error){
      console.log(error)
      setInfo("Something went wrong");
      setLoading(false);
    }
  }
  const handleSubmit = (request, flag) =>{
    flag = (!isNaN) ? flag : 1;
    if(flag === 1){
      let req = JSON.parse(JSON.stringify(request));
      request['reCalcReason'] = 'indexationRateChange';
      req['dt'] = moment(req['dt']).format(format);
      if(req['empNumber']){
        delete req.dt;
      }
      req = [req];
      callCreateApi(req); 
    }else{
      callCreateApi(request); 
    }
    
  }
  return (
    <Container>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
            <FormGroup tag="fieldset">
                <RadioButton
                  name={"selected"}
                  title={"Indexation Rate Change"}
                  value={"1"}
                  onChange={(e)=>handleChange(e,setSelected)}
                  checked={selected}
                  //disabled={loading && selected != 1}
                />
                <RadioButton
                  name={"selected"}
                  title={"Bulk Update - Ordinary Pay"}
                  value={"2"}
                  onChange={(e)=>handleChange(e,setSelected)}
                  checked={selected}
                  //disabled={loading  && selected != 2}
                />
              </FormGroup>
              {selected == "1" &&  <Reclaim title="" onSubmit={handleSubmit} loading={loading} msg={message}/>}
              {selected == "2" &&  <BulkUpdateOrdinaryPay title="" handleSubmit={handleSubmit} loading={loading} msg={message}/>}
              {message != "" && <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="info">{message}</Alert>
            </Stack>}
              <br></br>
              {<ClaimDetails claimList={claimList} loading={loading} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default ReCalculation;
