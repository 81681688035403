import {SET_WITHDRAW_CLAIM_MODE,SET_WITHDRAW_CLAIM_DATA,WITHDRAW_REQUEST_CLAIM_REQUEST,WITHDRAW_REQUEST_CLAIM_SUCCESS,WITHDRAW_REQUEST_CLAIM_FAILURE,RESET_WITHDRAW_CLAIM_DATA} from '../../utils/constant'
export const setWithdrawClaimModeAction = mode => {
    return {
        type: SET_WITHDRAW_CLAIM_MODE,
        payload: mode
    }
}

export const setWithdrawClaimDataAction = data => {
    return {
        type: SET_WITHDRAW_CLAIM_DATA,
        payload: data
    }
}
export const withdrawClaimDataRequest = requestBody => {
    return {
        type: WITHDRAW_REQUEST_CLAIM_REQUEST,
        payload: requestBody
    }
}
export const withdrawClaimDataSuccess = resposne => {
    return {
        type: WITHDRAW_REQUEST_CLAIM_SUCCESS,
        payload: resposne
    }
}
export const withdrawClaimDataFailure = error => {
    return {
        type: WITHDRAW_REQUEST_CLAIM_FAILURE,
        payload: error
    }
}
export const resetWithdrawClaimData = () => {
    return {
        type: RESET_WITHDRAW_CLAIM_DATA,
    }
}