import React from 'react';
import { Card, CardBody, CardHeader, Col, Row, Container, FormGroup, Label, Input } from 'reactstrap';
import { RadioButton } from '../../../shared/components/radioButton/radioButton';
import { LoaderButton } from '../../Layout/components/buttons';
import { connect } from 'react-redux';
import { getDownloadDataAsync } from '../../../redux/actionCreator/getDataDownloadActionCreator';
import * as API from '../../../utils/api/base/apiUrl';
class DataTableDownload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCheckedValue: 'accClaims'
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.tableData && prevProps.tableData !== this.props.tableData) {
            if (this.props.tableData.name && this.props.tableData.content.length > 0) {
                const headingValue = Object.keys(this.props.tableData.content[0]);
                console.log("headingValue", headingValue)
                this.downloadCsvFile(this.JSON_to_CSV(this.props.tableData.content, headingValue),this.props.tableData.name);
            } else if (this.props.tableData.status && this.props.tableData.statusText) {
                alert(this.props.tableData.statusText)
            }
            console.log("this.props.tableData", this.props.tableData)
        }
    }

    JSON_to_CSV = (arr, columns, delimiter = ',') =>
        [
            columns.join(delimiter),
            ...arr.map(obj =>
                columns.reduce(
                    (acc, key) => `${acc}${!acc.length ? '' : delimiter}"${!obj[key] ? '' : obj[key]}"`,
                    ''
                )
            )
        ].join('\n');

    downloadCsvFile = (items,fileName) => {
        console.log("items",items)
        var FileSaver = require('file-saver');
        var blob = new Blob([items], {type: 'data:text/plain;charset=utf-8,'});
        FileSaver.saveAs(blob, `${fileName.replace(".","_")}.csv`);

        // let link = document.createElement('a')
        // link.id = 'download-csv'
        // link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent((items)));
        // link.setAttribute('download', `${fileName}.csv`);
        // document.body.appendChild(link)
        // document.querySelector('#download-csv').click()
        // document.body.removeChild(link);
    }


    downloadTableData = async () => {
        console.log("downloadTableData", this.state.selectedCheckedValue)
        this.props.getDownloadDataAsync(`${API.getDataDownloadApi}?query=dbo.${this.state.selectedCheckedValue}`);
    }
    selectedValue = (event) => {
        console.log("event", event.target.value);
        this.setState({ selectedCheckedValue: event.target.value })
    }
    render() {
        return (
            <Container>
                <Row>
                    <Col md={12} lg={12}>
                        <Card>
                            <CardBody>
                                <FormGroup tag="fieldset">
                                    <legend>Download Data Table</legend>
                                    <RadioButton name={'data'} title={'Claims Table'} value={'accclaims'} onChange={this.selectedValue} checked={this.state.selectedCheckedValue} />
                                    <RadioButton name={'data'} title={'Payment Table'} value={'accpayments'} onChange={this.selectedValue} checked={this.state.selectedCheckedValue} />
                                    <RadioButton name={'data'} title={'Pay Period Totals Table'} value={'accpayperiodtotal'} onChange={this.selectedValue} checked={this.state.selectedCheckedValue} />
                                    <RadioButton name={'data'} title={'Employee Table'} value={'employee'} onChange={this.selectedValue} checked={this.state.selectedCheckedValue} />
                                    <RadioButton name={'data'} title={'ACC Invoice Table'} value={'accinvoices'} onChange={this.selectedValue} checked={this.state.selectedCheckedValue} />
                                </FormGroup>
                                <LoaderButton color={'primary'} title={'Download'} onClick={this.downloadTableData} isLoading={this.props.loading} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        tableData: state.tableData.downloadData,
        loading: state.tableData.isLoading
    }
}

const mapDispatchToProps = {
    getDownloadDataAsync
}

export default connect(mapStateToProps, mapDispatchToProps)(DataTableDownload);
