import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DataTable from '../../../Tables/DataTable/index';
import MaterialTable from '../../../Tables/MaterialTable/index';
import DataTableDownload from '../../../Tables/DataTableDownload/index';
import Reports from '../../../Tables/ReportsDownload/index';
import ReCalculation from '../../../Tables/ReCalculation';
import { ProtectedRoute } from '../../../../shared/components/auth/ProtectedRoute'
import { pageConf } from '../../../../shared/components/auth/routeGuard'

export default () => (
  <Switch>
    <ProtectedRoute path="/tables/data_table" roles={pageConf.dashboardRoute.roles} component={DataTable} />
    <ProtectedRoute path="/tables/material_table" roles={pageConf.maintainFactorRoute.roles} component={MaterialTable} />
    <ProtectedRoute path="/tables/table_data_download" roles={pageConf.reportsRoute.roles} component={DataTableDownload} />
    <ProtectedRoute path="/tables/reports_download" roles={pageConf.dataDownloadRoute.roles} component={Reports} />
    <ProtectedRoute path="/tables/recalculation" roles={pageConf.dataDownloadRoute.roles} component={ReCalculation} />
  </Switch>
);
